import React, { useContext, useMemo } from "react";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import MeasuresContext from "../../contexts/Measures";
import * as Md from "../../md/full";
import _ from "lodash";
import { CustIDByScope } from "../../contexts/BrandConfiguratorProvider";
import FiltersContext from "../../contexts/Filters";
import VersionAnalysisReport from "./VersionAnalysisReport";

const BrandAnalysisWidget = ({ filters, ...props }) => {
    const { selectedKpi, seriesBy } = useContext(MeasuresContext);
    const { getFirstFilterValue } = useContext(FiltersContext);
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy,
            slicesBy: [Md.CorrMake, Md.CorrModel, Md.CustId, Md.VersionStd],
            filters,
        },
    });

    const countryId = getFirstFilterValue("filterCountryId");
    const channel1 = getFirstFilterValue("filterChannel1");
    const oemPromoCustIDs = CustIDByScope[`${countryId}-${channel1}`]?.promo;
    const oemConfigCustIDs = CustIDByScope[`${countryId}-${channel1}`]?.config;

    // Prepare filter selectors
    const makeModelObject = useMemo(() => {
        const slices = result?.data().slices().toArray();
        const mappedObj = slices?.reduce((acc, slice) => {
            const [make, model, custId, versionStd] = slice.sliceTitles();
            const versionObject = {
                label: versionStd,
                [custId]: slice.dataPoints().map((dp) => (dp.rawValue !== null ? Number(dp.rawValue) : null)),
            };
            if (!(make in acc)) {
                acc[make] = {
                    [model]: [versionObject],
                };
            } else {
                if (model in acc[make]) {
                    const index = acc[make][model].findIndex((obj) => obj.label === versionStd);
                    if (index !== -1) {
                        acc[make][model][index] = {
                            ...acc[make][model][index],
                            ...versionObject,
                        };
                    } else {
                        acc[make][model] = [...acc[make][model], versionObject];
                    }
                } else {
                    acc[make][model] = [versionObject];
                }
            }
            return acc;
        }, {});
        // Group the config and promo, by selecting only the min
        const res = mappedObj
            ? Object.entries(mappedObj).reduce((acc, [make, modelsObj]) => {
                  const modelObjCopy = Object.entries(modelsObj).reduce((accModel, [model, versionsArr]) => {
                      const versionArrCopy = versionsArr.map((versionObj) => ({
                          ...versionObj,
                          config: _.min(oemConfigCustIDs.map((custId) => (custId in versionObj ? versionObj[custId][selectedKpi.index] : null))) || null,
                          promo: _.min(oemPromoCustIDs.map((custId) => (custId in versionObj ? versionObj[custId][selectedKpi.index] : null))) || null,
                      }));
                      return {
                          ...accModel,
                          [model]: versionArrCopy,
                      };
                  }, {});
                  return {
                      ...acc,
                      [make]: modelObjCopy,
                  };
              }, {})
            : [];

        return res;
    }, [result, status, selectedKpi]);

    return status === "loading" ? (
        <div className="flex flex-col items-center justify-center gap-2 min-h-[400px]">
            <div>
                <LoadingComponent />
            </div>
            <span>Loading...</span>
        </div>
    ) : (
        <div className="max-h-[450px]">
            <VersionAnalysisReport filters={filters} filterSelectors={makeModelObject} {...props} />
        </div>
    );
};

export default BrandAnalysisWidget;

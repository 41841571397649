// @ts-ignore
import React, { useContext, useEffect, useRef, useState } from "react";
import { useExecutionDataView } from "@gooddata/sdk-ui";
import * as Md from "../../md/full";
import { newJsonPivot } from "../../utils/jsonPivot";
import { useAppContext } from "../../contexts/AppContext";
import Widget from "../Widget";
import TableIndices from "./TableIndices";
import { calculatePriceIndexBase100, pctChg } from "../../utils/indices";
import { CumulatedIndices } from "./CumulatedIndices";
import VolumeTrend from "./VolumeTrend";
import LineIndices from "./LineIndices";
import { colors } from "../../constants";

import { InformationCircleIcon } from "@heroicons/react/24/outline";
import FiltersContext from "../../contexts/Filters";
import MeasuresContext from "../../contexts/Measures";
import LoadingSpinner from "../LoadingSpinner";

// @ts-ignore
export const TrendIndicesView = React.forwardRef(({ seriesBy, filters, selectedKpi, displayMode }, ref) => {
    const { getColorByCustId, startingMonth, getCountryByCode, monthList } = useAppContext();
    const { getScopeForTitleHistory } = useContext(MeasuresContext);

    const { filterCountryId, filterChannel1, filterDuration, getFiltersForFootnote } = useContext(FiltersContext);
    // Category colors passed to the nested chart
    const [categoryColors, setCategoryColors] = useState([]);
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [selectedTab, setSelectTab] = useState("Index");

    // @ts-ignore
    const { result, status, error } = useExecutionDataView({
        execution: { seriesBy, slicesBy: [Md.MthCode, displayMode.attribute], filters: filters },
    });

    const tabs = [
        { name: "Index", show: <TableIndices data={data} startMonth={startingMonth} calculation={calculatePriceIndexBase100} name={"Index"} displayBy={displayMode.key} dpTitleHandler={displayMode.formatTitle} /> },
        // { name: "Amount Change", show: <TableIndices data={data} calculation={amtChg} /> },
        { name: "Monthly % Change", show: <TableIndices startMonth={startingMonth} data={data} calculation={pctChg} name={"Monthly % Change"} displayBy={displayMode.key} dpTitleHandler={displayMode.formatTitle} /> },
    ];
    const chartFootnote = getFiltersForFootnote();
    const actmonthlist = monthList.slice(monthList.indexOf(startingMonth));
    const lastMonth = actmonthlist?.length > 0 ? actmonthlist[actmonthlist.length - 1] : monthList?.length > 0 ? monthList[monthList?.length - 1] : null;

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        if (slices) {
            const prePivot = slices?.map((slice) => ({
                mthCode: slice.sliceTitles()[0],
                [displayMode.key]: slice.sliceTitles()[1],
                Value: 100 * Number(slice.dataPoints()[27].rawValue),
            }));
            const prev = slices?.map((slice) => ({
                mthCode: slice.sliceTitles()[0],
                [displayMode.key]: slice.sliceTitles()[1],
                Value: slice.dataPoints()[27].rawValue !== null ? Number(slice.dataPoints()[28].rawValue) : null,
            }));

            const categoryColors = result
                ?.meta()
                .allHeaders()[0][1]
                .reduce((acc, nextHeader, index) => {
                    // @ts-ignore
                    const headerTitle = nextHeader?.attributeHeaderItem?.name;
                    if (!acc.find((item) => item?.name === headerTitle)) {
                        acc.push(
                            Object.assign({
                                name: headerTitle,
                                color: displayMode.key === "Player" ? getColorByCustId(headerTitle) : colors[index],
                            }),
                        );
                    }
                    return acc;
                }, []);
            const data2 = newJsonPivot(prev, { row: displayMode.key, column: "mthCode", value: "Value" });
            const data = newJsonPivot(prePivot, { row: displayMode.key, column: "mthCode", value: "Value" });
            setData(data);
            setData2(data2);
            setCategoryColors(categoryColors);
        }
    }, [result, selectedKpi]);

    useEffect(() => {
        // If mode changed, clear the data
        setData([]);
        setData2([]);
    }, [displayMode]);

    return status === "loading" ? (
        <LoadingSpinner />
    ) : (
        data.length > 0 && (
            <div className="space-y-6">
                {/* Line Indice widget */}
                <Widget title={`Rental Indices Trends - ${getScopeForTitleHistory("Adjusted Rental")}`} description={"The index shows the monthly rate evolution vs the starting month ( Analysis is made with iso-version month over month )"} footnote={chartFootnote} handleWidgetVisibility={true}>
                    <LineIndices data={data} calculation={calculatePriceIndexBase100} startMonth={startingMonth} displayBy={displayMode.key} dpTitleHandler={displayMode.formatTitle} categoryColors={categoryColors} filters={filters} />
                </Widget>
                {/* Cumul Indices widget */}
                <Widget title={`Cumulated Rental indices ${displayMode.key === "Fuel" ? "by Energy" : ""} - ${getScopeForTitleHistory(`${startingMonth} to ${lastMonth}`)}`} description={"Evolution in %"} footnote={chartFootnote} handleWidgetVisibility={true}>
                    <CumulatedIndices
                        data={data}
                        calculation={calculatePriceIndexBase100}
                        startMonth={startingMonth}
                        displayBy={displayMode.key}
                        dpTitleHandler={displayMode.formatTitle}
                        categoryColors={categoryColors}
                        defaultCategoryColor={displayMode.key !== "Player" && "#3a0ca3"}
                        filters={filters}
                    />
                </Widget>
                {/* Volumes used to calculate indices */}
                <Widget
                    // @ts-ignore
                    title={`Indices Vehicle Volumes - ${getCountryByCode(filterCountryId.positiveAttributeFilter.in?.values[0])} | ${filterDuration.positiveAttributeFilter.in["values"][0]} | ${filterChannel1.positiveAttributeFilter.in["values"][0]} | Monthly Count of Versions`}
                    description={"Number of versions utilized in the Index calculation (iso-version month over month)"}
                    footnote={chartFootnote}
                    handleWidgetVisibility={true}
                >
                    <VolumeTrend data={data2} displayBy={displayMode.key} dpTitleHandler={displayMode.formatTitle} categoryColors={categoryColors} filters={filters} width={ref?.current?.getBoundingClientRect().width} />
                </Widget>

                <h3 className="my-4 text-indigo-700">{`Rental Indices Tables - ${getScopeForTitleHistory("Adjusted Rental")}`}</h3>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        id="tabs"
                        name="tabs"
                        onChange={(e) => setSelectTab(e.target.name)}
                        className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-700 focus:outline-none focus:ring-indigo-700 sm:text-sm"
                        defaultValue={tabs.find((tab) => tab.name === selectedTab).name}
                    >
                        {tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="flex gap-8 border-b pb-2 border-gray-200">
                        {tabs.map((tab) => (
                            <>
                                <div>
                                    <input
                                        key={tab.name}
                                        onClick={() => setSelectTab(tab.name)}
                                        className={"cursor-pointer accent-indigo-700"}
                                        aria-current={tab.name ? "page" : undefined}
                                        value={tab.name}
                                        type="radio"
                                        name={tab.name}
                                        checked={tab.name === selectedTab}
                                        onChange={() => setSelectTab(tab.name)}
                                    />
                                    <label htmlFor={tab.name} className="cursor-pointer whitespace-nowrap py-4 px-1 font-medium text-sm" onClick={() => setSelectTab(tab.name)}>
                                        {tab.name}
                                    </label>
                                </div>
                            </>
                        ))}
                        {selectedTab === "Index" && (
                            <p className="text-sm text-gray-500 opacity-80 font-medium flex flex-row">
                                <InformationCircleIcon className="h-5 w-5 text-gray-700 mr-2" aria-hidden="true" />
                                The index shows the monthly rates evolution vs a reference month that is the 100 base
                            </p>
                        )}
                        {selectedTab === "Monthly % Change" && (
                            <p className="text-sm text-gray-500 opacity-80 font-medium flex flex-row">
                                <InformationCircleIcon className="h-5 w-5 text-gray-700 mr-2" aria-hidden="true" /> % of change vs previous month.
                            </p>
                        )}
                    </div>
                </div>
                <div className="break-after-page">{React.cloneElement(tabs.find((tab) => tab.name === selectedTab).show, { data: data })}</div>
            </div>
        )
    );
});

import { saveAs } from "file-saver";

const TIMEOUT_DURATION = 300000; // 5 minutes

export const generatePdf = async (props, setIsGenerating, setProgress) => {
    try {
        setIsGenerating(true);
        setProgress(0);

        const documentData = {
            data: props.data,
            title: props.title,
            subtitle: props.subtitle,
        };
        //@ts-ignore
        const worker = new Worker(new URL("../workers/pdfWorker.js", import.meta.url), { type: "module" });

        const result = await Promise.race([
            new Promise((resolve, reject) => {
                worker.onmessage = (e) => {
                    if (e.data.error) {
                        reject(new Error(e.data.error));
                    } else if (e.data.progress) {
                        setProgress(e.data.progress);
                    } else {
                        resolve(e.data.blob);
                    }
                };

                worker.onerror = reject;
                worker.postMessage(documentData);
            }),
            new Promise((_, reject) => setTimeout(() => reject(new Error("PDF generation timed out")), TIMEOUT_DURATION)),
        ]);

        saveAs(result, "promotions_report.pdf");
        worker.terminate();
    } catch (error) {
        console.error("Error generating PDF:", error);
    } finally {
        setIsGenerating(false);
        setProgress(0);
    }
};

import React, { useContext, useState, useEffect } from "react";
import HighchartsComponent from "../../HighchartsComponent";
import { useAppContext } from "../../../contexts/AppContext";
import FiltersContext from "../../../contexts/Filters";
import { LoadingComponent } from "@gooddata/sdk-ui";

/**
 *
 * @param {*} data: List of cust kpi values for each vehicle
 * @returns
 */
const BasketSynthesisChart = ({ printStatus, data, listPrices, basketReference = undefined, filters, isLoading = false, width = null, title, kpiSettings, ...props }) => {
    const id = "basket-synthesis-graph";
    const { getParamsByCustID, showListPrices: showListPricesContext } = useAppContext();

    const { getFirstFilterValue } = useContext(FiltersContext);

    const [showScatter, setShowScatter] = useState(true);
    const [showListPrice, setShowListPrice] = useState(true);

    useEffect(() => {
        setShowListPrice(showListPricesContext);
    }, [showListPricesContext]);

    const chartData = data?.map((elem, index) => {
        const dataValues = Object.entries(elem)
            .filter(([key, value]) => key !== "versionStd" && key !== "make" && value !== null)
            .map(([key, value]) => value)
            .sort((a, b) => a - b);

        return Object.assign({
            name: elem.versionStd,
            y: dataValues[0],
            color: elem.versionStd === basketReference?.versionStd && "#3a0ca3",
        });
    });
    chartData?.sort((a, b) => b.y - a.y);

    const listpriceData =
        listPrices?.map((elem, index) => {
            const dataValues = Object.entries(elem)
                .filter(([key, value]) => key !== "versionStd" && key !== "make" && value !== null)
                .map(([key, value]) => value)
                .sort((a, b) => a - b);

            return Object.assign({
                name: elem.versionStd,
                y: dataValues[0],
                color: "#ff7d93",
            });
        }) || [];

    listpriceData?.sort((a, b) => b.y - a.y);

    const categories = chartData?.map((item) => item.name);
    const custOfferSeries = data?.reduce((acc, elem) => {
        const custOffersMatrix = Object.entries(elem).filter(([key, value]) => key !== "versionStd" && key !== "make" && value !== null);
        // find position of the versionStd in the data used in bar chart
        const xIndex = categories?.findIndex((category) => category === elem?.versionStd);
        custOffersMatrix?.forEach((custOffer) => {
            const custObj = getParamsByCustID(custOffer[0], getFirstFilterValue("filterCountryId"));
            const custSerie = acc.find((item) => item.id === custOffer[0]);
            if (custSerie !== undefined) {
                custSerie.data.push({
                    x: showListPrice ? xIndex - 0.14 : xIndex,
                    y: custOffer[1],
                });
            } else {
                acc.push(
                    Object.assign({
                        type: "scatter",
                        id: custOffer[0],
                        name: custObj?.custLabel || custOffer[0],
                        color: custObj?.hexColor || "#6c757d",
                        data: [
                            {
                                x: showListPrice ? xIndex - 0.14 : xIndex,
                                y: custOffer[1],
                            },
                        ],
                    }),
                );
            }
        });
        return acc;
    }, []);

    return isLoading ? (
        <LoadingComponent />
    ) : (
        <div className={!printStatus && "border border-gray-200 shadow rounded"}>
            <HighchartsComponent
                enableScreenshot={!printStatus}
                widgetProps={{
                    id,
                    filters,
                    section: "basketsynthesis",
                    flag_services: kpiSettings,
                }}
                options={{
                    chart: {
                        height: categories.length < 6 ? 450 : 600,
                        width: width,
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 3.0,
                            enableMouseTracking: false,
                            dataLabels: {
                                enabled: true,
                                align: "right",
                                x: -20,
                                color: "#FFFFFF",
                                formatter: function () {
                                    return this.y !== 0 ? this.y : null;
                                },
                            },
                        },
                        scatter: {
                            stickyTracking: false,
                            marker: {
                                radius: 6,
                            },
                        },
                    },
                    title: {
                        text: title,
                        style: {
                            fontSize: printStatus ? "20px" : "14px",
                            padding: printStatus ? "16px" : null,
                            fontWeight: "bold",
                            color: "#4d4d4d",
                            textAlign: "center",
                        },
                    },
                    tooltip: {
                        snap: 0,
                        formatter: function () {
                            if (!["Cheapest competitor", "List Price"].includes(this.series.name)) {
                                return `<b>${this.series.name}</b><br/>${this.y}`;
                            } else return `<span>${this.x}</span><br/><em>Adj.rental</em> ${this.y}`;
                        },
                    },
                    series: showListPricesContext
                        ? [
                              {
                                  name: "Cheapest competitor",
                                  type: "bar",
                                  data: chartData,
                                  color: "#506e96",
                                  yAxis: 0,
                                  pointWidth: 30.0,
                                  events: {
                                      legendItemClick: function (event) {
                                          if (event.target.name === "Cheapest competitor") {
                                              setShowScatter(!showScatter);
                                          }
                                          return true;
                                      },
                                  },
                              },
                              {
                                  name: "List Price",
                                  type: "bar",
                                  data: listpriceData,
                                  color: "#ff7d93",
                                  yAxis: 1,
                                  pointWidth: 20.0,
                                  events: {
                                      legendItemClick: function (event) {
                                          if (event.target.name === "List Price") {
                                              setShowListPrice(!showListPrice);
                                          }
                                          return true;
                                      },
                                  },
                              },
                              ...custOfferSeries.map((serie) => ({
                                  ...serie,
                                  visible: showScatter,
                              })),
                          ]
                        : [
                              {
                                  name: "Cheapest competitor",
                                  type: "bar",
                                  data: chartData,
                                  color: "#506e96",
                                  yAxis: 0,
                                  pointWidth: 30.0,
                              },
                              ...custOfferSeries,
                          ],
                    xAxis: {
                        categories: categories,
                        labels: {
                            style: {
                                fontWeight: 600,
                                fontSize: "12px",
                                color: "#595959",
                            },
                        },
                    },
                    yAxis: showListPricesContext
                        ? [
                              {
                                  title: {
                                      text: showScatter ? title?.split("|")[4] : "List Price",
                                      style: {
                                          color: showScatter ? "#506e96" : "#ff7d93",
                                      },
                                  },
                                  labels: {
                                      style: {
                                          color: showScatter ? "#506e96" : "#ff7d93",
                                      },
                                  },
                                  alignTicks: false,
                                  plotLines: basketReference?.value && [
                                      {
                                          value: basketReference?.value,
                                          width: 2,
                                          zIndex: 10,
                                          color: "#FF0000",
                                          dashStyle: "shortdash",
                                      },
                                  ],
                              },
                              {
                                  title: {
                                      text: null,
                                  },
                                  labels: {
                                      enabled: false,
                                  },
                                  opposite: true,
                                  alignTicks: false,
                                  gridLineWidth: showScatter ? 0 : 1,
                                  max: Math.max(...listpriceData?.map((item) => item.y)) * 2,
                              },
                          ]
                        : [
                              {
                                  title: {
                                      text: title?.split("|")[4],
                                      style: {
                                          color: "#506e96",
                                      },
                                  },
                                  labels: {
                                      style: {
                                          color: "#506e96",
                                      },
                                  },
                                  alignTicks: false,
                                  plotLines: basketReference?.value && [
                                      {
                                          value: basketReference?.value,
                                          width: 2,
                                          zIndex: 10,
                                          color: "#FF0000",
                                          dashStyle: "shortdash",
                                      },
                                  ],
                              },
                          ],
                    legend: {
                        enabled: true,
                        symbolRadius: 0,
                        symbolHeight: 16,
                        labelFormatter: function () {
                            if (this.name === "Cheapest competitor") return `<span style="width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;color:#506e96">${this.name}</span>`;
                            else return `<div style="width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">${this.name}</div>`;
                        },
                    },
                }}
                {...props}
            />
        </div>
    );
};

export default BasketSynthesisChart;

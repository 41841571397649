import React, { useContext, useEffect, useState } from "react";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { useAppContext } from "../../contexts/AppContext";
import FiltersContext from "../../contexts/Filters";
import HighchartsComponent from "../HighchartsComponent";
import * as Md from "../../md/full";
import { newJsonPivot } from "../../utils/jsonPivot";
import MeasuresContext from "../../contexts/Measures";
import { operations } from "./CarSelectorConfigModal";

/**
 *
 * @param {*} data: List of cust kpi values for each vehicle
 * @returns
 */
const MakeModelOverview = ({ seriesBy, filters, footnote, title, kpiSettings, ...props }) => {
    const id = "synthesis-graph";
    const { getParamsByCustID, showListPrices: showListPricesContext } = useAppContext();
    const { getFirstFilterValue } = useContext(FiltersContext);
    const { selectedKpi } = useContext(MeasuresContext);
    const [showListPrice, setShowListPrice] = useState(true);
    const [showScatter, setShowScatter] = useState(true);
    const [data, setData] = useState([]);
    const [listPrices, setListPrices] = useState([]);

    const { result, status } = useExecutionDataView(
        {
            execution: {
                seriesBy: seriesBy,
                slicesBy: [Md.CustId, Md.VersionStd],
                filters: filters,
            },
        },
        [showListPricesContext],
    );

    useEffect(() => {
        if (result) {
            const data = result
                ?.data()
                .series()
                .toArray()
                .find((serie) => serie.measureTitle() === seriesBy[selectedKpi.index].measure.localIdentifier);
            const dataPoints = data?.dataPoints().map((dp) =>
                Object.freeze({
                    value: Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue)),
                    custId: dp.sliceDesc.sliceTitles()[0],
                    versionStd: dp.sliceDesc.sliceTitles()[1],
                }),
            );
            if (dataPoints) {
                let parsedData = newJsonPivot(dataPoints, { row: "versionStd", column: "custId", value: "value" });
                if ("customFields" in props) {
                    parsedData = parsedData.map((versionObj) => {
                        const custIds = Object.keys(versionObj).filter((key) => key !== "versionStd");
                        const customFieldsObj = props.customFields.reduce((acc, field) => {
                            const coeffs = field.players.filter((player) => custIds.includes(player)).map((player) => versionObj[player]);
                            return {
                                ...acc,
                                [field.fieldName]: operations.calculate(coeffs, field.operation, (value) => (selectedKpi.index === 24 ? Number(value.toFixed(2)) : Math.round(value))),
                            };
                        }, {});
                        return {
                            ...versionObj,
                            ...customFieldsObj,
                        };
                    });
                }
                setData(parsedData);
            }
            const lpdata = result
                ?.data()
                .series()
                .toArray()
                .find((serie) => serie.measureTitle() === seriesBy[1].measure.localIdentifier)
                ?.dataPoints()
                .map((dp) =>
                    Object.freeze({
                        value: Math.round(Number(dp.rawValue)),
                        custId: dp.sliceDesc.sliceTitles()[0],
                        versionStd: dp.sliceDesc.sliceTitles()[1],
                    }),
                );
            if (lpdata) {
                let parsedData = newJsonPivot(lpdata, { row: "versionStd", column: "custId", value: "value" });
                if ("customFields" in props) {
                    parsedData = parsedData.map((versionObj) => {
                        const custIds = Object.keys(versionObj).filter((key) => key !== "versionStd");
                        const customFieldsObj = props.customFields.reduce((acc, field) => {
                            const coeffs = field.players.filter((player) => custIds.includes(player)).map((player) => versionObj[player]);
                            return {
                                ...acc,
                                [field.fieldName]: operations.calculate(coeffs, field.operation, (value) => (selectedKpi.index === 24 ? Number(value.toFixed(2)) : Math.round(value))),
                            };
                        }, {});
                        return {
                            ...versionObj,
                            ...customFieldsObj,
                        };
                    });
                }
                setListPrices(parsedData);
            }
        }
    }, [result, selectedKpi]);

    const chartData = data?.map((elem, index) => {
        const dataValues =
            Object.entries(elem)
                .filter(([key, value]) => key !== "versionStd" && key !== "make" && value !== null)
                .map(([key, value]) => value)
                .sort((a, b) => a - b) || [];

        return Object.assign({
            name: elem.versionStd,
            y: dataValues[0],
            color: "#506e96",
        });
    });
    chartData.sort((a, b) => b.y - a.y);

    const listpriceData = listPrices?.map((elem, index) => {
        const dataValues = Object.entries(elem)
            .filter(([key, value]) => key !== "versionStd" && key !== "make" && value !== null)
            .map(([key, value]) => value)
            .sort((a, b) => a - b);

        return Object.assign({
            name: elem.versionStd,
            y: dataValues[0],
            color: "#ff7d93",
        });
    });

    listpriceData?.sort((a, b) => b.y - a.y);

    const categories = chartData?.map((item) => item.name) || [];
    const custOfferSeries = (data || []).reduce((acc, elem) => {
        const custOffersMatrix = Object.entries(elem).filter(([key, value]) => key !== "versionStd" && key !== "make" && value !== null);
        // find position of the versionStd in the data used in bar chart
        const xIndex = categories.findIndex((category) => category === elem?.versionStd);
        custOffersMatrix.forEach((custOffer) => {
            const custObj = getParamsByCustID(custOffer[0], getFirstFilterValue("filterCountryId"));
            const custSerie = acc.find((item) => item.id === custOffer[0]);
            if (custSerie !== undefined) {
                custSerie.data.push({
                    x: showListPrice ? xIndex - 0.14 : xIndex,
                    y: custOffer[1],
                });
            } else {
                acc.push(
                    Object.assign({
                        type: "scatter",
                        id: custOffer[0],
                        name: custObj?.custLabel || custOffer[0],
                        color: custObj?.hexColor || "#6c757d",
                        data: [
                            {
                                x: showListPrice ? xIndex - 0.14 : xIndex,
                                y: custOffer[1],
                            },
                        ],
                    }),
                );
            }
        });
        return acc;
    }, []);

    useEffect(() => {
        setShowListPrice(showListPricesContext);
    }, [showListPricesContext]);

    return (
        <div>
            {status === "loading" ? (
                <LoadingComponent />
            ) : (
                <div className="border border-gray-200 shadow rounded mt-1">
                    <HighchartsComponent
                        widgetProps={{
                            id,
                            filters,
                            section: "car-selector",
                            flag_services: kpiSettings,
                        }}
                        options={{
                            chart: {
                                height: props.height || (categories.length < 6 ? 450 : 600),
                            },
                            plotOptions: {
                                // series: {
                                // },
                                bar: {
                                    borderRadius: 3.0,
                                    enableMouseTracking: false,
                                    dataLabels: {
                                        enabled: true,
                                        align: "right",
                                        x: -20,
                                        color: "#FFFFFF",
                                        formatter: function () {
                                            return this.y !== 0 ? this.y : null;
                                        },
                                    },
                                },
                                scatter: {
                                    stickyTracking: false,
                                    // findNearestPointBy: 'y',
                                    marker: {
                                        radius: 6,
                                    },
                                },
                            },
                            title: {
                                text: title,
                                align: "center",
                                style: {
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: "#595959",
                                },
                            },
                            tooltip: {
                                snap: 0,
                                formatter: function () {
                                    if (!["Cheapest", "List Price"].includes(this.series.name)) {
                                        return `<b>${this.series.name}</b><br/>${this.y}`;
                                    } else return `<span>${this.x}</span><br/><em>Adj.rental</em> ${this.y}`;
                                },
                            },
                            series: showListPricesContext
                                ? [
                                      {
                                          name: "Cheapest",
                                          type: "bar",
                                          data: chartData,
                                          color: "#506e96",
                                          yAxis: 0,
                                          pointWidth: 30.0,
                                          events: {
                                              legendItemClick: function (event) {
                                                  if (event.target.name === "Cheapest") {
                                                      setShowScatter(!showScatter);
                                                  }
                                                  return true;
                                              },
                                          },
                                      },
                                      {
                                          name: "List Price",
                                          type: "bar",
                                          data: listpriceData,
                                          color: "#ff7d93",
                                          yAxis: 1,
                                          pointWidth: 20.0, // Half the width of the Cheapest bar
                                          events: {
                                              legendItemClick: function (event) {
                                                  if (event.target.name === "List Price") {
                                                      setShowListPrice(!showListPrice);
                                                  }
                                                  return true;
                                              },
                                          },
                                      },
                                      ...custOfferSeries.map((serie) => ({
                                          ...serie,
                                          visible: showScatter,
                                      })),
                                  ]
                                : [
                                      {
                                          name: "Cheapest",
                                          type: "bar",
                                          data: chartData,
                                          color: "#506e96",
                                          yAxis: 0,
                                          pointWidth: 30.0,
                                      },
                                      ...custOfferSeries,
                                  ],
                            xAxis: {
                                categories: categories,
                                labels: {
                                    style: {
                                        fontWeight: 600,
                                        fontSize: "12px",
                                        color: "#595959",
                                    },
                                },
                            },
                            yAxis: showListPricesContext
                                ? [
                                      {
                                          title: {
                                              text: showScatter ? title?.split("|")[5] : "List Price",
                                              style: {
                                                  color: showScatter ? "#506e96" : "#ff7d93",
                                              },
                                          },
                                          labels: {
                                              style: {
                                                  color: showScatter ? "#506e96" : "#ff7d93",
                                              },
                                          },
                                          alignTicks: false,
                                      },
                                      {
                                          title: {
                                              text: null,
                                          },
                                          labels: {
                                              enabled: false,
                                          },
                                          opposite: true,
                                          alignTicks: false,
                                          gridLineWidth: showScatter ? 0 : 1,
                                          max: Math.max(...listpriceData?.map((item) => item.y)) * 2,
                                      },
                                  ]
                                : [
                                      {
                                          title: {
                                              text: title?.split("|")[5],
                                              style: {
                                                  color: "#506e96",
                                              },
                                          },
                                          labels: {
                                              style: {
                                                  color: "#506e96",
                                              },
                                          },
                                          alignTicks: false,
                                      },
                                  ],
                            legend: {
                                enabled: true,
                                symbolRadius: 0,
                                symbolHeight: 16,
                                labelFormatter: function () {
                                    if (this.name === "Cheapest") return `<span style="width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;color:#506e96">${this.name}</span>`;
                                    else return `<div style="width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">${this.name}</div>`;
                                },
                            },
                        }}
                        {...props}
                    />
                </div>
            )}
            {footnote && <span className="text-xs text-gray-500">{footnote}</span>}
        </div>
    );
};

export default MakeModelOverview;

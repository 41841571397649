// @ts-nocheck
import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import { useBasketContext } from "../contexts/BasketProvider";
import { useHistory } from "react-router-dom";
import { CreateBasketForm } from "../components/Baskets/CreateBasketForm";

const LoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

export function CreateBasketView({ vehicles = [], isEdit = false }) {
    const basketContext = useBasketContext();
    const history = useHistory();
    let [formStatus, setFormStatus] = useState("");

    let basket = {
        name: "",
        basketgroup: "no Group",
        description: "",
        vehicles: vehicles.length > 0 ? vehicles : basketContext.vehicles,
    };

    useEffect(() => {
        if (formStatus === "success") {
            basketContext.emptyBasket();
            setTimeout(() => {
                history.push("/baskets");
            }, 1000);
        }
    }, [formStatus]);

    // function submitForm(values) {
    //     let filters = values.vehicles.map((vehicle) =>
    //         Object.assign({
    //             type: "version_std",
    //             value: vehicle.Vehicle,
    //         }),
    //     );
    //     let mutationVariables = {
    //         name: values.name,
    //         description: values.description,
    //         filters: filters,
    //     };
    //     setFormStatus("submit");
    //     insertBasket({
    //         variables: mutationVariables,
    //         refetchQueries: [
    //             {
    //                 query: GET_BASKETS,
    //             },
    //         ],
    //     }).then((res) => {
    //         setFormStatus("success");
    //         message.success({
    //             content: "Your basket had been saved",
    //             style: {
    //                 top: "80px",
    //                 right: "20px",
    //                 position: "absolute",
    //             },
    //             duration: 2,
    //         });
    //     });
    // }

    let form = <CreateBasketForm initialValues={basket} />;
    return formStatus === "submit" ? <Spin indicator={LoadingIcon}>{form}</Spin> : form;
}

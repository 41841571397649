import { ErrorComponent, LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import React, { useContext, useEffect, useState } from "react";
import MeasuresContext, { flagServiceMap } from "../../contexts/Measures";
import { colors } from "../../constants";
import { useAppContext } from "../../contexts/AppContext";
import { newArithmeticMeasure } from "@gooddata/sdk-model";
import * as Md from "../../md/full";

const BasketLineWidget = ({ id, execDefinition, componentChild, ...props }) => {
    const { selectedKpi } = useContext(MeasuresContext);
    const { getColorByCustId, getCustlabelByCustId } = useAppContext();
    const [data, setData] = useState({});
    const [trendCategoryTitles, setTrendCategoryTitles] = useState([]);
    const displayMode = props.displayMode;
    const { seriesBy: execSeries, slicesBy, filters } = execDefinition;
    const [newSeries, setNewSeries] = useState([...execSeries]);
    const { result, status, error } = useExecutionDataView({
        execution: {
            seriesBy: [...newSeries],
            slicesBy,
            filters,
        },
    });
    const { flag_services } = props;

    useEffect(() => {
        const dataSerie = result
            ?.data()
            .series()
            .toArray()
            .find((serie) => serie.measureTitle() === newSeries[selectedKpi.index].measure.localIdentifier);
        const slices = result?.data().slices().toArray();
        if (dataSerie && slices) {
            const scrapingDates = slices.reduce((acc, slice) => {
                let mthCode = new Date(slice.sliceTitles()[1]).getTime();
                !acc.includes(mthCode) && acc.push(mthCode);
                return acc;
            }, []);
            let trendColorCopy = colors.slice();
            const serieColors = slices.reduce((acc, slice, index) => {
                let attrValue = displayMode.attributes.reduce((acc, attr, index) => acc + " " + slice.sliceTitles()[index + 2], "");
                if (!(attrValue in acc)) {
                    acc[attrValue] = trendColorCopy.shift();
                }
                // !acc.includes(attrValue) && acc.push(Object.assign());
                return acc;
            }, {});

            const trendData = dataSerie.dataPoints().reduce((acc, dp) => {
                const sliceTitles = dp.sliceDesc.sliceTitles();
                const custValue = sliceTitles[0];
                const serieTitle = sliceTitles[2];
                const scrapeDate = new Date(sliceTitles[1]).getTime();
                const dataPoint = Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue));
                if (custValue in acc) {
                    let serieElem = acc[custValue].find((item) => item.name === serieTitle);
                    if (serieElem) {
                        const mthIndex = scrapingDates.findIndex((item) => item === scrapeDate);
                        serieElem.data[mthIndex] = [scrapeDate, dataPoint];
                    } else {
                        let dataValues = Array(scrapingDates?.length).fill([scrapeDate, null]);
                        const mthIndex = scrapingDates.findIndex((item) => item === scrapeDate);
                        dataValues[mthIndex] = [scrapeDate, dataPoint];
                        acc[custValue].push({
                            name: serieTitle,
                            data: dataValues,
                            color: serieColors[serieTitle],
                        });
                    }
                } else {
                    let dataValues = Array(scrapingDates?.length).fill([scrapeDate, null]);
                    const mthIndex = scrapingDates.findIndex((item) => item === scrapeDate);
                    dataValues[mthIndex] = [scrapeDate, dataPoint];
                    acc[custValue] = [
                        {
                            name: serieTitle,
                            data: dataValues,
                            color: serieColors[serieTitle],
                        },
                    ];
                }
                return acc;
            }, {});

            const TrendDataV = dataSerie.dataPoints().reduce((acc, dp) => {
                const sliceTitles = dp.sliceDesc.sliceTitles();
                const versionStd = sliceTitles[2];
                const custSerieTitle = getCustlabelByCustId(sliceTitles[0]);
                const scrapingDate = new Date(sliceTitles[1]).getTime();
                const dataPoint = Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue));
                if (versionStd in acc) {
                    let serieElem = acc[versionStd].find((item) => item.name === custSerieTitle);
                    if (serieElem) {
                        const mthIndex = scrapingDates.findIndex((item) => item === scrapingDate);
                        serieElem.data[mthIndex] = [scrapingDate, dataPoint];
                    } else {
                        let dataValues = Array(scrapingDates?.length).fill([scrapingDate, null]);
                        const mthIndex = scrapingDates.findIndex((item) => item === scrapingDate);
                        dataValues[mthIndex] = [scrapingDate, dataPoint];
                        acc[versionStd].push({
                            name: custSerieTitle,
                            data: dataValues,
                            color: getColorByCustId(custSerieTitle),
                        });
                    }
                } else {
                    let dataValues = Array(scrapingDates?.length).fill([scrapingDate, null]);
                    const mthIndex = scrapingDates.findIndex((item) => item === scrapingDate);
                    dataValues[mthIndex] = [scrapingDate, dataPoint];
                    acc[versionStd] = [
                        {
                            name: custSerieTitle,
                            data: dataValues,
                            color: getColorByCustId(custSerieTitle),
                        },
                    ];
                }
                return acc;
            }, {});

            setTrendCategoryTitles(scrapingDates);
            if (displayMode.key === "player") {
                setData(trendData);
            } else {
                setData(TrendDataV);
            }
        }
    }, [result, newSeries]);

    useEffect(() => {
        if (flag_services) {
            const enabledServices = Object.keys(flag_services)
                .filter((flService) => flService in flagServiceMap && flag_services[flService])
                .map((flService) => flagServiceMap[flService]);
            newSeries.splice(23, 1, newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"));
            newSeries.splice(24, 1, newArithmeticMeasure([newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"), Md.ListPrice.Avg], "ratio"));
            setNewSeries([...newSeries]);
        }
    }, [componentChild, flag_services]);

    return status === "success" && data[props.custIndex]?.length > 0 ? (
        <>
            {React.createElement(componentChild, {
                data: data[props.custIndex],
                categories: trendCategoryTitles,
                displayMode: displayMode,
                legendStyle: {
                    fontSize: data[props.custIndex]?.length > 8 ? "8px" : "10px",
                    fontWeigth: data[props.custIndex]?.length > 8 ? "400" : "600",
                    color: "#595959",
                    ellipsis: data[props.custIndex]?.length > 6 ? true : false,
                    width: data[props.custIndex]?.length > 6 ? "130px" : "auto",
                },
                custIndex: props.custIndex,
                kpiSettings: flag_services,
                ...props,
            })}
        </>
    ) : status === "loading" ? (
        <LoadingComponent />
    ) : (
        <ErrorComponent message={error?.message} />
    );
};

export default BasketLineWidget;

function fillUp(array) {
    let lastValid = null;

    return array.map((element) => {
        if (element === null) {
            return lastValid;
        }
        lastValid = element;
        return element;
    });
}

function pctChg(data, row) {
    return data.filter((element) => {
        return Object.values(element).filter((obj) => obj === null).length !== Object.values(element).length - 1;
    });
}

function amtChg(data, row) {
    const columns = Object.keys(data[0])
        .filter((f) => f !== row)
        .map((m) => m);

    return data.map((d) => {
        const filled = fillUp(
            Object.keys(d)
                .filter((f) => f !== row)
                .map((m) => d[m]),
        );

        const calculated = filled.map((m, i) => {
            if (i === 0) {
                return null;
            } else {
                return Math.round((m - filled[i - 1]) * 100) / 100;
            }
        });

        return {
            [row]: d[row],
            ...calculated.reduce((a, b, i) => ({ ...a, [columns[i]]: b }), {}),
        };
    });
}

function base100(data, rowKey, startMonth) {
    let result = data
        .filter((element) => {
            return Object.values(element).filter((obj) => obj === null).length !== Object.values(element).length - 1;
        })
        .map((element) => {
            let objIterator = Object.entries(element);
            let custIndexObj = {};
            objIterator.forEach(([objKey, objValue]) => {
                let accumulatorValue = undefined;
                if (objKey === rowKey) {
                    accumulatorValue = objValue;
                } else if (objKey <= startMonth) {
                    accumulatorValue = 100;
                } else {
                    // find value of the previous month
                    const previousMonths = objIterator.filter(([custKey, custValue]) => custKey < objKey && custValue !== null).map(([custKey, custValue]) => custKey);
                    if (previousMonths.length <= 0) {
                        accumulatorValue = 100 + objValue;
                    } else {
                        accumulatorValue = custIndexObj[previousMonths[previousMonths.length - 1]] * (1 + objValue / 100);
                    }
                    // accumulatorValue = objIterator
                    //     .filter(([key, value]) => key <= objKey && key > startMonth)
                    //     .map(([key, val]) => val)
                    //     .reduce((acc, currentValue) => {
                    //         if (currentValue === null) {
                    //             return acc;
                    //         }
                    //         return acc + Number(parseFloat(currentValue).toFixed(4));
                    //     }, 100);
                }
                custIndexObj[objKey] = accumulatorValue;
            });
            return custIndexObj;
        });
    return result;
}

function calculatePriceIndexBase100(custIdEvl, baseMthCode) {
    // sorting the price evolution
    const priceEvolArr = Object.entries(custIdEvl)
        .filter(([key, val]) => key !== "Player")
        .sort((e1, e2) => e1[0].localeCompare(e2[0]));
    let transformedPriceEvolto100 = new Array(priceEvolArr.length).fill(null);
    // Base 100 start
    const noNullBaseIndex = priceEvolArr.findIndex(([mth, val]) => val !== null);
    const baseMthIndex = priceEvolArr.findIndex(([mth, val]) => mth === baseMthCode);
    const base100StartIndex = baseMthIndex >= noNullBaseIndex ? baseMthIndex : noNullBaseIndex > 0 ? noNullBaseIndex - 1 : noNullBaseIndex;
    transformedPriceEvolto100[base100StartIndex] = 100;
    priceEvolArr.forEach(([key, val], index) => {
        let base100Val;
        if (val !== null && base100StartIndex !== index) {
            // Find the previous point non null
            const prevValues = transformedPriceEvolto100.filter((val, i) => val !== null && i < index);
            transformedPriceEvolto100[index] = prevValues[prevValues.length - 1] * (1 + val / 100);
        }
    });
    return transformedPriceEvolto100;
}

export { base100, amtChg, pctChg, calculatePriceIndexBase100 };

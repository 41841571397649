import React from "react";
import { useContext } from "react";
import FiltersContext from "../contexts/Filters";
import UKOEM from "../components/OEM/Ukoem";
import OEM from "../components/OEM/Oem";

const OemOffers = () => {
    const { filterCountryId } = useContext(FiltersContext);
    const country = filterCountryId.positiveAttributeFilter.in["values"][0];
    return country === "GB" ? <UKOEM /> : <OEM />;
};

export default OemOffers;

import { gql } from "@apollo/client";

const UPDATE_BASKET_MUTATION = gql`
    mutation editBasket($id: uuid!, $setInput: re_rentaleye_baskets_set_input!) {
        update_re_rentaleye_baskets_by_pk(pk_columns: { id: $id }, _set: $setInput) {
            id
            name
            basketgroup
            description
        }
    }
`;

const SHARE_BASKET_MUTATION = gql`
    mutation shareBasket($data: [re_rentaleye_basket_permissions_insert_input!]!) {
        insert_re_rentaleye_basket_permissions(objects: $data) {
            returning {
                access_role
            }
        }
    }
`;

const GET_BASKETS = gql`
    query GET_BASKETS($country: String) {
        re_rentaleye_baskets(where: { country: { _eq: $country } }, order_by: { updated_at: desc_nulls_last }) {
            id
            name
            basketgroup
            description
            created_at
            created_by
            updated_at
            reference_line
            basket_filters {
                type
                value
            }
        }
    }
`;

const GET_SYSTEM_BASKETS = gql`
    query GetSystemBaskets($country: String) {
        re_rentaleye_system_baskets(where: { ctryid: { _eq: $country } }) {
            id
            name
            basketgroup
            basket_filters {
                type
                value
                reference
            }
        }
    }
`;

const GET_ASSIGNED_USERS_TO_BASKET = gql`
    query GetAssignedUsers($basketId: uuid) {
        re_rentaleye_basket_permissions(where: { basket: { _eq: $basketId } }) {
            id
            access_role
            basket_permissions_user {
                username
            }
            basket_permissions_assigned_by {
                username
            }
        }
    }
`;

const UPDATE_BASKET_ASSIGNMENT = gql`
    mutation updateUserBasketAssignment($payload: [re_rentaleye_basket_permissions_updates!]!) {
        update_re_rentaleye_basket_permissions_many(updates: $payload) {
            returning {
                id
            }
        }
    }
`;

const REMOVE_USER_ASSIGNMENT_TO_BASKET = gql`
    mutation removeUserBasketAssignment($ids: [uuid]) {
        delete_re_rentaleye_basket_permissions(where: { id: { _in: $ids } }) {
            returning {
                id
            }
        }
    }
`;

// Delete basket and related permissions
const DELETE_BASKET_MUTATION = gql`
    mutation DELETE_BASKET($id: uuid!) {
        delete_re_rentaleye_basket_permissions(where: { basket_permissions_basket: { id: { _eq: $id } } }) {
            returning {
                basket_permissions_user {
                    auth0_id
                }
            }
        }

        delete_re_rentaleye_baskets_by_pk(id: $id) {
            id
        }
    }
`;

// Drop auth user from basket
const DROP_USER_BASKET_MUTATION = gql`
    mutation DROP_BASKET_PERMISSIONS($id: uuid!) {
        delete_re_rentaleye_basket_permissions(where: { basket: { _eq: $id } }) {
            returning {
                basket_permissions_assigned_by {
                    auth0_id
                }
            }
        }
    }
`;

export { UPDATE_BASKET_MUTATION, GET_BASKETS, GET_SYSTEM_BASKETS, SHARE_BASKET_MUTATION, GET_ASSIGNED_USERS_TO_BASKET, DELETE_BASKET_MUTATION, DROP_USER_BASKET_MUTATION, REMOVE_USER_ASSIGNMENT_TO_BASKET, UPDATE_BASKET_ASSIGNMENT };

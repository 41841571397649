import React, { useContext, useMemo } from "react";
import { Sidebar } from "./Sidebar";
import Header from "./Header";
import Query from "./Query";
import News from "./News";
import { useAppContext } from "../../contexts/AppContext";
import UserContext from "../../contexts/User";

function Layout({
    children,
    title,
    showQuery = true,
    showScope = true,
    showParams = true,
    showKpi = true,
    showFilters = true,
    DurationScope = true,
    Channel1Scope = true,
    MonthScope = true,
    shownews = false,
    moreOptions = false,
    enableChannel3 = true,
    enableQouteType = true,
    alignQuery = "self-center",
}) {
    const { activenews } = useAppContext();
    const { user } = useContext(UserContext);
    const { isSideBarCollapsed } = useAppContext();
    const marginLeftContentStyle = `mt-24 col-span-2 mr-6 ${isSideBarCollapsed ? "ml-20" : "ml-64"}`;

    return (
        <div className="grid grid-cols-layout">
            <Header title={title} className="col-span-full" />
            <Sidebar />
            <div
                className={marginLeftContentStyle}
                // style={{
                //     marginLeft: isSideBarCollapsed ? "80px" : "210px",
                // }}
            >
                {shownews && activenews && user?.news_visible && <News />}
                <div className="pb-10 flex flex-col items-stretch gap-12 mx-auto px-8 sm:px-4 md:px-4">
                    {showQuery && (
                        <Query
                            showScope={showScope}
                            showKpi={showKpi}
                            showParams={showParams}
                            showFilters={showFilters}
                            DurationScope={DurationScope}
                            Channel1Scope={Channel1Scope}
                            MonthScope={MonthScope}
                            shownews={shownews}
                            moreOptions={moreOptions}
                            enableChannel3={enableChannel3}
                            enableQouteType={enableQouteType}
                        />
                    )}
                    <main>{children}</main>
                </div>
            </div>
        </div>
    );
}

export default Layout;

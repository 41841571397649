import React, { useContext } from "react";
import HighchartsComponent from "../HighchartsComponent";
import Highcharts from "highcharts";
import FiltersContext from "../../contexts/Filters";

const VolumeTrend = ({ data, displayBy, dpTitleHandler, categoryColors = [], filters, mode = "priceIndex", ...props }) => {
    const id = "volume-indices";
    const { filterCountryId } = useContext(FiltersContext);
    const Bars = data
        .filter((elem) => Object.values(elem).filter((f) => f !== null).length > 1)
        .map((m) => ({
            name: dpTitleHandler(m[displayBy], filterCountryId.positiveAttributeFilter.in.values[0]),
            data: Object.values(m)
                .map((m) => m)
                .filter((f) => typeof f !== "string"),
            color: categoryColors.find((item) => item.name === m[displayBy])?.color,
        }));

    return (
        <div className="w-max">
            <HighchartsComponent
                widgetProps={{
                    id,
                    filters,
                    mode,
                    section: "trends",
                }}
                options={{
                    chart: {
                        type: "column",
                        width: props?.width > 0 ? props.width : null,
                        height: props.height || 450,
                    },
                    title: {
                        text: null,
                    },
                    xAxis: {
                        categories: Object.keys(data[0]).filter((f) => f !== displayBy),
                        crosshair: true,
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: null,
                        },
                        labels: {
                            formatter: function () {
                                return this.value;
                            },
                        },
                        stackLabels: {
                            enabled: true,
                        },
                    },
                    tooltip: {
                        formatter: function () {
                            return "<b>" + this.point.category + " " + this.series.name + "</b>: " + Highcharts.numberFormat(this.y, 0);
                        },
                    },
                    plotOptions: {
                        column: {
                            stacking: "normal",
                            dataLabels: {
                                enabled: true,
                            },
                            pointPadding: 0,
                            groupPadding: 0,
                            borderWidth: 0,
                            maxPointWidth: 32,
                        },
                    },
                    series: Bars,
                }}
                {...props}
            />
        </div>
    );
};

export default VolumeTrend;

import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { WorkspaceProvider } from "../contexts/Workspace";
import { useBackend } from "../contexts/Auth";

import Login from "./Login";
import Logout from "./Logout";
import Home from "./Home";
import { Vehicles } from "./Vehicles";
import Competitiveness from "./Competitiveness";
import { Baskets } from "./Baskets";
import Export from "./Export";
import Layout from "../components/Layout/Layout";

import { Settings } from "./Settings";
import { CreateBasketView } from "./CreateBasket";
import { EditBasketView } from "./EditBasket";
import InfoPage from "./InfoPage";
import News from "./News";
import OemOffers from "./OemOffers";
import UserContext from "../contexts/User";
import { navigation } from "../components/Layout/Sidebar";
import { CarSelectorProvider } from "../contexts/CarSelectorProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { Dashboard } from "./Dashboard";
import Popup from "../components/Layout/Popup";
import BrandModelAnalysis from "./BrandModelAnalysis";

// Uncomment these lines if you want to redirect unauthorized users to login form

// import { useAuth0 } from "@auth0/auth0-react";

// const RedirectIfNotLoggedIn = () => {
//     const auth0 = useAuth0();
//     const shouldRedirectToLogin = !auth0.isLoading && !auth0.isAuthenticated;
//     return shouldRedirectToLogin ? <Route component={() => <Redirect to="/login" />} /> : null;
// };

const EventLogWrapper = ({ children }) => {
    // Wrapper component to capture the clicks on section urls
    const { user, trackEvent } = useContext(UserContext);
    const location = useLocation();
    const { isAuthenticated } = useAuth0();
    const [showSplashLogo, setShowSplashLogo] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            setShowSplashLogo(true);
            setTimeout(() => {
                setShowSplashLogo(false);
                setShowNotification(true);
            }, 6000);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        const navSelected = navigation.find((item) => item.href === location.pathname);
        if (user?.username && navSelected?.name) {
            trackEvent(user.username, navSelected?.name);
        }
    }, [user, location.pathname]);

    return (
        <>
            {
                <>
                    {showSplashLogo && (
                        <div className="flex items-center justify-center bg-indigo-50 min-h-screen w-screen md:w-auto">
                            <video autoPlay muted height="300" width="600">
                                <source src="/videos/logo-experteye.mp4" type="video/mp4" />
                            </video>
                        </div>
                    )}
                    {showNotification && <Popup />}
                    <div
                        style={{
                            display: (showSplashLogo || !isAuthenticated) && "none",
                            backgroundColor: (showSplashLogo || !isAuthenticated) && "#F1F1F1",
                        }}
                    >
                        {children}
                    </div>
                </>
            }
        </>
    );
};

const AppRouter = () => {
    const {
        // @ts-ignore
        decorated: {
            decorated: { sdk: backend },
        },
    } = useBackend();
    const relayState = process.env.REACT_APP_RELAY_STATE;

    useEffect(() => {
        backend.user.isLoggedIn().then((isLogged) => {
            if (!isLogged) {
                process.env.REACT_APP_SET_HOSTNAME && backend.user.initiateSamlSso(relayState);
            }
        });
    }, [backend]);

    return (
        <div>
            <Router>
                <EventLogWrapper>
                    {/* WorkspaceProvider depends on Router so it must be nested */}
                    <WorkspaceProvider>
                        <Route
                            exact
                            path="/"
                            component={() => (
                                <Layout title={"Rentaleye My Dashboard"} showParams={false} DurationScope={false} showKpi={true} shownews={true} showQuery={true} alignQuery="self-start" showFilters={false}>
                                    <DndProvider backend={HTML5Backend}>
                                        <Dashboard />
                                    </DndProvider>
                                </Layout>
                            )}
                        />

                        <Route
                            exact
                            path="/trends"
                            component={() => (
                                <Layout title={"Rentaleye Trends"} showKpi={false} shownews={true}>
                                    <Home />
                                </Layout>
                            )}
                        />

                        <Route
                            exact
                            path="/vehicles"
                            component={() => (
                                <CarSelectorProvider>
                                    <Layout title={"Rentaleye Car Selector"}>
                                        <Vehicles />
                                    </Layout>
                                </CarSelectorProvider>
                            )}
                        />
                        <Route
                            exact
                            path="/oemoffers"
                            component={() => (
                                <Layout title={"Rentaleye Promotions"} DurationScope={false} showKpi={false} showParams={false}>
                                    <OemOffers />
                                </Layout>
                            )}
                        />
                        <Route
                            exact
                            path="/brand_model_analysis"
                            component={() => (
                                <Layout title={"Rentaleye Brand and Model Analysis"}>
                                    <BrandModelAnalysis />
                                </Layout>
                            )}
                        />
                        <Route
                            exact
                            path="/info"
                            component={() => (
                                <Layout title={"Rentaleye Data Coverage"} showFilters={true} showKpi={false} DurationScope={false}>
                                    <InfoPage />
                                </Layout>
                            )}
                        />
                        <Route
                            exact
                            path="/news"
                            component={() => (
                                <Layout title={"Rentaleye Latest Updates"} showParams={false} showFilters={false} MonthScope={false} DurationScope={false} showKpi={false} alignQuery={"self-start"}>
                                    <News />
                                </Layout>
                            )}
                        />

                        <Route
                            exact
                            path="/competitiveness"
                            component={() => (
                                <Layout title={"Rentaleye Competitiveness"} showKpi={false} enableChannel3={false} enableQouteType={false}>
                                    <Competitiveness />
                                </Layout>
                            )}
                        />

                        <Route
                            exact
                            path="/baskets"
                            component={() => (
                                <Layout title={"Rentaleye Baskets"} showParams={false} showScope={false} showFilters={false} moreOptions={true}>
                                    <Baskets />
                                </Layout>
                            )}
                        />
                        <Route
                            exact
                            path="/baskets/create"
                            component={() => {
                                return (
                                    <Layout title={"Create new basket"} showQuery={false}>
                                        <div>
                                            <CreateBasketView vehicles={[]} />
                                        </div>
                                    </Layout>
                                );
                            }}
                        />
                        <Route
                            exact
                            path="/baskets/edit"
                            component={() => {
                                return (
                                    <Layout title={"Edit basket"} showQuery={false}>
                                        <div>
                                            <EditBasketView vehicles={[]} />
                                        </div>
                                    </Layout>
                                );
                            }}
                        />
                        <Route
                            exact
                            path="/export"
                            component={() => (
                                <Layout title={"Rentaleye Export"} showKpi={false}>
                                    <Export />
                                </Layout>
                            )}
                        />

                        <Route
                            exact
                            path="/settings"
                            component={() => (
                                <Layout title={"Rentaleye Settings"} showParams={false} showQuery={true} showFilters={false} showKpi={false} DurationScope={false} MonthScope={false} alignQuery={"self-start"}>
                                    <Settings />
                                </Layout>
                            )}
                        />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/logout" component={Logout} />
                        {/* <RedirectIfNotLoggedIn /> */}
                    </WorkspaceProvider>
                </EventLogWrapper>
            </Router>
        </div>
    );
};

export default AppRouter;

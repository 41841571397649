import { useExecutionDataView } from "@gooddata/sdk-ui";
import React, { useContext, useEffect } from "react";
import jsonPivot from "../../utils/jsonPivot";
import { newAttributeSort, newMeasureSort } from "@gooddata/sdk-model";
import * as Md from "../../md/full";
import MeasuresContext from "../../contexts/Measures";
import TableComponent from "../TableComponent";

const BasketSummary = ({ id, execDefinition, componentChild, ...props }) => {
    const [columns, setColumns] = React.useState([]);
    const [dataTable, setDataTable] = React.useState([]);
    const { selectedKpi, selectedAdjDeposit } = useContext(MeasuresContext);
    const selectedBaskets = props.metadata?.selectedBaskets;
    const { seriesBy } = execDefinition;
    const { result, status } = useExecutionDataView({
        execution: {
            ...execDefinition,
            sortBy: [newMeasureSort(seriesBy[selectedKpi?.index]), newAttributeSort(Md.CustId)],
        },
    });
    useEffect(() => {
        if (result && status === "success" && selectedBaskets?.length > 0) {
            const data = result.data().series().firstForMeasure(seriesBy[selectedKpi.index]);
            const adjdata = result.data().series().firstForMeasure(seriesBy[2]);
            let dataPoints = [];
            let flatBaskets = selectedBaskets
                ?.map((basket) =>
                    basket.basketFilters
                        .filter((basketFilter) => basketFilter.type === "version_std")
                        .map((basketFilter) =>
                            Object.assign({
                                name: basket.name,
                                versionStd: basketFilter.value,
                            }),
                        ),
                )
                .flat();
            const predata = data?.dataPoints().map((dp) =>
                Object.freeze({
                    value: Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue)),
                    custId: dp.sliceDesc.sliceTitles()[0],
                    versionStd: dp.sliceDesc.sliceTitles()[1],
                }),
            );
            const AdjDataPoints = adjdata
                ?.dataPoints()
                .map((dp) =>
                    Object.freeze({
                        value: Number(dp.rawValue) === 0 ? null : Number(dp.rawValue),
                        custId: dp.sliceDesc.sliceTitles()[0],
                        versionStd: dp.sliceDesc.sliceTitles()[1],
                    }),
                )
                .filter((dp) => dp.value !== null);

            if (selectedAdjDeposit !== "Total Payable by month") {
                // filter out predata from records that are not in AdjDataPoints
                dataPoints = predata.filter((dp) => AdjDataPoints.some((adjdp) => adjdp.custId === dp.custId && adjdp.versionStd === dp.versionStd));
            } else {
                dataPoints = predata;
            }
            const tableData = jsonPivot(dataPoints, { row: "versionStd", column: "custId", value: "value" });
            // Calculate average for each table row
            tableData.forEach((tr) => {
                const custValues = Object.entries(tr)
                    .filter(([key, value]) => key !== "versionStd" && value !== null)
                    .flatMap(([key, value]) => value);
                tr.average =
                    Math.round(custValues.reduce((acc, nextVal) => acc + nextVal, 0)) > 0
                        ? selectedKpi.index === 24
                            ? Number((custValues.reduce((acc, nextVal) => acc + nextVal, 0) / custValues.length).toFixed(2))
                            : Math.round(custValues.reduce((acc, nextVal) => acc + nextVal, 0) / custValues.length)
                        : null;
            });
            // Assign each data item with their associate basket
            flatBaskets.forEach((basketElem) => {
                let tdElem = tableData.find((elem) => elem && elem["versionStd"] === basketElem["versionStd"]);
                if (tdElem?.basket) {
                    let newTd = {
                        ...tdElem,
                        basket: basketElem.name,
                    };
                    tableData.push(newTd);
                } else if (tdElem) {
                    tdElem.basket = basketElem.name;
                }
            });

            tableData.sort((a, b) => {
                if (a.basket < b.basket) {
                    return -1;
                } else if (a.basket > b.basket) {
                    return 1;
                }
                return 0;
            });
            // Re-position columns
            let tableKeys = Object.keys(tableData[0]);
            let basketColumnIndex = tableKeys.findIndex((elem) => elem === "basket");
            if (basketColumnIndex !== -1) {
                let basketColumns = tableKeys.splice(basketColumnIndex, 1);
                tableKeys.splice(0, 0, ...basketColumns);
            }
            let versionStdColumnIndex = tableKeys.findIndex((elem) => elem === "versionStd");
            if (versionStdColumnIndex !== -1) {
                let versionStdColumns = tableKeys.splice(versionStdColumnIndex, 1);
                tableKeys.splice(1, 0, ...versionStdColumns);
            }
            let averageColumnIndex = tableKeys.findIndex((elem) => elem === "average");
            if (averageColumnIndex !== -1) {
                let averageColumns = tableKeys.splice(averageColumnIndex, 1);
                tableKeys.splice(2, 0, ...averageColumns);
            }
            // @ts-ignore
            let columnsTable = tableKeys.map((elem) =>
                Object.freeze({
                    dataIndex: elem,
                    title: () => <span>{elem === "basket" ? "Basket Name" : elem === "versionStd" ? "Version Target" : elem === "average" ? "Average" : <img alt="logo" src={`/images/logos/Logo_${elem}.png`} width={80} height={80} />}</span>,
                    align: ["basket", "versionStd"].includes(elem) ? "left" : "center",
                    width: elem === "basket" ? 140 : !["versionStd"].includes(elem) ? 100 : 300,
                    onCell: (row, index) => {
                        if (elem === "basket") {
                            let basket = selectedBaskets.find((basket) => basket.name === row.basket);
                            let startIndex = tableData.findIndex((elem) => elem.basket === basket?.name);
                            if (index === startIndex) {
                                let offset = tableData.filter((elem) => elem.basket === basket.name)?.length;
                                return {
                                    rowSpan: offset,
                                };
                            } else {
                                return {
                                    rowSpan: 0,
                                };
                            }
                        }
                    },
                    render: (text) => {
                        if (elem === "basket") {
                            return <span className="absolute top-2">{text}</span>;
                        } else {
                            return text;
                        }
                    },
                }),
            );
            setColumns(columnsTable);
            setDataTable(tableData);
        }
    }, [result, status]);
    return (
        <TableComponent
            columns={columns}
            dataSource={dataTable}
            size="small"
            pagination={false}
            bordered
            scroll={{
                y: 400,
            }}
            {...props}
        />
    );
};

export default BasketSummary;

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { UserParams } from "../types/User";
import { GET_ALL_USER_PREFERENCES, GET_ALL_USER_PREFERENCES_SYSTEM, GET_FILTER_USER_BY_EMAIL_LIST, GET_KPI_ADJ_SETTINGS, GET_USER_BY_EMAIL, INSERT_BULK_USER_PREFS, INSERT_EVENT_LOGS, UPDATE_SCOPE_SETTINGS, UPDATE_USER_DETAIL, UPSERT_USER_PREFS } from "../queries/Users";
import { useAuth0 } from "@auth0/auth0-react";

const initialValues = {
    loading: true,
    user: {},
    userPrefs: [],
    systemUserPrefs: [],
    defaultUserPref: {
        mth_code: moment().format("YYYY MM"),
    },
    selectedScope: {
        ctryid: "",
        channel1: "",
        duration: "",
        mth_code: "",
    },
    kpiSettings: {},
    getCountries: null,
    refrechSettings: null,
    trackEvent: null,
    IncServices: true,
    enabledKpisettings: {},
    setEnabledKpisettings: undefined,
    updateUser: (userSettings) => Promise.resolve(undefined),
    updateScopeSettings: ({ id, newScopeSettings }) => Promise.resolve(undefined),
    loadSettings: (ctryid, channel1 = undefined) => {},
    resetUserPref: (ctryid = undefined, channel1 = undefined) => new Promise(null),
    filterUsersByEmail: (emailList) => new Promise(null),
    updateServices: null,
    updateRollForward: null,
    updateMultiSelection: (value) => Promise.resolve(undefined),
    showVersionCatalog: null,
    setShowVersionCatalog: null,
};

const UserContext = React.createContext({ ...initialValues });

function UserProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const { user: ssoUser } = useAuth0();
    const [user, setUser] = useState();
    const [userPrefs, setUserPrefs] = useState([]);
    const [systemUserPrefs, setSystemUserPrefs] = useState([]);
    const [showVersionCatalog, setShowVersionCatalog] = useState();
    const [defaultUserPref, setDefaultUserPref] = useState({});
    const [kpiSettings, setKpiSettings] = useState({});
    const [services, updateServices] = useState(true);
    const [enabledKpisettings, setEnabledKpisettings] = useState({});
    const { data: allUserPrefsResults, loading: loadingUserPrefs } = useQuery(GET_ALL_USER_PREFERENCES);
    const { data: systemUserPrefsdataResult, loading: loadingSysUserPrefs } = useQuery(GET_ALL_USER_PREFERENCES_SYSTEM);
    const { data: userDetailResult } = useQuery(GET_USER_BY_EMAIL, {
        variables: {
            email: ssoUser?.email,
        },
    });

    const { data: filteredUserList, refetch: refetchFilterUsersQuery } = useQuery(GET_FILTER_USER_BY_EMAIL_LIST);

    const [insertBulkUserPrefs, { loading: userPrefInsertionStatus }] = useMutation(INSERT_BULK_USER_PREFS);
    const [updateUser] = useMutation(UPDATE_USER_DETAIL, {
        refetchQueries: [
            {
                query: GET_USER_BY_EMAIL,
                variables: {
                    email: ssoUser?.email,
                },
            },
        ],
    });

    const [executeUpdateScopeSettingsMutation] = useMutation(UPDATE_SCOPE_SETTINGS, {
        refetchQueries: [
            {
                query: GET_ALL_USER_PREFERENCES,
            },
            {
                query: GET_KPI_ADJ_SETTINGS,
            },
        ],
    });

    const [upsertUserPref] = useMutation(UPSERT_USER_PREFS, {
        refetchQueries: [
            {
                query: GET_ALL_USER_PREFERENCES,
            },
        ],
    });
    const [insertEventLog] = useMutation(INSERT_EVENT_LOGS);

    function loadSettings(country, channel1 = undefined) {
        let filteredUserPref;
        if (channel1) {
            filteredUserPref = userPrefs.find((elem) => elem.ctryid === country && elem.channel1 === channel1) || userPrefs.find((elem) => elem.ctryid === country);
        } else {
            filteredUserPref = userPrefs.find((elem) => elem.ctryid === country);
        }
        if (filteredUserPref) {
            const userParam = new UserParams(filteredUserPref);
            setDefaultUserPref(userParam);
            const { flag_bre, flag_fin, flag_co2, flag_evb, flag_ins, flag_rep, flag_ser, flag_tyr, flag_win } = userParam;
            setKpiSettings({ flag_bre, flag_fin, flag_co2, flag_evb, flag_ins, flag_rep, flag_ser, flag_tyr, flag_win });
        }
    }

    function resetUserPref(ctryid = undefined, channel1 = undefined) {
        let userPrefObj = systemUserPrefs.find((item) => item.ctryid === ctryid && item.channel1 === channel1);
        if (!isNaN(userPrefObj)) {
            userPrefObj = systemUserPrefs.find((item) => item.fl_default);
        }
        return upsertUserPref({
            variables: {
                object: userPrefObj,
            },
        });
    }

    function updateScopeSettings({ id, newScopeSettings }) {
        return executeUpdateScopeSettingsMutation({
            variables: {
                key: id,
                args: newScopeSettings,
            },
        });
    }
    function filterUsersByEmail(emailList) {
        return refetchFilterUsersQuery({
            emailList: emailList,
        });
    }

    function trackEvent(login, sectionName) {
        return insertEventLog({
            variables: {
                login: login,
                section_name: sectionName,
            },
        });
    }

    function onChangeCatalogMode() {
        return updateUser({
            variables: {
                args: {
                    catalogue_by_version: showVersionCatalog,
                },
            },
        });
    }

    function updateMultiSelection(value) {
        return updateUser({
            variables: {
                args: { enable_multiselection: value },
            },
        });
    }

    async function updateRollForward(value, ctryid, channel1) {
        let userPrefObj = userPrefs.find((item) => item.ctryid === ctryid && item.channel1 === channel1);
        if (!isNaN(userPrefObj)) {
            userPrefObj = userPrefs.find((item) => item.fl_default);
        }
        // Remove fl_weekly from userPrefObj
        delete userPrefObj.fl_weekly; // {{ edit_1 }}
        userPrefObj = { ...userPrefObj, fl_rw: Number(value) };
        return upsertUserPref({
            variables: {
                object: userPrefObj,
            },
        });
    }

    useEffect(() => {
        if (!loadingSysUserPrefs && !loadingUserPrefs) {
            if (allUserPrefsResults?.re_rentaleye_re_params_user_prefs.length > 0) {
                const parsedUserPrefs = allUserPrefsResults.re_rentaleye_re_params_user_prefs.map((item) => new UserParams(item));
                setUserPrefs(parsedUserPrefs);
            } else if (systemUserPrefsdataResult?.re_rentaleye_re_params_user_prefs_system.length > 0 && !userPrefInsertionStatus) {
                const sysUserPref = systemUserPrefsdataResult?.re_rentaleye_re_params_user_prefs_system.map((item) => new UserParams(item));
                // Re-initialize settings from the system
                insertBulkUserPrefs({
                    variables: {
                        objects: sysUserPref,
                    },
                    refetchQueries: [
                        {
                            query: GET_ALL_USER_PREFERENCES,
                        },
                    ],
                });
            }
            if (systemUserPrefsdataResult?.re_rentaleye_re_params_user_prefs_system.length > 0) {
                const sysUserPref = systemUserPrefsdataResult?.re_rentaleye_re_params_user_prefs_system.map((item) => new UserParams(item));
                setSystemUserPrefs(sysUserPref);
            }
        }
    }, [loadingSysUserPrefs, loadingUserPrefs, allUserPrefsResults, systemUserPrefsdataResult]);

    useEffect(() => {
        // Initialize default pref either from user prefs table or insert them from system user prefs
        if (userPrefs.length > 0) {
            let initUserPref;
            if (defaultUserPref && Object.keys(defaultUserPref).includes("ctryid")) {
                initUserPref = userPrefs.filter((elem) => elem.ctryid === defaultUserPref?.ctryid && elem.channel1 === defaultUserPref?.channel1)[0];
            } else {
                initUserPref = userPrefs.find((elem) => elem.fl_default);
            }
            if (initUserPref) {
                setDefaultUserPref(initUserPref);
                const { flag_bre, flag_fin, flag_co2, flag_evb, flag_ins, flag_rep, flag_ser, flag_tyr, flag_win } = initUserPref;
                setKpiSettings({ flag_bre, flag_fin, flag_co2, flag_evb, flag_ins, flag_rep, flag_ser, flag_tyr, flag_win });
                setLoading(false);
            }
        }
    }, [userPrefs]);

    // useEffect(() => {
    //     if (enabledKpisettings) {
    //         updateServices(Object.values(enabledKpisettings).some((value) => value === 1));
    //     }
    // }, []);

    useEffect(() => {
        // save user state on the user provider
        if (userDetailResult) {
            setUser({ ...userDetailResult.re_rentaleye_users[0] });
        }
    }, [userDetailResult]);

    useEffect(() => {
        if (user !== undefined) {
            if (isNaN(showVersionCatalog)) setShowVersionCatalog(user.catalogue_by_version);
        }
    }, [user]);

    useEffect(() => {
        if (!isNaN(showVersionCatalog) && showVersionCatalog !== user.catalogue_by_version) {
            onChangeCatalogMode();
        }
    }, [showVersionCatalog, user]);

    const value = useMemo(() => ({ loading, userPrefs, IncServices: services, systemUserPrefs, user, defaultUserPref, kpiSettings, enabledKpisettings }), [loading, userPrefs, services, systemUserPrefs, user, defaultUserPref, kpiSettings, enabledKpisettings]);

    const getCountries = useCallback(() => [...new Set(userPrefs.map((m) => m.ctryid))].sort((e1, e2) => e1.localeCompare(e2)), [userPrefs]);

    return (
        <UserContext.Provider
            value={{
                ...initialValues,
                ...value,
                getCountries,
                loadSettings,
                refrechSettings: (ctryd, channel1) => {},
                trackEvent,
                updateUser,
                resetUserPref,
                filterUsersByEmail,
                updateScopeSettings,
                updateRollForward,
                updateServices,
                showVersionCatalog,
                setShowVersionCatalog,
                setEnabledKpisettings,
                updateMultiSelection,
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

const useUserContext = () => useContext(UserContext);

export default UserContext;

export { UserProvider, useUserContext };

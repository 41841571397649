import React, { useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import more from "highcharts/highcharts-more";
import { NoDataToDisplay } from "react-highcharts-no-data-to-display";
import groupedCategories from "highcharts-grouped-categories";
import events from "highcharts-custom-events";
import { CopyOutlined } from "@ant-design/icons";
import { Dropdown, Modal, message } from "antd";
import html2canvas from "html2canvas";
import { FiMoreVertical } from "react-icons/fi";

import { RiUnpinFill } from "react-icons/ri";
import { MdOutlineDashboardCustomize } from "react-icons/md";

import { PinIcon } from "./Icons/PinIcon";
import { useDashboard } from "../contexts/DashboardProvider";
import { Formik } from "formik";
import { isString } from "lodash";

Highcharts.setOptions({ credits: false, accessibility: { enabled: false } });
NoDataToDisplay(Highcharts);
groupedCategories(Highcharts);
more(Highcharts);
events(Highcharts);

/**
 *
 * @param section mention the section name on which the graph is plotted
 * @param filters The filters applied to calculate the graph
 * @returns
 */
export default function HighchartsComponent({ options, imgName = "img", enableScreenshot = true, enablePin = true, widgetUid = undefined, widgetIndex = undefined, menuButtonPosition = "outside", menuLocation = {}, widgetProps = {}, ...props }) {
    const [showMenu, setShowMenu] = useState(false);
    const [showPinModal, setShowPinModal] = useState(false);
    // submitted | completed | error
    const [formSubmissionState, setFormSubmissionState] = useState(undefined);
    const [cursorCoordinates, setCursorCoordinates] = useState({ x: null, y: null });
    const [boxDimensions, setBoxDimensions] = useState({});
    const ref = useRef(null);
    const { pinWidget, unPinwWidget } = useDashboard();

    const getImage = () => {
        return html2canvas(ref.current);
    };

    const copyImage = (imgCanvas, { extension = "png" } = {}) => {
        imgCanvas.toBlob((imgBlob) => {
            navigator.clipboard.write([new window.ClipboardItem({ [imgBlob.type]: imgBlob })]).then((res) => {
                message.success("The chart's copied to clipboard");
            });
        });
    };

    function onPinClick() {
        setShowPinModal(true);
        showMenu && setShowMenu(!showMenu);
    }

    function onUnPinClick() {
        if (isString(widgetUid)) {
            unPinwWidget(widgetUid);
        } else {
            throw Error("Widget does not exist");
        }
    }

    const onSubmitWidgetForm = (formValues) => {
        setFormSubmissionState("Submitted");
        const { id, section, filters, ...metadata } = widgetProps;
        const widgetFilters = filters
            .filter((item) => item?.positiveAttributeFilter?.in?.values?.length > 0 || item?.measureValueFilter?.condition !== undefined)
            .reduce((acc, item) => {
                let filtervalue = item?.measureValueFilter?.condition || item?.positiveAttributeFilter?.in?.values;
                let filterIdentifier = item?.measureValueFilter?.measure?.localIdentifier || item?.positiveAttributeFilter?.displayForm?.identifier;
                acc = {
                    ...acc,
                    [filterIdentifier]: filtervalue,
                };
                return acc;
            }, {});

        pinWidget({
            label: id,
            section,
            metadata,
            filters: widgetFilters,
            ...formValues,
        })
            .then((res) => {
                message.success("Widget's pinned");
            })
            .catch((error) => {
                message.error(error);
            })
            .finally(() => {
                setFormSubmissionState("Completed");
                setShowPinModal(false);
            });
    };

    const menuItems = [
        enablePin
            ? {
                  key: 1,
                  icon: <PinIcon width={20} />,
                  label: "Pin to dashboard",
                  clickHandler: () => onPinClick(),
                  isEnabled: true,
              }
            : {
                  key: 2,
                  icon: <RiUnpinFill width={20} />,
                  label: "Unpin widget",
                  clickHandler: () => onUnPinClick(),
                  isEnabled: true,
              },
        {
            key: 3,
            icon: <CopyOutlined width={20} className="text-steel" />,
            label: "Copy to clipboard",
            clickHandler: () => {
                document.body.style.cursor = "wait";
                showMenu && setShowMenu(!showMenu);
                getImage().then((result) => {
                    document.body.style.cursor = "default";
                    copyImage(result);
                });
            },
            isEnabled: enableScreenshot,
        },
    ];

    // const openMenu = (event) => {
    //     event.preventDefault();
    //     setCursorCoordinates({
    //         x: event.offsetX,
    //         y: event.offsetY,
    //     });
    //     !showMenu && setShowMenu(!showMenu);
    // };

    // const closeMenu = (event) => {
    //     event.which === 1 && setShowMenu(false);
    // };

    // useEffect(() => {
    //     ref?.current?.addEventListener("contextmenu", openMenu);
    //     ref?.current?.addEventListener("mousedown", closeMenu);
    //     const refDimensions = ref?.current?.getBoundingClientRect();
    //     refDimensions && setBoxDimensions({ width: refDimensions.width, height: refDimensions.height });
    //     return () => {
    //         ref?.current?.removeEventListener("contextmenu", openMenu);
    //         ref?.current?.removeEventListener("mousedown", closeMenu);
    //     };
    // }, [ref, showMenu]);

    // Context menu position style
    let menuStyle = {};
    if (cursorCoordinates?.x > boxDimensions?.width * 0.8) {
        menuStyle = {
            ...menuStyle,
            right: boxDimensions.width - cursorCoordinates.x,
        };
    } else {
        menuStyle = {
            ...menuStyle,
            marginLeft: cursorCoordinates.x,
        };
    }

    if (cursorCoordinates?.y > boxDimensions?.height * 0.8) {
        menuStyle = {
            ...menuStyle,
            bottom: boxDimensions.height - cursorCoordinates.y,
        };
    } else {
        menuStyle = {
            ...menuStyle,
            marginTop: cursorCoordinates.y,
        };
    }

    return (
        <div className="relative">
            {enableScreenshot && (
                <Dropdown
                    menu={{
                        items: menuItems,
                        onClick: ({ key }) => {
                            menuItems.find((obj) => obj.key === Number(key))?.clickHandler();
                        },
                    }}
                    trigger={["click"]}
                >
                    <FiMoreVertical
                        className={`absolute right-2 z-40 rounded w-8 h-8 px-1.5 text-gray-500 cursor-pointer hover:bg-backgroundColor hover:text-indigo-700`}
                        style={{
                            top: `${menuButtonPosition === "outside" ? (menuLocation?.top ? menuLocation?.top : "-60px") : "2px"}`,
                        }}
                    />
                </Dropdown>
            )}
            <div className="relative">
                {/* {showMenu && (
                <div className="absolute z-50 p-2 bg-white border border-gray-200 rounded shadow-md shadow-gray-300" style={menuStyle}>
                    <ul className="my-auto">
                        {menuItems.map((item) => (
                            <li className="flex gap-4 items-center font-medium rounded py-1 px-2 cursor-pointer hover:bg-backgroundColor hover:text-indigo-700" onClick={item.clickHandler}>
                                {item.icon}
                                {item.label}
                            </li>
                        ))}
                    </ul>
                </div>
            )} */}
                <Modal open={showPinModal} onCancel={() => setShowPinModal(false)} footer={null}>
                    <div className="flex gap-2 items-center mb-2">
                        <MdOutlineDashboardCustomize className="w-8 h-8" color="#3a0ca3" />
                        <h3 className="my-0 text-indigo-700 font-bold text-xl">Add to my dashboard</h3>
                    </div>
                    <div className="flex gap-4">
                        <Formik initialValues={{ title: "" }} onSubmit={onSubmitWidgetForm}>
                            {({ handleChange, handleSubmit }) => (
                                <form className="flex flex-col gap-6 flex-1" onSubmit={handleSubmit}>
                                    <fieldset className="flex flex-col gap-1 pl-10">
                                        <label className="text-steel font-semibold text-base">Name</label>
                                        <input type="text" name="title" placeholder="Add a name" onChange={handleChange} className={`p-2 border rounded border-gray-300 focus:border-steel focus:outline-none`} />
                                    </fieldset>
                                    <button type="submit" disabled={formSubmissionState === "Submitted"} className="self-end bg-indigo-700 hover:bg-indigo-500 text-white font-semibold shadow-button py-2 rounded-md px-6">
                                        {formSubmissionState === "Submitted" ? "Saving ..." : "Save"}
                                    </button>
                                </form>
                            )}
                        </Formik>
                    </div>
                </Modal>

                <div ref={ref}>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
            </div>
        </div>
    );
}

export { Highcharts };

function Basket({ id, name, type = "system", description, group, createdBy = undefined, reference_line = null, createdAt, updatedAt, basketFilters }) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.group = group;
    this.basketFilters = basketFilters;
    this.description = description;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.reference_line = reference_line;
    this.loadVizData = loadVizData;
    this.storeVizData = storeVizData;
    this.clearVizData = clearVizData;
    return this;
}

function loadVizData(type, custIndex) {
    const key = `${this.id}-${custIndex}-${type}`;
    const result = localStorage.getItem(key);
    return result && JSON.parse(result);
}

function storeVizData(type, custIndex, data) {
    const key = `${this.id}-${custIndex}-${type}`;
    const encodedData = JSON.stringify(data);
    return localStorage.setItem(key, encodedData);
}

function clearVizData() {
    let basketKeys = Object.keys(localStorage).filter((key) => key.includes(this.id));
    for (let key of basketKeys) {
        localStorage.removeItem(key);
    }
    return basketKeys;
}

export { Basket };

import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Input, message, Spin, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { DeleteOutlined, PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { SquaresPlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useBasketContext } from "../../contexts/BasketProvider";
import { CarIcon } from "../Icons/CarIcon";
import { gql, useMutation } from "@apollo/client";
import { GET_BASKETS } from "../../contexts/BasketProvider";
import FiltersContext from "../../contexts/Filters";
import { useUserContext } from "../../contexts/User";

const LoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

const UPDATE_BASKET_MUTATION = gql`
    mutation updateBasket($id: uuid!, $name: String, $basketgroup: String, $description: String, $filters: [re_rentaleye_basket_filters_insert_input!]!) {
        delete_re_rentaleye_basket_filters(where: { basket_id: { _eq: $id } }) {
            returning {
                basket_id
            }
        }

        insert_re_rentaleye_basket_filters(objects: $filters) {
            returning {
                id
            }
        }
    }
`;

const INSERT_BASKET_MUTATION = gql`
    mutation insertBasket($name: String!, $basketgroup: String, $description: String, $country: String, $filters: [re_rentaleye_basket_filters_insert_input!]!) {
        insert_re_rentaleye_baskets_one(object: { name: $name, basketgroup: $basketgroup, description: $description, country: $country, basket_filters: { data: $filters } }) {
            id
        }
    }
`;
const UPDATE_BASKETS_MUTATION = gql`
    mutation updateBasket($id: uuid!, $object: re_rentaleye_baskets_set_input, $filters: [re_rentaleye_basket_filters_insert_input!]!) {
        update_re_rentaleye_baskets(where: { id: { _eq: $id } }, _set: $object) {
            returning {
                id
            }
        }
        delete_re_rentaleye_basket_filters(where: { basket_id: { _eq: $id } }) {
            returning {
                basket_id
            }
        }
        insert_re_rentaleye_basket_filters(objects: $filters) {
            returning {
                id
            }
        }
    }
`;

const basketSchemaValidation = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    basketgroup: Yup.string().default("no Group"),
    description: Yup.string(),
    vehicles: Yup.array().min(1),
});

function CreateBasketForm({ initialValues }) {
    // const { refetch } = useQuery(GET_BASKETS);
    const { userBaskets, basketCollaborators, basket, vehicles, emptyBasket, updateState, removeVehicle, setBasketModal, refetchUserBaskets, editBasket, getBasketType } = useBasketContext();
    const { user } = useUserContext();
    const { getFilterValue } = useContext(FiltersContext);
    const countryValue = getFilterValue("filterCountryId")[0];
    const [updateBasket] = useMutation(UPDATE_BASKET_MUTATION);
    const [updateBaskets] = useMutation(UPDATE_BASKETS_MUTATION);
    const [insertBasket] = useMutation(INSERT_BASKET_MUTATION);
    const [createGroup, setCreateGroup] = useState(false);
    const history = useHistory();
    let [formStatus, setFormStatus] = useState("");
    const userBasketGroup = userBaskets
        .map((basket) => basket.group)
        .filter((item, index, self) => {
            return index === self.indexOf(item);
        });

    useEffect(() => {
        if (formStatus === "success") {
            // emptyBasket();
            history.push({
                pathname: "/baskets",
            });
            setFormStatus("");
        }
    }, [formStatus]);

    function onSaveSubmit(values) {
        if (!values.name && !values.description && !values.basketgroup) {
            let filters = vehicles.map((vehicle) =>
                Object.assign({
                    type: "version_std",
                    value: vehicle.Vehicle,
                    basket_id: basket.id,
                }),
            );

            let mutationVariables = {
                id: basket.id,
                filters: filters,
            };
            setFormStatus("submit");
            updateBasket({
                variables: mutationVariables,
                refetchQueries: [
                    {
                        query: GET_BASKETS,
                    },
                ],
            })
                .then((res) => {
                    // Trigger update to change the updated_at date
                    editBasket(basket.id, {});
                    setFormStatus("success");
                    emptyBasket();
                    updateState({
                        isBasketModalOpened: false,
                        selectedBasketGroup: undefined,
                    });
                    refetchUserBaskets({ country: countryValue });
                    message.success({
                        content: "Your basket had been updated",
                        style: {
                            top: "80px",
                            right: "20px",
                            position: "absolute",
                        },
                        duration: 2,
                    });
                })
                .catch((error) => {
                    if (error.name === "ApolloError") {
                        setFormStatus("error");
                        message.error({
                            content: "Network error: Unable to update the basket",
                            style: {
                                top: "80px",
                                right: "20px",
                                position: "absolute",
                            },
                            duration: 2,
                        });
                    }
                });
        } else {
            let filters = values?.vehicles.map((vehicle) =>
                Object.assign({
                    type: "version_std",
                    value: vehicle.Vehicle,
                    basket_id: basket.id,
                }),
            );
            let mutationVariables = {};
            Object.entries(values).forEach(([key, value]) => {
                if (value !== undefined && key !== "vehicles") {
                    if (key === "basketgroup" && value === "") {
                        value = "No Group";
                    }
                    mutationVariables[key] = value;
                }
            });
            // let mutationVariables = {
            //     id: basket.id,
            //     name: values?.name === undefined ? basket.name : values?.name,
            //     basketgroup: values?.basketgroup === undefined ? basket.basketgroup : values?.basketgroup,
            //     description: values?.description === undefined ? basket.description : values?.description,
            //     country: countryValue,
            //     filters: filters,
            // };
            setFormStatus("submit");
            updateBaskets({
                variables: {
                    id: basket.id,
                    object: mutationVariables,
                    filters: filters,
                },
                refetchQueries: [
                    {
                        query: GET_BASKETS,
                    },
                ],
            })
                .then((res) => {
                    setFormStatus("success");
                    emptyBasket();
                    updateState({
                        isBasketModalOpened: false,
                        selectedBasketGroup: undefined,
                    });
                    refetchUserBaskets({ country: countryValue });
                    message.success({
                        content: "Your basket had been updated",
                        style: {
                            top: "80px",
                            right: "20px",
                            position: "absolute",
                        },
                        duration: 2,
                    });
                    this.forceUpdate();
                })
                .catch((error) => {
                    if (error.name === "ApolloError") {
                        setFormStatus("error");
                        message.error({
                            content: "Network error: Unable to update the basket",
                            style: {
                                top: "80px",
                                right: "20px",
                                position: "absolute",
                            },
                            duration: 2,
                        });
                    }
                });
        }
    }

    function onSaveAsSubmit(values) {
        let filters = values.vehicles.reduce((acc, vehicle) => {
            Object.entries(vehicle).forEach(([key, value]) => {
                if (key === "Vehicle") {
                    acc.push(
                        Object.assign({
                            type: "version_std",
                            value: value,
                        }),
                    );
                }
                if (key === "model" && value && acc.filter((item) => item.value === value && item.type === key).length <= 0) {
                    acc.push(
                        Object.assign({
                            type: key,
                            value: value,
                        }),
                    );
                }
            });
            return acc;
        }, []);
        let mutationVariables = {
            name: values.name,
            basketgroup: values.basketgroup ?? "No Group",
            description: values.description,
            country: countryValue,
            filters: filters,
        };
        setFormStatus("submit");
        insertBasket({
            variables: mutationVariables,
            refetchQueries: [
                {
                    query: GET_BASKETS,
                },
            ],
        })
            .then((res) => {
                setFormStatus("success");
                emptyBasket();
                // selectBasketGroup(undefined);
                // setBasketModal(false);
                updateState({
                    isBasketModalOpened: false,
                    selectedBasketGroup: undefined,
                });
                refetchUserBaskets({ country: countryValue });
                message.success({
                    content: "Your basket had been saved",
                    style: {
                        top: "80px",
                        right: "20px",
                        position: "absolute",
                    },
                    duration: 2,
                });
                // this.forceUpdate();
            })
            .catch((error) => {
                if (error.name === "ApolloError") {
                    setFormStatus("error");
                    message.error({
                        content: "Network error: Unable to save the basket",
                        style: {
                            top: "80px",
                            right: "20px",
                            position: "absolute",
                        },
                        duration: 2,
                    });
                }
            });
    }

    function ClearBasket() {
        emptyBasket();
        updateState({
            isBasketModalOpened: false,
            selectedBasketGroup: undefined,
        });
    }
    const editPermission = basket?.type !== "system" && (basket?.createdBy === user.auth0_id || basketCollaborators.findIndex((item) => item.email === user.username && item.role === "manager") !== -1);

    let form = (
        <Formik initialValues={initialValues} onSubmit={onSaveAsSubmit} validationSchema={basketSchemaValidation} validateOnChange={false} validateOnBlur={false}>
            {({ errors, handleSubmit, handleChange, values }) => {
                return (
                    <div className="self-center flex flex-col p-2 mt-4">
                        {/* <h3 className='text-lg font-medium'>Create new basket</h3> */}
                        <Form className="flex flex-col items-stretch gap-2" onSubmit={handleSubmit}>
                            <div className="grid grid-cols-[100px_1fr] gap-2">
                                <label htmlFor="name">Name</label>
                                <div>
                                    <Input name="name" onChange={handleChange} placeholder="Audi basket" status={errors.name && "error"} value={values.name} className="w-full" />
                                    {errors.name && <span className="col-start-2 col-end-span text-sm text-red">{errors.name}</span>}
                                </div>
                                <label htmlFor="basketgroup">Basket group</label>
                                <div>
                                    {createGroup === false && (
                                        <div className="flex flex-row gap-2 items-center">
                                            <select name="basketgroup" onChange={handleChange} value={values.basketgroup} className="flex gap-2 items-center rounded-sm border border-gray-300 px-2 py-1 text-indigo-700 font-medium hover:bg-backgroundColor w-64 focus:outline-none">
                                                <option value="">Select group</option>
                                                {userBasketGroup.map((group) => (
                                                    <option key={group} value={group}>
                                                        {group}
                                                    </option>
                                                ))}
                                            </select>
                                            <button className="flex gap-2 items-center rounded-sm border border-gray-300 px-2 py-1 text-indigo-700 font-medium hover:bg-backgroundColor" type="button" onClick={() => setCreateGroup(!createGroup)}>
                                                <SquaresPlusIcon width={20} height={20} />
                                                <span>Create new group</span>
                                            </button>
                                        </div>
                                    )}
                                    {createGroup === true && (
                                        <div className="flex flex-row gap-4 items-center w-96">
                                            <Input name="basketgroup" onChange={handleChange} placeholder="Group Audi" status={errors.basketgroup && "error"} value={values.basketgroup} />
                                            <button className="flex gap-2 items-center rounded border-2 border-gray-200 px-2 py-1 text-indigo-700 font-medium hover:bg-backgroundColor " type="button" onClick={() => setCreateGroup(!createGroup)}>
                                                <XMarkIcon width={20} height={20} />
                                                <span>Cancel</span>
                                            </button>
                                        </div>
                                    )}
                                    {errors.basketgroup && <span className="col-start-2 text-sm text-red">{errors.basketgroup}</span>}
                                </div>
                                <label htmlFor="description">Description</label>
                                <TextArea name="description" onChange={handleChange} value={values.description} />
                            </div>
                            {basketCollaborators.length > 0 && (
                                <div className="grid grid-cols-[100px_1fr] gap-2">
                                    <span>Owner:</span>
                                    <span>{basketCollaborators.find((assignment) => assignment.role === "owner")?.email}</span>
                                    <span>Type</span>
                                    <span>{getBasketType(user)}</span>
                                    <span>Editors:</span>
                                    <ul className="inline-flex gap-2 list-none my-auto">
                                        {basketCollaborators
                                            .filter((assignment) => assignment.role === "manager")
                                            .slice(0, 3)
                                            .map((assignment) => (
                                                <li>{assignment.email}</li>
                                            ))}
                                        {basketCollaborators.filter((assignment) => assignment.role === "manager").length > 3 && (
                                            <Tooltip
                                                title={basketCollaborators
                                                    .filter((assignment) => assignment.role === "manager")
                                                    .map((assignment) => assignment.email)
                                                    .slice(3)
                                                    .join(", ")}
                                            >
                                                <li className="text-secondary-500 font-semibold text-sm">(...)</li>
                                            </Tooltip>
                                        )}
                                    </ul>
                                    <span>Viewers:</span>
                                    <ul className="inline-flex gap-2 list-none my-auto">
                                        {basketCollaborators
                                            .filter((assignment) => assignment.role === "reader")
                                            .slice(0, 3)
                                            .map((assignment) => (
                                                <li>{assignment.email}</li>
                                            ))}
                                        {basketCollaborators.filter((assignment) => assignment.role === "reader").length > 3 && (
                                            <Tooltip
                                                title={basketCollaborators
                                                    .filter((assignment) => assignment.role === "reader")
                                                    .map((assignment) => assignment.email)
                                                    .slice(3)
                                                    .join(", ")}
                                            >
                                                <li className="text-secondary-500 font-semibold text-sm">(...)</li>
                                            </Tooltip>
                                        )}
                                    </ul>
                                </div>
                            )}
                            <div className="mt-1.5 border-b border-indigo-500 border-[1px]" />
                            <div className="px-2">
                                {initialValues.vehicles.map((vehicle, index) => (
                                    // <li key={vehicle.idRecord} className="py-1">
                                    //     {vehicle.Vehicle}
                                    // </li>
                                    <div key={index} className="flex items-center gap-4">
                                        <CarIcon
                                            width={25}
                                            height={25}
                                            style={{
                                                fill: "#3a0ca3",
                                            }}
                                        />
                                        <div className="flex-1">
                                            <span>{vehicle.Vehicle}</span>
                                        </div>
                                        <Button
                                            type="text"
                                            onClick={() => {
                                                removeVehicle(vehicle);
                                            }}
                                            icon={
                                                <DeleteOutlined
                                                    style={{
                                                        color: "#dc143c",
                                                    }}
                                                />
                                            }
                                        ></Button>
                                    </div>
                                ))}
                                <a
                                    className="my-2 py-2 flex items-center gap-2 hover:bg-backgroundColor"
                                    onClick={() => {
                                        history.push({
                                            pathname: "/vehicles",
                                        });
                                        setBasketModal(false);
                                    }}
                                >
                                    <PlusOutlined
                                        style={{
                                            color: "#490fcc",
                                            fontSize: "18px",
                                        }}
                                    />
                                    <span className="text-sm text-gray-500 font-semibold">Add vehicles</span>
                                </a>
                            </div>
                            <div className="flex justify-center gap-4">
                                {editPermission && (
                                    <button
                                        className="self-center py-2 px-4 bg-indigo-700 hover:bg-indigo-700 rounded text-white font-semibold disabled:bg-gray-300"
                                        // disabled={basket.createdBy !== user.auth0_id}
                                        onClick={() => {
                                            onSaveSubmit(values);
                                        }}
                                        type="button"
                                    >
                                        Save
                                    </button>
                                )}
                                <button className="self-center py-2 px-4 bg-indigo-700 hover:bg-indigo-700 rounded text-white font-semibold" type="submit">
                                    Save as
                                </button>
                                <button
                                    className="self-center py-2 px-4 bg-red hover:bg-secondary-500 rounded text-white font-semibold"
                                    type="button"
                                    onClick={() => {
                                        ClearBasket();
                                    }}
                                >
                                    Clear
                                </button>
                            </div>
                        </Form>
                    </div>
                );
            }}
        </Formik>
    );

    return formStatus === "submit" ? <Spin indicator={LoadingIcon}>{form}</Spin> : form;
}

export { CreateBasketForm };

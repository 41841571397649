import React from "react";
import { LoadingComponent } from "@gooddata/sdk-ui";

import { TrendChart } from "../Visualization/TrendChart";

function BasketLineChart({ kpiSettings, changesByMonth, title, subtitle = undefined, categories, minValue, maxValue, data = [], width = null, height = null, chartProps = {}, custIndex, filters, displayMode, ...props }) {
    const settingsAliases = {
        flag_ser: "Maintenance",
        flag_bre: "Breakdown",
        flag_ins: "Insurance",
        flag_rep: "Replacement",
        flag_fin: "Financial loss",
        flag_co2: "CO2 Malus",
        flag_tyr: "Tyres",
        flag_win: "Winter Tyres",
        flag_evb: "EV Bonus",
    };
    const services = Object.keys(kpiSettings)
        .filter((key) => kpiSettings[key] === true)
        .map((key) => settingsAliases[key]);

    return data.length <= 0 ? (
        <div className="p-10">
            <LoadingComponent />
        </div>
    ) : (
        <div className="border-2 border-gray-300 rounded">
            <TrendChart
                services={services}
                changesByMonth={changesByMonth}
                metadata={{ custIndex, displayMode, flag_services: kpiSettings }}
                section={"basketline"}
                filters={filters}
                id={"basket-line-chart"}
                title={title}
                subtitle={subtitle}
                categories={categories}
                legendStyle={{
                    fontSize: "10px",
                    fontWeigth: "600",
                    color: "#595959",
                }}
                series={data}
                width={width}
                height={height}
                {...chartProps}
                minValue={minValue}
                maxValue={maxValue}
                scaleUnit="datetime"
                {...props}
            />{" "}
        </div>
    );
}

export { BasketLineChart };

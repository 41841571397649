import React, { useEffect, useState, useRef } from "react";

const ModeDisplayFilter = ({ elements = [], defaultElement, inputName, inputLabel, onChange = (item) => {}, helpMessage = "" }) => {
    // const { displayMode, changeMode } = useContext(FiltersContext);
    const [showHelp, setShowHelp] = useState(false);

    const ref = useRef();

    function onSelectMode(item) {
        onChange(item);
    }

    useEffect(() => {
        function onHoverDisplay() {
            setShowHelp(true);
        }
        ref?.current?.addEventListener("mouseenter", onHoverDisplay);
        ref?.current?.addEventListener("mouseleave", () => {
            setShowHelp(false);
        });
        return () => {
            ref?.current?.removeEventListener("mouseenter", onHoverDisplay);
        };
    }, [ref]);

    return (
        <fieldset className="grid grid-cols-3 w-full">
            <div className="relative flex flex-col items-stretch col-span-1">
                <span ref={ref} className="w-max font-medium text-sm text-indigo-700 hover:cursor-help">
                    {inputLabel}
                </span>
                {showHelp && <span className="absolute top-6 -left-4 z-10 w-max px-2 py-1 bg-gray-700 rounded-md text-white text-md">{helpMessage}</span>}
            </div>
            <div className="col-span-2 grid grid-cols-2 gap-2 ml-4 w-full">
                {elements.map((item) => (
                    <div key={item.key} className={`flex items-center gap-1 col-span-1`} aria-disabled={item.disabled}>
                        <input
                            type="radio"
                            id={item.key}
                            name={inputName}
                            disabled={item.disabled}
                            checked={!item.disabed && defaultElement.key === item.key}
                            onChange={(event) => onSelectMode(item)}
                            style={{
                                accentColor: "#6B50B6",
                            }}
                        />
                        <label htmlFor={item.key} className={(defaultElement.key === item.key ? "text-indigo-500" : "text-gray-500") + " w-max text-sm font-medium" + (item.disabled ? " cursor-not-allowed" : " cursor-pointer")}>
                            {item.title}
                        </label>
                    </div>
                ))}
            </div>
        </fieldset>
        // <div className="flex items-center gap-4">
        //     <span ref={ref}  className="font-bold text-base text-indigo-700 hover:cursor-help">{inputLabel}</span>
        //     <Dropdown
        //     menu={{
        //         items: elements,
        //         onClick: (e) => {
        //             const selectedItem = elements.find(elem => elem.key === e.key);
        //             onSelectMode(selectedItem);
        //         }
        //      }}
        //     arrow={{
        //         pointAtCenter: true
        //     }}
        // >
        //         <Space>
        //             <span>{defaultElement.title}</span>
        //             <ArrowDownIcon width={20} height={15} />
        //         </Space>
        // </Dropdown>
        // </div>
    );
};

export default ModeDisplayFilter;

import React from "react";
import ProgressBar from "./ProgressBar";

const LoadingPopup = ({ isOpen, message, progress }) => {
    if (!isOpen) return null;

    const getProgressMessage = (progress) => {
        if (progress < 5) return "Initializing...";
        if (progress < 95) return "Generating pages...";
        if (progress < 100) return "Finalizing PDF...";
        return "Complete!";
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm w-full mx-4">
                <div className="flex flex-col items-center gap-4">
                    <h3 className="text-lg font-semibold text-gray-700">{message}</h3>
                    <ProgressBar progress={progress} />
                    <div className="text-sm text-gray-500 flex flex-col items-center">
                        <p>{progress.toFixed(0)}% Complete</p>
                        <p className="text-xs mt-1">{getProgressMessage(progress)}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingPopup;

import React, { useState } from "react";
import _ from "lodash";
import { Alert, Checkbox, Input, Modal, Popconfirm } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAppContext } from "../../contexts/AppContext";
import { Dropdown, DropdownCheckbox } from "../controls/DropdownCheckbox";
import { FiMinusCircle } from "react-icons/fi";
import { FaRegCheckCircle } from "react-icons/fa";

const CarSelectorConfigModal = ({ showModal, title, setShowModal = (flag) => {}, onSubmit = (data) => Promise.resolve(undefined), players = [], action = "editCustomField", ...props }) => {
    // completed | loading
    const [submitFormState, setSubmitFormState] = useState("");
    const { getCustlabelByCustId } = useAppContext();
    const operationItems = Object.keys(operations).map((operation, index) =>
        Object.assign({
            key: index,
            label: operations[operation],
        }),
    );

    const initValues = action === "editCustomField" ? { fieldName: "", players: [], operation: operations.avg } : props.configField ? { ...props.configField, players: [...props.configField.players] } : { field_name: "", operation: "Showing", players: [] };
    const selectPlayersFormSchema = Yup.object().shape({
        players: Yup.array().min(1, "Please, select at least one player"),
    });

    return (
        <Modal open={showModal} onCancel={() => setShowModal(false)} footer={null} title={title} width={700}>
            <div>
                <Formik
                    initialValues={initValues}
                    validationSchema={action === "editCustomField" ? customFieldFormSchema : selectPlayersFormSchema}
                    onSubmit={(values) => {
                        setSubmitFormState("loading");
                        onSubmit({ ...values })
                            .then((res) => {
                                setShowModal(false);
                            })
                            .finally(() => {
                                setSubmitFormState("completed");
                            });
                    }}
                >
                    {({ values, handleChange, handleSubmit, setFieldValue, isValid, errors }) =>
                        action === "editCustomField" ? (
                            <form className="grid grid-cols-2 grid-rows-3 gap-8" onSubmit={handleSubmit}>
                                <fieldset
                                    className="col-span-full"
                                    // className="flex flex-col gap-1 pl-10"
                                >
                                    <label className="text-steel font-semibold text-base w-12">Label</label>
                                    <Input
                                        name="fieldName"
                                        placeholder="EE_AVG_CUST1_CUST3"
                                        maxLength={15}
                                        onChange={handleChange}
                                        style={{
                                            borderColor: "#6B50B6",
                                        }}
                                        // className="border border-indigo-500 focus:outline-indigo-500"
                                    />
                                    <div className="h-4">{values.fieldName && <span className="text-xs font-semibold text-gray-500">({values.fieldName.length}/15)</span>}</div>
                                </fieldset>
                                <fieldset className="flex flex-col gap-1">
                                    <label className="text-steel font-semibold text-base">Players</label>
                                    <DropdownCheckbox
                                        title={"Select players"}
                                        items={players
                                            .map((item, index) =>
                                                Object.assign({
                                                    key: index,
                                                    label: getCustlabelByCustId(item),
                                                    value: item,
                                                }),
                                            )
                                            .sort((a, b) => a.label.localeCompare(b.label))}
                                        name="players"
                                        onChange={handleChange}
                                        onSelectItems={(players) => setFieldValue("players", players)}
                                    />
                                </fieldset>
                                <fieldset className="flex flex-col gap-1">
                                    <label className="text-steel font-semibold text-base">Operation</label>
                                    <Dropdown
                                        title={"Select an operation"}
                                        items={operationItems}
                                        onSelect={({ key }) => {
                                            setFieldValue("operation", operationItems[key].label);
                                        }}
                                    />
                                </fieldset>
                                <button type="submit" className={`mt-8 col-start-2 bg-indigo-700 hover:bg-indigo-500 text-white font-semibold shadow-button py-2 rounded-md px-6 ${submitFormState === "loading" || !isValid ? "opacity-40" : ""}`} disabled={!isValid || submitFormState === "loading"}>
                                    {/* {formSubmissionState === "Submitted" ? "Saving ..." : "Save"} */}
                                    {submitFormState === "loading" ? "Calculating your field..." : `Save`}
                                </button>
                            </form>
                        ) : (
                            <>
                                <div className="flex flex-col gap-1">
                                    {!errors?.players ? <label className="text-steel font-semibold text-base">* Only the activated players will be shown in the Car selector section</label> : <Alert message={errors.players} type="error" showIcon />}
                                    <Checkbox
                                        style={{
                                            padding: "0px 8px",
                                        }}
                                        onChange={(e) => {
                                            const newPlayers = e.target.checked ? [...players] : [];
                                            setFieldValue("players", newPlayers);
                                        }}
                                        checked={values.players.length === players.length}
                                        indeterminate={values.players.filter((player) => players.includes(player)).length > 0 && values.players.length !== players.length}
                                    >
                                        <span className="hover:font-semibold">{values.players.length === players.length ? "Hide all" : "Select all"}</span>
                                    </Checkbox>
                                </div>

                                <form className="flex flex-col items-stretch" onSubmit={handleSubmit}>
                                    <ul className={`z-20 grid grid-cols-2 gap-2 py-4 w-full`}>
                                        {players
                                            .map((item, index) =>
                                                Object.assign({
                                                    key: index,
                                                    label: getCustlabelByCustId(item),
                                                    value: item,
                                                }),
                                            )
                                            .sort((a, b) => a.label.localeCompare(b.label))
                                            .map((item, index) => {
                                                const isSelected = values.players.find((obj) => obj === item.value) !== undefined;
                                                const colSize = Math.round(players.length / 2);
                                                const colIndex = index < colSize ? 1 : 2;
                                                const rowIndex = index < colSize ? index + 1 : index - colSize + 1;

                                                return (
                                                    <li
                                                        className={`flex items-center gap-2 hover:bg-indigo-100 py-1 px-2 cursor-pointer ${isSelected ? "text-indigo-500" : "text-gray-300"}`}
                                                        style={{
                                                            gridRowStart: rowIndex,
                                                            gridColumnStart: colIndex,
                                                        }}
                                                        key={item.key}
                                                        onClick={() => {
                                                            const arr = values.players;
                                                            const index = arr.findIndex((obj) => obj === item.value);
                                                            if (index !== -1) arr.splice(index, 1);
                                                            else arr.push(item.value);
                                                            setFieldValue("players", [...arr]);
                                                        }}
                                                    >
                                                        <FaRegCheckCircle />
                                                        <label htmlFor={item.value} className={`cursor-pointer font-medium`}>
                                                            {item.label}
                                                        </label>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                    <button
                                        type="submit"
                                        className={`w-48 mt-8 self-end bg-indigo-700 hover:bg-indigo-500 text-white font-semibold cursor-pointer shadow-button py-2 rounded-md px-6 ${submitFormState === "loading" || !isValid ? "opacity-40" : ""}`}
                                        disabled={values.players.filter((player) => players.includes(player)).length === 0 || !isValid || submitFormState === "loading"}
                                    >
                                        {submitFormState === "loading" ? "Loading..." : `Save`}
                                    </button>
                                </form>
                            </>
                        )
                    }
                </Formik>
            </div>
        </Modal>
    );
};

const DropCustomField = ({ field, onDelete = (field) => {}, ...props }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    return (
        <Popconfirm title={"Are you sure"} showCancel={false} placement={"bottomRight"} onConfirm={() => onDelete(field)}>
            <div className="p-1 rounded hover:bg-gray-200 text-gray-300 hover:text-red">
                <FiMinusCircle width={50} height={50} className="cursor-pointer" onClick={() => setShowConfirmation(!showConfirmation)} />
            </div>
        </Popconfirm>
    );
};

const customFieldFormSchema = Yup.object().shape({
    fieldName: Yup.string().required("Enter name"),
    players: Yup.array().min(2),
});

const operations = Object.freeze({
    avg: "Average",
    min: "Minimum",
    max: "Maximum",
    median: "Median",
    calculate: (operands, operator, toRound = (value) => value) => {
        let res;
        operands = operands.filter((obj) => obj !== null).sort((a, b) => a - b);
        if (operator === operations.avg) res = operands.reduce((acc, item) => acc + item, 0) / operands.length || null;
        else if (operator === operations.min) res = operands[0] || null;
        else if (operator === operations.max) res = operands[operands.length - 1] || null;
        else res = _.mean(operands) || null;
        return res !== null ? toRound(res) : null;
    },
});

const customFieldColors = ["#506e96", "#6c757d", "#ff7d93"];

export { CarSelectorConfigModal, DropCustomField, operations, customFieldColors };

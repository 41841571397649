import React from "react";

const ActionButton = ({ title, icon = null, suffixIcon = null, ...props }) => {
    return (
        <button className="flex justify-between items-center gap-2 py-2 px-4 text-steel text-sm font-bold hover:bg-backgroundColor rounded border border-b-4 border-gray-300 hover:border-indigo-700 disabled:border-gray-300" {...props}>
            {icon} {title} {suffixIcon}
        </button>
    );
};

export { ActionButton };

import React, { useContext, useState } from "react";

import HighchartsComponent from "../HighchartsComponent";
import { useBrandConfigurator } from "../../contexts/BrandConfiguratorProvider";
import MeasuresContext from "../../contexts/Measures";

import { DownOutlined, UpOutlined } from "@ant-design/icons";

import "../../styles/brandModelAnalysis.css";

const VersionAnalysisReport = ({ filters, filterSelectors, ...props }) => {
    const widgetId = "version-analysis-chart";
    const { deselectConfigurator } = useBrandConfigurator();
    const { selectedKpi } = useContext(MeasuresContext);
    const { title, subtitle } = props;

    const dataVersionsFlat = Object.entries(filterSelectors)
        .reduce((acc, [brand, modelsObj]) => {
            const flatVersionsByModel = Object.entries(modelsObj)
                .reduce((accModel, [model, versionArr]) => {
                    const arr = versionArr.map((versionObj) => ({
                        ...versionObj,
                        model: model,
                    }));
                    return [...accModel, ...arr];
                }, [])
                .map((obj) => ({
                    ...obj,
                    brand: brand,
                }));
            return [...acc, ...flatVersionsByModel];
        }, [])
        .sort((a, b) => (a.promo && b.promo ? a.promo - b.promo : a.config - b.config));

    const categories = dataVersionsFlat.map((elem) => elem.label);
    const lines = dataVersionsFlat.map((obj) => (obj.promo < obj.config ? obj.promo || obj.config : obj.config || obj.promo)).map((val) => selectedKpi.roundValue(val));

    const showFullGraph = categories.length <= 8;
    const report = (
        <HighchartsComponent
            widgetProps={{
                id: widgetId,
                filters,
                section: "oem-views",
            }}
            options={{
                chart: {
                    type: "bar",
                    height: categories.length > 15 ? categories.length * 30 : props.height,
                    marginLeft: props.widgetUid ? 270 : 480,
                },
                plotOptions: {
                    scatter: {
                        stickyTracking: false,
                        showInLegend: false,
                        // findNearestPointBy: 'y',
                        marker: {
                            radius: 5,
                        },
                    },
                    bar: {
                        series: {
                            groupPadding: 0.3,
                            stacking: "normal",
                        },
                        pointWidth: categories.length <= 7 ? 22 : 18,
                    },
                },
                title: {
                    text: title,
                    align: "center",
                    style: {
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#3a0ca3",
                    },
                },
                subtitle: {
                    text: subtitle,
                    align: "center",
                    style: {
                        fontSize: "12px",
                        fontWeight: 600,
                        color: "#506e96",
                    },
                },
                xAxis: {
                    categories: categories,
                    labels: {
                        padding: 3,
                        style: {
                            textOverflow: "ellipsis",
                            // whiteSpace: 'nowrap',
                            overflow: "ellipsis",
                        },
                        useHTML: true,
                        formatter: function () {
                            return props.widgetUid ? this.value : `<span class="versionxAxisLabel">${this.value}</span>`;
                        },
                        events: {
                            click: function (event) {
                                if (props.widgetUid) return;
                                const versionObj = dataVersionsFlat.find((item) => item.label === event.target.textContent);
                                deselectConfigurator(versionObj.brand, versionObj.model, [versionObj]);
                            },
                        },
                    },
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                },
                tooltip: {
                    formatter: function () {
                        return `${this.x.name}<br/>${this.series.name} <b>${this.y}</b>`;
                    },
                },
                series: [
                    {
                        name: "Cheapest",
                        type: "bar",
                        data: lines,
                        color: "white",
                        borderColor: "#3a0ca3",
                        borderWidth: 2,
                        enableMouseTracking: false,
                    },
                    {
                        name: "OEM promo",
                        type: "scatter",
                        color: "#ff7d93",
                        legendSymbol: "circle",
                        showInLegend: dataVersionsFlat.filter((serie) => serie.promo !== null).length > 0,
                        legend: {
                            symbolRadius: 0,
                        },
                        data: dataVersionsFlat.map((serie) =>
                            // Object.assign({
                            //     x: serie.promo,
                            //     y: serie.label
                            //     // color: BrandColors[serie.name]
                            // }),
                            serie.promo ? selectedKpi.roundValue(serie.promo) : null,
                        ),
                    },
                    {
                        name: "OEM Config",
                        type: "scatter",
                        color: "#3a0ca3",
                        showInLegend: dataVersionsFlat.filter((serie) => serie.config !== null).length > 0,
                        legend: {
                            symbolRadius: 0,
                        },
                        data: dataVersionsFlat.map((serie) =>
                            // Object.assign({
                            //     x: serie.config,
                            //     y: serie.label
                            //     // color: BrandColors[serie.name]
                            // }),
                            serie.config ? selectedKpi.roundValue(serie.config) : null,
                        ),
                        marker: {
                            symbol: "circle",
                            fillColor: "#3a0ca3",
                        },
                    },
                ],
                legend: {
                    useHTML: true,
                    labelFormatter: function () {
                        return this.name === "Cheapest" ? `<div style="display:flex; gap:3px; align-items: center; "><div style="width:12px; height:12px; border: 2px solid #3a0ca3;"></div> <span>${this.name}</span></div>` : this.name;
                    },
                },
            }}
            menuButtonPosition={props.widgetUid ? "outside" : "inside"}
            {...props}
        />
    );

    return props.widgetUid ? report : <VersionAnalysisWrapper showFullGraph={showFullGraph} reportComponent={report} />;
};

const VersionAnalysisWrapper = ({ reportComponent, showFullGraph, ...props }) => {
    const [showMore, setShowMore] = useState(false);
    return (
        <div>
            <div
                className={!showFullGraph && !showMore ? "h-[300px] overflow-hidden bg-white" : ""}
                style={
                    !showFullGraph && !showMore
                        ? {
                              maskImage: "linear-gradient(to bottom, black 70%, transparent 100%)",
                              WebkitMaskImage: "linear-gradient(to bottom, black 70%, transparent 100%)",
                          }
                        : {}
                }
            >
                {reportComponent}
                {props.footnote && <span className="pl-3 pb-2 text-left text-xs font-medium">{props.footnote}</span>}
            </div>

            {!showFullGraph && (
                <div
                    className={!showMore ? "mx-auto max-w-fit flex items-center justify-center gap-2 px-4 py-1.5 mb-2 rounded-md hover:bg-backgroundColor cursor-pointer" : "mx-auto max-w-fit flex items-center justify-center mb-2 p-2 rounded-full hover:bg-backgroundColor"}
                    onClick={() => setShowMore(!showMore)}
                >
                    {!showMore ? (
                        <>
                            <DownOutlined
                                // className={"p-2 rounded-full hover:bg-backgroundColor"}
                                style={{
                                    color: "#506e96",
                                }}
                            />
                            <span className="text-steel font-semibold">Show versions</span>
                        </>
                    ) : (
                        <UpOutlined
                            style={{
                                color: "#506e96",
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default VersionAnalysisReport;

import React from "react";

const ProgressBar = ({ progress }) => {
    return (
        <div className="w-full bg-gray-200 rounded-full h-2.5 overflow-hidden">
            <div
                className="bg-indigo-700 h-full rounded-full transition-all duration-500 ease-out"
                style={{
                    width: `${progress}%`,
                    transition: "width 0.5s ease-out",
                }}
            />
        </div>
    );
};

export default ProgressBar;

import React, { useEffect, useRef, useState } from "react";
import { CaretDownFill, CaretUpFill } from "../Icons/CaretIcon";

const ExtendedTable = ({ columns, data, onSort, stickyColumn, columnGroups }) => {
    const contentRef = useRef();
    const scrollRef = useRef();
    const [contentWidth, setContentWidth] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 100;
    const totalPages = Math.ceil(data.length / rowsPerPage);

    const syncScroll = (src, target) => {
        target.current.scrollLeft = src.current.scrollLeft;
    };

    const handleContentScroll = () => syncScroll(contentRef, scrollRef);
    const handleScrollScroll = () => syncScroll(scrollRef, contentRef);
    useEffect(() => {
        const contentElement = contentRef.current;
        const scrollElement = scrollRef.current;

        if (contentElement && scrollElement) {
            //@ts-ignore
            setContentWidth(contentElement?.scrollWidth);
            //@ts-ignore
            contentElement?.addEventListener("scroll", handleContentScroll);
            //@ts-ignore
            scrollElement?.addEventListener("scroll", handleScrollScroll);

            return () => {
                //@ts-ignore
                contentElement?.removeEventListener("scroll", handleContentScroll);
                //@ts-ignore
                scrollElement?.removeEventListener("scroll", handleScrollScroll);
            };
        }
    }, [handleContentScroll, handleScrollScroll, contentRef, scrollRef]);

    const getCurrentPageData = () => {
        const startIndex = (currentPage - 1) * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        return data.slice(startIndex, endIndex);
    };

    return (
        <div className="flex flex-col gap-4">
            <div ref={scrollRef} className="overflow-x-scroll h-5">
                <div style={{ width: `${contentWidth}px` }} />
            </div>
            <div ref={contentRef} className="max-h-[500px] overflow-x-scroll rounded-lg scrollbar-hide shadow-md">
                <table className="table-fixed w-max border-collapse">
                    <colgroup>
                        {columns.map((col) => (
                            <col
                                key={col.key}
                                style={{
                                    minWidth: col.minWidth || "120px",
                                    maxWidth: col.maxWidth || "200px",
                                    width: col.width || "150px",
                                }}
                            />
                        ))}
                    </colgroup>
                    <thead className="sticky top-0 z-10 bg-white shadow-sm">
                        {columnGroups && (
                            <tr className="border-b">
                                {columnGroups.map((group, index) => (
                                    <th key={index} colSpan={group.span} className={group.className || "px-4 py-3 text-gray-700 font-semibold"}>
                                        {group.title}
                                    </th>
                                ))}
                            </tr>
                        )}
                        <tr className="border-b h-8">
                            {columns.map((col) => (
                                <th
                                    key={col.key}
                                    className={`px-3 py-1 text-xs font-medium text-gray-500 transition-colors
                                    ${col.key === stickyColumn ? "sticky left-0 bg-white shadow-sm z-10" : ""}
                                    whitespace-nowrap overflow-hidden text-ellipsis`}
                                >
                                    <span className="flex items-center justify-between">
                                        <div className="flex-1">{col.title}</div>
                                        {onSort && (
                                            <div className="flex flex-col ml-2 opacity-70 hover:opacity-100">
                                                <span>
                                                    <CaretUpFill width={12} onClick={() => onSort(col, "desc")} className="fill-current cursor-pointer text-gray-300 hover:text-indigo-500" />
                                                </span>
                                                <span>
                                                    <CaretDownFill width={12} className="fill-current text-gray-300 cursor-pointer hover:text-indigo-500" onClick={() => onSort(col, "asc")} />
                                                </span>
                                            </div>
                                        )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        {getCurrentPageData().map((row, rowIndex) => (
                            <tr key={rowIndex} className="border-b h-7 hover:bg-gray-50">
                                {columns.map((col) => (
                                    <td
                                        key={col.key}
                                        className={`px-3 py-1 bg-white
                                        ${col.key === stickyColumn ? "sticky left-0 text-left shadow-sm" : "text-center"} 
                                        ${col.key === "Version" ? "text-left" : ""}
                                        text-gray-600 text-[11px]
                                        whitespace-nowrap overflow-hidden text-ellipsis`}
                                    >
                                        {row[col.key]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 rounded-b-lg shadow-sm">
                <div className="flex items-center gap-3">
                    <button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1} className="p-2 rounded-md text-gray-500 hover:bg-gray-100 disabled:opacity-40 disabled:hover:bg-white transition-colors">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="m15 18-6-6 6-6" />
                        </svg>
                    </button>

                    <div className="flex items-center gap-1">
                        <span className="text-sm font-medium text-gray-700">{currentPage}</span>
                        <span className="text-gray-500 text-sm">/</span>
                        <span className="text-sm text-gray-500">{totalPages}</span>
                    </div>

                    <button onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} className="p-2 rounded-md text-gray-500 hover:bg-gray-100 disabled:opacity-40 disabled:hover:bg-white transition-colors">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="m9 18 6-6-6-6" />
                        </svg>
                    </button>
                </div>

                <div>
                    <p className="text-xs text-gray-500">
                        Showing <span className="font-medium">{(currentPage - 1) * rowsPerPage + 1}</span> to <span className="font-medium">{Math.min(currentPage * rowsPerPage, data.length)}</span> of <span className="font-medium">{data.length}</span> results
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ExtendedTable;

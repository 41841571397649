import React, { useContext, useEffect, useState } from "react";
import { ErrorComponent, LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";
import * as Md from "../../md/full";
import { Modal } from "antd";
import MeasuresContext from "../../contexts/Measures";
import { useUserContext } from "../../contexts/User";

const IncrementalExport = ({ execDefinition, corrMakes, toTransform, onComplete, basketList, servicemessage }) => {
    const [data, setData] = useState([]);
    const { selectedAdjDeposit } = useContext(MeasuresContext);
    // Sort corrMakes by name
    corrMakes.sort((a, b) => a?.title.localeCompare(b?.title));
    const { result: result1, status } = useExecutionDataView({
        execution: {
            ...execDefinition,
            filters: [
                ...execDefinition.filters,
                newPositiveAttributeFilter(
                    Md.CorrMake,
                    // [...corrMakes?.filter((item) => item.versionCount >= 1000).map((item) => item.title)])
                    [...corrMakes?.slice(0, 2).map((item) => item.title)],
                ),
            ],
        },
        window: {
            offset: [0],
            size: [100, 100],
        },
    });

    useEffect(() => {
        const slices = result1?.data().slices().toArray();
        const series = result1?.data().slices().toArray();
        if (series && slices) {
            const dataTmp = toTransform({ slices, series, baskets: basketList, selectedAdj: selectedAdjDeposit });
            if (dataTmp?.data) {
                setData(
                    dataTmp.data.map((item) => ({
                        ...item,
                        Adj_Rental_Includes: servicemessage,
                    })),
                );
            } else dataTmp?.length > 0 && setData(dataTmp.map((item) => ({ ...item, Adj_Rental_Includes: servicemessage })));
        }
    }, [result1, selectedAdjDeposit]);

    useEffect(() => {
        data?.length > 0 && onComplete(data);
    }, [data]);

    return status === "error" ? <ErrorComponent message={"No data provided for this scope"} description="Please change your filters" /> : <div></div>;
};

const IncrementalExportFullCatalog = ({ execDefinition, corrMakes, toTransform, onComplete, basketList, servicemessage }) => {
    const [data, setData] = useState([]);
    const { selectedAdjDeposit } = useContext(MeasuresContext);
    const { kpiSettings } = useUserContext();

    const { result: result1, status: status1 } = useExecutionDataView({
        execution: {
            ...execDefinition,
            filters: [...execDefinition.filters, newPositiveAttributeFilter(Md.CorrMake, [...corrMakes?.filter((item) => item.versionCount >= 1000).map((item) => item.title)])],
        },
    });
    const { result: result2, status: status2 } = useExecutionDataView({
        execution: {
            ...execDefinition,
            filters: [...execDefinition.filters, newPositiveAttributeFilter(Md.CorrMake, [...corrMakes?.filter((item) => item.versionCount < 1000).map((item) => item.title)])],
        },
    });

    useEffect(() => {
        const slices1 = result1?.data().slices().toArray();
        const slices2 = result2?.data().slices().toArray();
        const series1 = result1?.data().slices().toArray();
        const series2 = result2?.data().slices().toArray();
        if (series1 && slices1 && series2 && slices2) {
            const dataTmp = toTransform({ slices: [...slices1, ...slices2], series: [...series1, ...series2], baskets: basketList, selectedAdj: selectedAdjDeposit, kpiSettings: kpiSettings });
            if (dataTmp?.data) {
                setData(dataTmp.data.map((item) => ({ ...item, Adj_Rental_Includes: servicemessage })));
            } else dataTmp?.length > 0 && setData(dataTmp.map((item) => ({ ...item, Adj_Rental_Includes: servicemessage })));
        }
    }, [result1, result2]);

    useEffect(() => {
        data?.length > 0 && onComplete(data);
    }, [data]);

    return status1 === "error" || status2 === "error" ? (
        <span>There are no data provided for your filters selection</span>
    ) : (
        <Modal open={status1 === "loading" || status2 === "loading"} footer={null} width={800}>
            <div className="flex flex-col items-center justify-center gap-2 h-64">
                <div className="text-lg font-semibold text-gray-500">Generating your file via the incremental load method</div>
                <span className="font-medium text-gray-500">This operation may take several minutes, please wait</span>
                <div className="my-4">
                    <LoadingComponent />
                </div>
            </div>
        </Modal>
    );
};

export { IncrementalExport, IncrementalExportFullCatalog };

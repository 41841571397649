import React, { useContext, useState, useEffect, useRef } from "react";
import { ErrorComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import * as Md from "../../md/full";
import { newJsonPivot } from "../../utils/jsonPivot";

import FiltersContext from "../../contexts/Filters";
import MeasuresContext from "../../contexts/Measures";

import TableIndices from "../Indices/TableIndices";
import LineIndices from "../Indices/LineIndices";

import LoadingSpinner from "../LoadingSpinner";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";
import { useAppContext } from "../../contexts/AppContext";
import TrendEvolutionView from "../Indices/TrendEvolutionView";
import { TrendIndicesView } from "../Indices/TrendIndicesView";

export default function Indices() {
    const { currentFilterHistory, getFiltersForFootnote, filterCountryId, filterChannel1, filterDuration } = useContext(FiltersContext);
    const { seriesBy, getScopeForTitleHistory, selectedKpi } = useContext(MeasuresContext);
    const { getCountryByCode, getColorByCustId, getParamsByCustID, startingMonth, monthList, setMonthList, trendsMode } = useAppContext();
    const displayModes = [
        {
            key: "Player",
            title: "Player",
            label: "Player",
            formatTitle: (value, ctryId) => getParamsByCustID(value, ctryId)?.custLabel,
            attribute: Md.CustId,
        },
        {
            key: "Fuel",
            label: "Energy",
            title: "Energy",
            formatTitle: (value, ctryid) => value,
            attribute: Md.Fuel,
        },
    ];
    const [displayMode] = useState(displayModes[0]);

    const [showTrendIndices, setShowTrendIndices] = useState(true);

    const actmonthlist = monthList.slice(monthList.indexOf(startingMonth));
    const filters = [...currentFilterHistory, newPositiveAttributeFilter(Md.FlagPrevMth, ["1"]), newPositiveAttributeFilter(Md.MthCode, actmonthlist)];

    const {
        result: firstresult,
        status,
        error,
    } = useExecutionDataView({
        execution: {
            seriesBy,
            slicesBy: [Md.MthCode, displayMode.attribute],
            filters: [...currentFilterHistory, newPositiveAttributeFilter(Md.FlagPrevMth, ["1"])],
            // sortBy: [newAttributeSort(Md.MthCode, 'desc')]
        },
    });

    useEffect(() => {
        const months = [];
        const slices = firstresult?.data().slices().toArray();
        slices?.forEach((slice) => {
            if (!months.includes(slice.sliceTitles()[0])) {
                months.push(slice.sliceTitles()[0]);
            }
        });
        setMonthList(months);
    }, [firstresult, selectedKpi]);

    const ref = useRef(null);

    return (
        <div ref={ref}>
            {status === "loading" ? (
                <LoadingSpinner />
            ) : trendsMode !== "rental-average" ? (
                <TrendIndicesView seriesBy={seriesBy} filters={filters} selectedKpi={selectedKpi} displayMode={displayMode} ref={ref} />
            ) : (
                <TrendEvolutionView seriesBy={seriesBy} filters={currentFilterHistory} slicesBy={[Md.MthCode, Md.CustId]} selectedKpi={selectedKpi} displayMode={displayMode} startMonth={startingMonth} handleWidgetVisibility={true} ref={ref} />
            )}
            {error && <ErrorComponent message={error?.getMessage()} />}
        </div>
    );
}

import React from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const defaultStyling = "p-2 rounded-full hover:bg-backgroundColor";

const UpDownButton = ({ visibilityState = true, onClickHandler = () => {}, className = "" }) => (
    <Tooltip title={visibilityState ? "Hide report" : "Show report"} color="#506e96">
        {visibilityState ? (
            <UpOutlined
                className={`${defaultStyling} ${className}`}
                style={{
                    color: "#506e96",
                }}
                onClick={onClickHandler}
            />
        ) : (
            <DownOutlined
                className={`${defaultStyling} ${className}`}
                style={{
                    color: "#506e96",
                }}
                onClick={onClickHandler}
            />
        )}
    </Tooltip>
);

export default UpDownButton;

import React, { useState, useEffect, useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";

import BasketsTable from "../components/Baskets/BasketsTable";
import { BasketVizWrapper } from "../components/Baskets/BasketViz/BasketVizWrapper";

import { ComboBoxInput } from "../components/ComboBoxInput";
import { useBasketContext } from "../contexts/BasketProvider";
import LoadingSpinner from "../components/LoadingSpinner";
import FiltersContext from "../contexts/Filters";
import * as Md from "../md/full";
import { ActionButton } from "../components/Buttons/PrimaryButton";
import ComboBoxScopeCountryFilter from "../components/ComboBoxScopeCountryFilter";

const GET_BASKETS_GROUPS = gql`
    query GetSystemBasket($country: String) {
        re_rentaleye_system_baskets(where: { ctryid: { _eq: $country } }, distinct_on: [basketgroup]) {
            basketgroup
        }
    }
`;
const GET_USER_BASKETS = gql`
    query GetUserBaskets($country: String) {
        re_rentaleye_baskets(distinct_on: basketgroup, where: { country: { _eq: $country } }) {
            basketgroup
        }
    }
`;

function Baskets() {
    const { userBaskets, systemBaskets, basketViz, loadingBaskets, selectBasketGroup, selectedBasketGroup, setSelectedBaskets } = useBasketContext();
    const [searchText, setSearchText] = useState("");
    const [isVisualize, setVizStatus] = useState(false);
    const { loading, getFilterValue, getFirstFilterValue } = useContext(FiltersContext);
    const countryValue = getFilterValue("filterCountryId")[0];
    const [setVersionStdFilter] = useState(newPositiveAttributeFilter(Md.VersionStd, []));
    const { data: systemBasketGroups, loading: loadingSystemBasket } = useQuery(GET_BASKETS_GROUPS, {
        variables: {
            country: countryValue,
        },
    });
    const { data: userBasketGroups, loading: loadingUserBasket } = useQuery(GET_USER_BASKETS, {
        variables: {
            country: countryValue,
        },
    });

    let [baskets, setBaskets] = useState([]);

    const basketGroupNames = userBasketGroups?.re_rentaleye_baskets.map((elem) => elem.basketgroup).concat(systemBasketGroups?.re_rentaleye_system_baskets.map((elem) => elem.basketgroup));

    useEffect(() => {
        if (basketViz !== undefined) {
            let versionStdFilters = basketViz?.basketFilters.filter((elem) => elem.type === "version_std").map((elem) => elem.value);
            let vehicleStdAttributeFilter = newPositiveAttributeFilter(Md.VersionStd, versionStdFilters);
            setVersionStdFilter(vehicleStdAttributeFilter);
        }
    }, [basketViz]);

    useEffect(() => {
        if (!loadingBaskets) setBaskets([...userBaskets, ...systemBaskets]);
    }, [userBaskets, systemBaskets, loadingBaskets]);

    function onSelectBasketGroup(basketGroup) {
        if (basketGroup === "") {
            selectBasketGroup(undefined);
        } else {
            selectBasketGroup(basketGroup);
        }
    }

    function onSelectBaskets(baskets) {
        setVizStatus(false);
        setSelectedBaskets(baskets);
    }

    return (
        <div>
            <div className="flex flex-col gap-4">
                {!loading && (
                    <div className="flex gap-8 items-center">
                        <div className="flex gap-4 items-center">
                            <label className="font-bold text-base mb-0 mr-4 text-indigo-700">Country</label>
                            <ComboBoxScopeCountryFilter title="Country" value={getFirstFilterValue("filterCountryId")} mdAttribute={Md.Ctryid} filterID="filterCountryId" />
                        </div>
                        <div className="flex gap-4 items-center">
                            <label htmlFor="basket-group" className="font-medium text-gray-500 text-sm">
                                Search basket
                            </label>
                            <div>
                                <div className="relative">
                                    <input
                                        id="search"
                                        name="search"
                                        className="block rounded-md border placeholder:text-indigo-700 placeholder:text-sm placeholder:font-semibold border-indigo-700 bg-white py-1 pl-3 w-[200px] leading-5 placeholder-gray-500 focus:border-indigo-700 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-700 sm:text-sm"
                                        placeholder="Enter Basket Name"
                                        type="search"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-4 items-center">
                            <label htmlFor="basket-group" className="font-medium text-gray-500 text-sm">
                                Select group
                            </label>
                            <ComboBoxInput title={""} dataElements={basketGroupNames} onSelect={onSelectBasketGroup} defaultElement={selectedBasketGroup} />
                        </div>
                    </div>
                )}
                {loadingSystemBasket && loadingUserBasket && loadingBaskets ? (
                    <LoadingSpinner />
                ) : (
                    baskets.length > 0 && (
                        <>
                            <BasketsTable baskets={[...baskets.filter((f) => f?.name.toLowerCase().includes(searchText.toLowerCase()))]} actionable={true} selectable={true} onSelectBaskets={onSelectBaskets} />
                            <div className="self-start">
                                <ActionButton
                                    title={"View basket(s)"}
                                    className="bg-indigo-700 border border-indigo-900 shadow-button hover:bg-indigo-500 text-white font-semibold py-1 px-8 rounded-md"
                                    // icon={<LineChartOutlined width={25} height={25} />}
                                    onClick={() => {
                                        setVizStatus(true);
                                    }}
                                />
                            </div>
                            {/* <BasketReference custIds={custIds} vehiclesStd={versionStdFilter.positiveAttributeFilter.in?.values} showVehiclesStd={versionStdFilter.positiveAttributeFilter.in?.values.length > 0} /> */}
                        </>
                    )
                )}
                {
                    isVisualize && <BasketVizWrapper />
                    // selectedBaskets.map((basket) => <BasketViz basket={basket} />)
                }
            </div>
        </div>
    );
}

export { Baskets };

import React, { useState, useEffect, useContext, useRef } from "react";
import { newAttributeSort, newMeasureSort, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import { useExecutionDataView } from "@gooddata/sdk-ui";
import { useReactToPrint } from "react-to-print";
import { utils, writeFileXLSX } from "xlsx";
import { useAppContext } from "../../../contexts/AppContext";
import { useBasketContext } from "../../../contexts/BasketProvider";
import FiltersContext from "../../../contexts/Filters";
import MeasuresContext from "../../../contexts/Measures";
import UserContext from "../../../contexts/User";
import jsonPivot from "../../../utils/jsonPivot";
import * as Md from "../../../md/full";
import { message } from "antd";
import TrendHistory from "./TrendHistory";
import MenuButton from "../../Buttons/MenuButton";
import ComboBoxFilterField from "../../ComboBoxScopeFilter";
import SelectKpi from "../../Layout/SelectKpi";
import DetailedView from "./DetailedView";
import Summary from "./Summary";
import PDFPrintButton from "../../Buttons/PDFPrintButton";
import LFGraph from "./LFGraph";
import LoadingSpinner from "../../LoadingSpinner";
import SelectAdjDeposit from "../../Layout/SelectAdjDeposit";
import Parameters from "../../Layout/Parameters";

const BasketVizWrapper = React.forwardRef((props, ref) => {
    const { selectedBaskets, selectBasketGroup, clearSelectedBaskets } = useBasketContext();
    const { getCustlabelByCustId, getCountryByCode } = useAppContext();
    const { selectedKpi, seriesBy, updateMeasures, selectedAdjDeposit } = useContext(MeasuresContext);
    const { filterChannel1, filterCountryId, filterDuration, filterMthCode, updateFilter, displayMode, changeMode, getFirstFilterValue, showOption, changeShowOption, showAsOptions } = useContext(FiltersContext);
    const { defaultUserPref, kpiSettings } = useContext(UserContext);
    // const [customFilterDuration, setCustomFilterDuration] = useState(filterDuration);
    const [isExported, setisExported] = useState(false);
    const [currentCountryFilter] = useState(filterCountryId);
    const [printMode, setPrintMode] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const adjdeposititle = getFirstFilterValue("filterCountryId") === "GB" ? "Target deposit : " + (selectedAdjDeposit === "Total Payable by month" ? "Total Payable " : selectedAdjDeposit + " ") : null;

    /**
     * Get data for each baskets selected, group them by players, filter by the version Std that are part of the basket
     */
    const custSlots = Object.entries(defaultUserPref)
        .filter(([key, val]) => key.includes("customer_slot") && val !== null)
        .map(([key, val]) => val);
    const custIdFilters = newPositiveAttributeFilter(Md.CustId, custSlots);
    const [versionStdFilter, setVersionStdFilter] = useState(newPositiveAttributeFilter(Md.VersionStd, []));

    const [columns, setColumns] = useState([
        {
            dataIndex: "versionStd",
        },
        {
            dataIndex: "basket",
        },
    ]);
    const [dataTable, setDataTable] = useState([]);
    const [exportDetails, setExportDetails] = useState(false);
    // Data used for xls export
    const [exportedData, setExportedData] = useState([]);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const [isPrinted, setPrintStatus] = useState(false);
    // const [isLoading, setLoading] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState("disabled");
    /* 
        const [componentItems, setComponentItems] = useState([
            {
                title: "Summary table",
                renderChild: (props) => <Table {...props} />,
                isShow: false,
            },
            {
                title: "Trends History Table",
                renderChild: (props) => <ExportData setisExported={setisExported} />,
                isShow: false,
            },
        ]);
    */
    const exportMenuItems = [
        {
            key: "1",
            label: (
                <span
                    onClick={() => {
                        message.loading("Exporting data...", 1);
                        onXlsDownload(exportedData);
                    }}
                >
                    Summary table
                </span>
            ),
        },
        {
            key: "2",
            label: (
                <span
                    onClick={() => {
                        setisExported(true);
                        message.loading("Exporting data...");
                    }}
                >
                    Trend history
                </span>
            ),
        },
        {
            key: "3",
            label: (
                <span
                    onClick={() => {
                        setExportDetails(true);
                        message.loading("Exporting data...");
                    }}
                >
                    Detailed view
                </span>
            ),
        },
    ];
    const PDFMenuItems = [
        {
            key: "1",
            label: (
                <span
                    className="cursor-pointer w-full"
                    onClick={() => {
                        message.loading("Printing...", 1);
                        setPrintMode("all");
                        OnGeneratePDF();
                    }}
                >
                    Print All
                </span>
            ),
        },
        {
            key: "2",
            label: (
                <span
                    className="cursor-pointer w-full"
                    onClick={() => {
                        message.loading("Printing...", 1);
                        setPrintMode("bars");
                        OnGeneratePDF();
                    }}
                >
                    Bars Only
                </span>
            ),
        },
        {
            key: "3",
            label: (
                <span
                    className="cursor-pointer w-full"
                    onClick={() => {
                        message.loading("Printing...", 1);
                        setPrintMode("trends");
                        OnGeneratePDF();
                    }}
                >
                    Trends Only
                </span>
            ),
        },
        {
            key: "4",
            label: (
                <span
                    className="cursor-pointer w-full"
                    onClick={() => {
                        message.loading("Printing...", 1);
                        setPrintMode("Overview");
                        OnGeneratePDF();
                    }}
                >
                    Overview
                </span>
            ),
        },
    ];

    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.CustId, Md.VersionStd],
            filters: [versionStdFilter, filterChannel1, filterCountryId, filterDuration, custIdFilters, filterMthCode],
            sortBy: [newMeasureSort(seriesBy[selectedKpi.index]), newAttributeSort(Md.CustId)],
        },
    });

    useEffect(() => {
        // Display mode changed to player => reset showas option to players details
        if (displayMode.key === "player" && showOption.key !== showAsOptions.find((item) => item.isDefault).key) {
            changeShowOption(showAsOptions.find((item) => item.isDefault));
        }
    }, [displayMode]);

    useEffect(() => {
        if (selectedBaskets.length > 0) {
            let versionStds = [];
            selectedBaskets?.map((basket) =>
                basket.basketFilters
                    .filter((basketFilter) => basketFilter.type === "version_std")
                    .forEach((basketFilter) => {
                        versionStds.push(basketFilter.value);
                    }),
            );
            setVersionStdFilter(newPositiveAttributeFilter(Md.VersionStd, versionStds));
            // setLoading(true);
            const loadedBaskets = selectedBaskets.filter((elem) => elem.printStatus);
            if (loadedBaskets.length >= selectedBaskets.length) setSubmissionStatus("active");
        } else {
            setSubmissionStatus("disabled");
        }
    }, [selectedBaskets]);

    useEffect(() => {
        if (result && status === "success" && selectedBaskets.length > 0) {
            const data = result.data().series().firstForMeasure(seriesBy[selectedKpi.index]);
            const adjdata = result.data().series().firstForMeasure(seriesBy[2]);
            let dataPoints = [];
            let flatBaskets = selectedBaskets
                .map((basket) =>
                    basket.basketFilters
                        .filter((basketFilter) => basketFilter.type === "version_std")
                        .map((basketFilter) =>
                            Object.assign({
                                name: basket.name,
                                versionStd: basketFilter.value,
                            }),
                        ),
                )
                .flat();
            const predata = data?.dataPoints().map((dp) =>
                Object.freeze({
                    value: Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue)),
                    custId: dp.sliceDesc.sliceTitles()[0],
                    versionStd: dp.sliceDesc.sliceTitles()[1],
                }),
            );
            const AdjDataPoints = adjdata
                ?.dataPoints()
                .map((dp) =>
                    Object.freeze({
                        value: Number(dp.rawValue) === 0 ? null : Number(dp.rawValue),
                        custId: dp.sliceDesc.sliceTitles()[0],
                        versionStd: dp.sliceDesc.sliceTitles()[1],
                    }),
                )
                .filter((dp) => dp.value !== null);

            if (selectedAdjDeposit !== "Total Payable by month") {
                // filter out predata from records that are not in AdjDataPoints
                dataPoints = predata.filter((dp) => AdjDataPoints.some((adjdp) => adjdp.custId === dp.custId && adjdp.versionStd === dp.versionStd));
            } else {
                dataPoints = predata;
            }
            const tableData = jsonPivot(dataPoints, { row: "versionStd", column: "custId", value: "value" });
            // Calculate average for each table row
            tableData.forEach((tr) => {
                const custValues = Object.entries(tr)
                    .filter(([key, value]) => key !== "versionStd" && value !== null)
                    .flatMap(([key, value]) => value);
                tr.average =
                    Math.round(custValues.reduce((acc, nextVal) => acc + nextVal, 0)) > 0
                        ? selectedKpi.index === 24
                            ? Number((custValues.reduce((acc, nextVal) => acc + nextVal, 0) / custValues.length).toFixed(2))
                            : Math.round(custValues.reduce((acc, nextVal) => acc + nextVal, 0) / custValues.length)
                        : null;
            });
            // Assign each data item with their associate basket
            flatBaskets.forEach((basketElem) => {
                let tdElem = tableData.find((elem) => elem && elem["versionStd"] === basketElem["versionStd"]);
                if (tdElem?.basket) {
                    let newTd = {
                        ...tdElem,
                        basket: basketElem.name,
                    };
                    tableData.push(newTd);
                } else if (tdElem) {
                    tdElem.basket = basketElem.name;
                }
            });

            tableData.sort((a, b) => {
                if (a.basket < b.basket) {
                    return -1;
                } else if (a.basket > b.basket) {
                    return 1;
                }
                return 0;
            });
            // Re-position columns
            let tableKeys = Object.keys(tableData[0]);
            let basketColumnIndex = tableKeys.findIndex((elem) => elem === "basket");
            if (basketColumnIndex !== -1) {
                let basketColumns = tableKeys.splice(basketColumnIndex, 1);
                tableKeys.splice(0, 0, ...basketColumns);
            }
            let versionStdColumnIndex = tableKeys.findIndex((elem) => elem === "versionStd");
            if (versionStdColumnIndex !== -1) {
                let versionStdColumns = tableKeys.splice(versionStdColumnIndex, 1);
                tableKeys.splice(1, 0, ...versionStdColumns);
            }
            let averageColumnIndex = tableKeys.findIndex((elem) => elem === "average");
            if (averageColumnIndex !== -1) {
                let averageColumns = tableKeys.splice(averageColumnIndex, 1);
                tableKeys.splice(2, 0, ...averageColumns);
            }
            // @ts-ignore
            let columnsTable = tableKeys.map((elem) =>
                Object.freeze({
                    dataIndex: elem,
                    title: () => <span>{elem === "basket" ? "Basket Name" : elem === "versionStd" ? "Version Target" : elem === "average" ? "Average" : <img alt="logo" src={`/images/logos/Logo_${elem}.png`} width={80} height={80} />}</span>,
                    align: ["basket", "versionStd"].includes(elem) ? "left" : "center",
                    width: elem === "basket" ? 140 : !["versionStd"].includes(elem) && 100,
                    onCell: (row, index) => {
                        if (elem === "basket") {
                            let basket = selectedBaskets.find((basket) => basket.name === row.basket);
                            let startIndex = tableData.findIndex((elem) => elem.basket === basket?.name);
                            if (index === startIndex) {
                                let offset = tableData.filter((elem) => elem.basket === basket.name)?.length;
                                return {
                                    rowSpan: offset,
                                };
                            } else {
                                return {
                                    rowSpan: 0,
                                };
                            }
                        }
                    },
                    render: (text) => {
                        if (elem === "basket") {
                            return <span className="absolute top-2">{text}</span>;
                        } else {
                            return text;
                        }
                    },
                }),
            );
            setColumns(columnsTable);
            setDataTable(tableData);
        }
    }, [result, status]);

    useEffect(() => {
        setSubmissionStatus("active");
        // Convert custId to cust labels for data export
        let preData = [...dataTable];
        preData = preData?.map((dataElem) => {
            Object.entries(dataElem).map(([key, value]) => {
                if (!["basket", "versionStd", "average"].includes(key)) {
                    let custLabel = getCustlabelByCustId(key);
                    if (key !== custLabel) {
                        dataElem = {
                            ...dataElem,
                            [custLabel]: dataElem[key],
                        };
                        delete dataElem[key];
                    }
                }
            });
            let orderedElem = Object.keys(dataElem)
                .sort((a, b) => {
                    const firstColumns = ["average", "versionStd", "basket"];
                    let aIndex = firstColumns.findIndex((elem) => elem === a);
                    let bIndex = firstColumns.findIndex((elem) => elem === b);
                    return aIndex === -1 && bIndex === -1 ? 0 : aIndex > bIndex ? -1 : 1;
                })
                .reduce((acc, nextKey) => {
                    acc[nextKey] = dataElem[nextKey];
                    return acc;
                }, {});
            return orderedElem;
        });

        setExportedData(preData);
    }, [dataTable]);

    /*
    useEffect(() => {
        // Basket scope updated
        const fDuration = scope === "Selected duration" ? filterDuration : newPositiveAttributeFilter(Md.Duration, [defaultUserPref.duration]);
        setCustomFilterDuration(fDuration);
    }, [scope, filterDuration]);
     */

    useEffect(() => {
        if (currentCountryFilter?.positiveAttributeFilter?.in.values[0] !== filterCountryId?.positiveAttributeFilter?.in.values[0]) {
            selectBasketGroup(undefined);
            clearSelectedBaskets();
        }
    }, [filterCountryId]);

    function OnGeneratePDF() {
        setSubmissionStatus("submitted");
        setTimeout(() => {
            setPrintStatus(true);
            setTimeout(
                () => {
                    handlePrint();
                    setSubmissionStatus("active");
                    setPrintStatus(false);
                },
                selectedBaskets.length < 2 ? 3000 : selectedBaskets.length * 1000,
            );
            setTimeout(
                () => {
                    setPrintMode("");
                },
                selectedBaskets.length < 2 ? 3000 : selectedBaskets.length * 1000,
            );
        }, 1000);
    }

    const onXlsDownload = (data) => {
        const ws = utils.json_to_sheet(data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");
        writeFileXLSX(wb, `Summary Table.xlsx`);
    };

    /*
    function setVisibility(itemIndex, visiblityValue) {
        // Set the visibility of nested components
        componentItems[itemIndex].isShow = visiblityValue;
        setComponentItems([...componentItems]);
    }
     */

    return selectedBaskets.length > 0 ? (
        <div className="relative -mt-1 flex flex-col gap-4">
            {/* <div className="sticky top-20 bg-white z-40"> */}
            <div className="absolute -top-12 left-48 flex gap-4 items-center">
                <label className="font-bold text-base mb-0 mr-10 text-indigo-700">Scope</label>
                <ComboBoxFilterField
                    mdAttribute={Md.Duration}
                    filterAttribute={filterDuration}
                    title={"Duration"}
                    filterID={"filterDuration"}
                    currentFilter={[filterChannel1, filterMthCode, filterCountryId]}
                    value={filterDuration?.positiveAttributeFilter?.in?.values[0]}
                    onUpdateFilter={updateFilter}
                />
                <ComboBoxFilterField
                    mdAttribute={Md.Channel1}
                    filterAttribute={filterChannel1}
                    title={"Channel 1"}
                    filterID={"filterChannel1"}
                    currentFilter={[filterDuration, filterMthCode, filterCountryId]}
                    value={filterChannel1?.positiveAttributeFilter?.in?.values[0]}
                    onUpdateFilter={updateFilter}
                />
                <ComboBoxFilterField mdAttribute={Md.MthCode} filterAttribute={filterMthCode} title={"Month"} filterID={"filterMthCode"} currentFilter={[filterChannel1, filterDuration, filterCountryId]} value={filterMthCode?.positiveAttributeFilter?.in?.values[0]} onUpdateFilter={updateFilter} />
                <SelectKpi
                    title={"Select KPI"}
                    selectedKpi={selectedKpi}
                    onSelect={(obj) => {
                        updateMeasures("selectedKpi", obj);
                    }}
                />
                {getFirstFilterValue("filterCountryId") === "GB" && <SelectAdjDeposit title="Target deposit" />}
                {/* <div className="flex gap-4 items-center">
                <label htmlFor="basket-group" className="font-medium text-gray-500 text-sm">
                    Duration config
                </label>
                <ComboBoxInput title={""} dataElements={scopeOptions} defaultElement={scope} onSelect={setScope} />
            </div> */}
            </div>
            <div className="flex gap-2 items-center justify-center absolute right-0 2xl:-top-11 sm:top-36">
                <PDFPrintButton items={PDFMenuItems} submissionStatus={submissionStatus} selectedBaskets={selectedBaskets} isLoading={isLoading} />
                <MenuButton items={exportMenuItems} />
            </div>
            <div className="flex gap-2 xl:ml-48">
                <Parameters />
            </div>
            {/* </div> */}
            <div className={isLoading ? "hidden" : "flex flex-col items-stretch gap-4"}>
                <div
                    style={
                        isPrinted
                            ? {
                                  //   display: "none",
                                  opacity: 0,
                              }
                            : {}
                    }
                >
                    <div ref={componentRef} className={`${!isPrinted && "space-y-3 divide-y-2 divide-gray-200"}`}>
                        {selectedBaskets.map((basket) => {
                            let calculatedData = dataTable.filter((tableElem) => tableElem.basket === basket.name);
                            let basketValues = calculatedData
                                .map((elem) =>
                                    Object.entries(elem)
                                        .filter(([key, val]) => custSlots.includes(key) && val !== null)
                                        .map(([key, val]) => val),
                                )
                                .reduce((acc, nextVal) => [...acc, ...nextVal], []);
                            basketValues.sort((a, b) => a - b);
                            // Retrieve cust ids that hold values;
                            const custIdValues = calculatedData
                                .map((dataItem) =>
                                    Object.entries(dataItem)
                                        .filter(([key, value]) => value != null && custSlots.includes(key))
                                        .map(([key, value]) => key),
                                )
                                .reduce((acc, custIdArr) => {
                                    custIdArr.forEach((elem) => {
                                        !acc.includes(elem) && acc.push(elem);
                                    });
                                    return acc;
                                }, []);
                            // Sort cust id based on the custIds of the user pref
                            custIdValues.sort((a, b) => custSlots.indexOf(a) - custSlots.indexOf(b));

                            return (
                                <div>
                                    {React.createElement(showOption.component, {
                                        basket: basket,
                                        custIds: custIdValues.length > 0 ? custIdValues : custSlots,
                                        selectedKpi: selectedKpi,
                                        filterCountryId: filterCountryId,
                                        filterChannel1: filterChannel1,
                                        filterMthCode: filterMthCode,
                                        filterDuration: filterDuration,
                                        printStatus: isPrinted,
                                        printMode: printMode,
                                        setIsLoading: setIsLoading,
                                        adjdeposititle: adjdeposititle,
                                        ...props,
                                    })}
                                    {/* <BasketViz {...props} basket={basket} custIds={custIdValues} selectedKpi={selectedKpi} filterCountryId={filterCountryId} filterChannel1={filterChannel1} filterMthCode={filterMthCode} filterDuration={filterDuration} printStatus={isPrinted} /> */}
                                    {!isPrinted && showOption.key === "players" && <Summary columns={columns} dataTable={dataTable} filters={[versionStdFilter, filterChannel1, filterCountryId, filterDuration, custIdFilters, filterMthCode]} selectedBaskets={selectedBaskets} />}
                                    {!isPrinted && showOption.key === "players" && <DetailedView Basket={basket} filters={[filterChannel1, filterCountryId, filterMthCode, filterDuration]} exportDetails={exportDetails} setExportDetails={setExportDetails} setIsLoading={setIsLoading} />}
                                    {!isPrinted && showOption.key === "players" && (
                                        <LFGraph
                                            Basket={basket}
                                            filters={[filterChannel1, filterCountryId, filterMthCode, filterDuration]}
                                            country={filterCountryId.positiveAttributeFilter.in["values"][0]}
                                            kpiSettings={kpiSettings}
                                            title={`${basket.name} Leasing factor matrix | ${getCountryByCode(filterCountryId?.positiveAttributeFilter?.in?.values[0])} | ${filterChannel1.positiveAttributeFilter.in?.values[0]} | ${filterDuration.positiveAttributeFilter.in?.values[0]} ${
                                                adjdeposititle ? " | " + adjdeposititle : ""
                                            }`}
                                            menuButtonPosition="inside"
                                            setIsLoading={setIsLoading}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            {isLoading && (
                <div className="h-60">
                    <LoadingSpinner />
                </div>
            )}
            {isExported && <TrendHistory setisExported={setisExported} selectedKpi={selectedKpi} filterCountryId={filterCountryId} filterChannel1={filterChannel1} filterDuration={filterDuration} />}
        </div>
    ) : (
        <div></div>
    );
});

export { BasketVizWrapper };

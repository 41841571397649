/* eslint-disable */
/* THIS FILE WAS AUTO-GENERATED USING CATALOG EXPORTER; YOU SHOULD NOT EDIT THIS FILE; GENERATE TIME: 2024-10-22T17:14:36.742Z; */
// @ts-ignore ignore unused imports here if they happen (e.g. when there is no measure in the workspace)
import { newAttribute, newMeasure, idRef } from "@gooddata/sdk-model";

/**
 * Attribute Title: Body Style
 * Attribute ID: attr.re_all_secure.body_style
 */
export const BodyStyle = newAttribute("label.re_all_secure.body_style");
/**
 * Attribute Title: Channel1
 * Attribute ID: attr.re_all_secure.channel1
 */
export const Channel1 = newAttribute("label.re_all_secure.channel1");
/**
 * Attribute Title: Channel2
 * Attribute ID: attr.re_all_secure.channel2
 */
export const Channel2 = newAttribute("label.re_all_secure.channel2");
/**
 * Attribute Title: Channel3
 * Attribute ID: attr.re_all_secure.channel3
 */
export const Channel3 = newAttribute("label.re_all_secure.channel3");
/**
 * Attribute Title: Corr Make
 * Attribute ID: attr.re_all_secure.corr_make
 */
export const CorrMake = newAttribute("label.re_all_secure.corr_make");
/**
 * Attribute Title: Corr Model
 * Attribute ID: attr.re_all_secure.corr_model
 */
export const CorrModel = newAttribute("label.re_all_secure.corr_model");
/**
 * Attribute Title: Ctryid
 * Attribute ID: attr.re_all_secure.ctryid
 */
export const Ctryid = newAttribute("label.re_all_secure.ctryid");
/**
 * Attribute Title: Cust Code
 * Attribute ID: attr.re_all_secure.cust_code
 */
export const CustCode = newAttribute("label.re_all_secure.cust_code");
/**
 * Attribute Title: Cust Id
 * Attribute ID: attr.re_all_secure.cust_id
 */
export const CustId = newAttribute("label.re_all_secure.cust_id");
/**
 * Attribute Title: cust_label
 * Attribute ID: attr.re_all_secure.cust_label
 */
export const CustLabel = newAttribute("label.re_all_secure.cust_label");
/**
 * Attribute Title: Custmixindex
 * Attribute ID: attr.re_all_secure.custmixindex
 */
export const Custmixindex = newAttribute("label.re_all_secure.custmixindex");
/**
 * Attribute Title: Drive
 * Attribute ID: attr.re_all_secure.drive
 */
export const Drive = newAttribute("label.re_all_secure.drive");
/**
 * Attribute Title: Duration
 * Attribute ID: attr.re_all_secure.duration
 */
export const Duration = newAttribute("label.re_all_secure.duration");
/**
 * Attribute Title: Ee Segment
 * Attribute ID: attr.re_all_secure.ee_segment
 */
export const EeSegment = newAttribute("label.re_all_secure.ee_segment");
/**
 * Attribute Title: Extraptype
 * Attribute ID: attr.re_all_secure.extraptype
 */
export const Extraptype = newAttribute("label.re_all_secure.extraptype");
/**
 * Attribute Title: Filter
 * Attribute ID: attr.re_all_secure.filter
 */
export const Filter = newAttribute("label.re_all_secure.filter");
/**
 * Attribute Title: Flag Prev Mth
 * Attribute ID: attr.re_all_secure.flagprevmth
 */
export const FlagPrevMth = newAttribute("label.re_all_secure.flagprevmth");
/**
 * Attribute Title: Font Back Color
 * Attribute ID: attr.re_all_secure.font_back_color
 */
export const FontBackColor = newAttribute("label.re_all_secure.font_back_color");
/**
 * Attribute Title: Fuel
 * Attribute ID: attr.re_all_secure.fuel
 */
export const Fuel = newAttribute("label.re_all_secure.fuel");
/**
 * Attribute Title: Gears Plus
 * Attribute ID: attr.re_all_secure.gears_plus
 */
export const GearsPlus = newAttribute("label.re_all_secure.gears_plus");
/**
 * Attribute Title: Id Record
 * Attribute ID: attr.re_all_secure.idrecord
 */
export const IdRecord = newAttribute("label.re_all_secure.idrecord");
/**
 * Attribute Title: Initial Trim
 * Attribute ID: attr.re_all_secure.initial_trim
 */
export const InitialTrim = newAttribute("label.re_all_secure.initial_trim");
/**
 * Attribute Title: latestmchar
 * Attribute ID: attr.re_all_secure.latestmchar
 */
export const Latestmchar = newAttribute("label.re_all_secure.latestmchar");
/**
 * Attribute Title: latestwchar
 * Attribute ID: attr.re_all_secure.latestwchar
 */
export const Latestwchar = newAttribute("label.re_all_secure.latestwchar");
/**
 * Attribute Title: Mth Code
 * Attribute ID: attr.re_all_secure.mth_code
 */
export const MthCode = newAttribute("label.re_all_secure.mth_code");
/**
 * Attribute Title: Pack
 * Attribute ID: attr.re_all_secure.pack
 */
export const Pack = newAttribute("label.re_all_secure.pack");
/**
 * Attribute Title: PeriodCat
 * Attribute ID: attr.re_all_secure.periodcat
 */
export const PeriodCat = newAttribute("label.re_all_secure.periodcat");
/**
 * Attribute Title: Rental Offer
 * Attribute ID: attr.re_all_secure.rental_offer
 */
export const RentalOffer = newAttribute("label.re_all_secure.rental_offer");
/**
 * Attribute Title: Rolledforward
 * Attribute ID: attr.re_all_secure.rolledforward
 */
export const Rolledforward = newAttribute("label.re_all_secure.rolledforward");
/**
 * Attribute Title: Size
 * Attribute ID: attr.re_all_secure.size
 */
export const Size = newAttribute("label.re_all_secure.size");
/**
 * Attribute Title: Sourcing
 * Attribute ID: attr.re_all_secure.sourcing
 */
export const Sourcing = newAttribute("label.re_all_secure.sourcing");
/**
 * Attribute Title: Trim Name
 * Attribute ID: attr.re_all_secure.trim_name
 */
export const TrimName = newAttribute("label.re_all_secure.trim_name");
/**
 * Attribute Title: Url
 * Attribute ID: attr.re_all_secure.url
 */
export const Url = newAttribute("label.re_all_secure.url");
/**
 * Attribute Title: Veh Segment
 * Attribute ID: attr.re_all_secure.veh_segment
 */
export const VehSegment = newAttribute("label.re_all_secure.veh_segment");
/**
 * Attribute Title: Veh Type
 * Attribute ID: attr.re_all_secure.veh_type
 */
export const VehType = newAttribute("label.re_all_secure.veh_type");
/**
 * Attribute Title: Version
 * Attribute ID: attr.re_all_secure.version
 */
export const Version = newAttribute("label.re_all_secure.version");
/**
 * Attribute Title: Version Std
 * Attribute ID: attr.re_all_secure.version_std
 */
export const VersionStd = newAttribute("label.re_all_secure.version_std");
/**
 * Attribute Title: Warning
 * Attribute ID: attr.re_all_secure.warning
 */
export const Warning = newAttribute("label.re_all_secure.warning");
/**
 * Attribute Title: Wheelbase
 * Attribute ID: attr.re_all_secure.wheelbase
 */
export const Wheelbase = newAttribute("label.re_all_secure.wheelbase");
/**
 * Metric Title: Player Count
 * Metric ID: aaSfY745IAfW
 * Metric Type: MAQL Metric
 */
export const PlayerCount = newMeasure(idRef("aaSfY745IAfW", "measure"));
/**
 * Metric Title: Version count
 * Metric ID: aaWnFC9AHIEP
 * Metric Type: MAQL Metric
 */
export const VersionCount = newMeasure(idRef("aaWnFC9AHIEP", "measure"));
/**
 * Metric Title: VersionStd Count
 * Metric ID: aahDIFXOSBEX
 * Metric Type: MAQL Metric
 */
export const VersionStdCount = newMeasure(idRef("aahDIFXOSBEX", "measure"));
/**
 * Fact Title: Actual Flag
 * Fact ID: fact.re_all_secure.actual_flag
 */
export const ActualFlag = {
    /**
     * Fact Title: Actual Flag
     * Fact ID: fact.re_all_secure.actual_flag
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.actual_flag", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Actual Flag
     * Fact ID: fact.re_all_secure.actual_flag
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.actual_flag", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Actual Flag
     * Fact ID: fact.re_all_secure.actual_flag
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.actual_flag", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Actual Flag
     * Fact ID: fact.re_all_secure.actual_flag
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.actual_flag", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Actual Flag
     * Fact ID: fact.re_all_secure.actual_flag
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.actual_flag", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Actual Flag
     * Fact ID: fact.re_all_secure.actual_flag
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.actual_flag", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Adj Deposit
 * Fact ID: fact.re_all_secure.adj_deposit
 */
export const AdjDeposit = {
    /**
     * Fact Title: Adj Deposit
     * Fact ID: fact.re_all_secure.adj_deposit
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.adj_deposit", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Adj Deposit
     * Fact ID: fact.re_all_secure.adj_deposit
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.adj_deposit", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Adj Deposit
     * Fact ID: fact.re_all_secure.adj_deposit
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.adj_deposit", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Adj Deposit
     * Fact ID: fact.re_all_secure.adj_deposit
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.adj_deposit", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Adj Deposit
     * Fact ID: fact.re_all_secure.adj_deposit
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.adj_deposit", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Adj Deposit
     * Fact ID: fact.re_all_secure.adj_deposit
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.adj_deposit", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Adj_deposit0
 * Fact ID: fact.re_all_secure.adj_deposit0
 */
export const AdjDeposit0 = {
    /**
     * Fact Title: Adj_deposit0
     * Fact ID: fact.re_all_secure.adj_deposit0
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.adj_deposit0", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Adj_deposit0
     * Fact ID: fact.re_all_secure.adj_deposit0
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.adj_deposit0", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Adj_deposit0
     * Fact ID: fact.re_all_secure.adj_deposit0
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.adj_deposit0", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Adj_deposit0
     * Fact ID: fact.re_all_secure.adj_deposit0
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.adj_deposit0", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Adj_deposit0
     * Fact ID: fact.re_all_secure.adj_deposit0
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.adj_deposit0", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Adj_deposit0
     * Fact ID: fact.re_all_secure.adj_deposit0
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.adj_deposit0", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Adj_deposit1000
 * Fact ID: fact.re_all_secure.adj_deposit1000
 */
export const AdjDeposit1000 = {
    /**
     * Fact Title: Adj_deposit1000
     * Fact ID: fact.re_all_secure.adj_deposit1000
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.adj_deposit1000", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Adj_deposit1000
     * Fact ID: fact.re_all_secure.adj_deposit1000
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.adj_deposit1000", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Adj_deposit1000
     * Fact ID: fact.re_all_secure.adj_deposit1000
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.adj_deposit1000", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Adj_deposit1000
     * Fact ID: fact.re_all_secure.adj_deposit1000
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.adj_deposit1000", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Adj_deposit1000
     * Fact ID: fact.re_all_secure.adj_deposit1000
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.adj_deposit1000", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Adj_deposit1000
     * Fact ID: fact.re_all_secure.adj_deposit1000
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.adj_deposit1000", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Adj_deposit2000
 * Fact ID: fact.re_all_secure.adj_deposit2000
 */
export const AdjDeposit2000 = {
    /**
     * Fact Title: Adj_deposit2000
     * Fact ID: fact.re_all_secure.adj_deposit2000
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.adj_deposit2000", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Adj_deposit2000
     * Fact ID: fact.re_all_secure.adj_deposit2000
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.adj_deposit2000", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Adj_deposit2000
     * Fact ID: fact.re_all_secure.adj_deposit2000
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.adj_deposit2000", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Adj_deposit2000
     * Fact ID: fact.re_all_secure.adj_deposit2000
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.adj_deposit2000", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Adj_deposit2000
     * Fact ID: fact.re_all_secure.adj_deposit2000
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.adj_deposit2000", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Adj_deposit2000
     * Fact ID: fact.re_all_secure.adj_deposit2000
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.adj_deposit2000", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Adj_deposit2500
 * Fact ID: fact.re_all_secure.adj_deposit2500
 */
export const AdjDeposit2500 = {
    /**
     * Fact Title: Adj_deposit2500
     * Fact ID: fact.re_all_secure.adj_deposit2500
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.adj_deposit2500", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Adj_deposit2500
     * Fact ID: fact.re_all_secure.adj_deposit2500
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.adj_deposit2500", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Adj_deposit2500
     * Fact ID: fact.re_all_secure.adj_deposit2500
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.adj_deposit2500", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Adj_deposit2500
     * Fact ID: fact.re_all_secure.adj_deposit2500
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.adj_deposit2500", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Adj_deposit2500
     * Fact ID: fact.re_all_secure.adj_deposit2500
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.adj_deposit2500", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Adj_deposit2500
     * Fact ID: fact.re_all_secure.adj_deposit2500
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.adj_deposit2500", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Adj_deposit3000
 * Fact ID: fact.re_all_secure.adj_deposit3000
 */
export const AdjDeposit3000 = {
    /**
     * Fact Title: Adj_deposit3000
     * Fact ID: fact.re_all_secure.adj_deposit3000
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.adj_deposit3000", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Adj_deposit3000
     * Fact ID: fact.re_all_secure.adj_deposit3000
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.adj_deposit3000", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Adj_deposit3000
     * Fact ID: fact.re_all_secure.adj_deposit3000
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.adj_deposit3000", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Adj_deposit3000
     * Fact ID: fact.re_all_secure.adj_deposit3000
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.adj_deposit3000", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Adj_deposit3000
     * Fact ID: fact.re_all_secure.adj_deposit3000
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.adj_deposit3000", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Adj_deposit3000
     * Fact ID: fact.re_all_secure.adj_deposit3000
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.adj_deposit3000", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Adj_deposit5000
 * Fact ID: fact.re_all_secure.adj_deposit5000
 */
export const AdjDeposit5000 = {
    /**
     * Fact Title: Adj_deposit5000
     * Fact ID: fact.re_all_secure.adj_deposit5000
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.adj_deposit5000", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Adj_deposit5000
     * Fact ID: fact.re_all_secure.adj_deposit5000
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.adj_deposit5000", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Adj_deposit5000
     * Fact ID: fact.re_all_secure.adj_deposit5000
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.adj_deposit5000", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Adj_deposit5000
     * Fact ID: fact.re_all_secure.adj_deposit5000
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.adj_deposit5000", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Adj_deposit5000
     * Fact ID: fact.re_all_secure.adj_deposit5000
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.adj_deposit5000", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Adj_deposit5000
     * Fact ID: fact.re_all_secure.adj_deposit5000
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.adj_deposit5000", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Annual Contract Distance
 * Fact ID: fact.re_all_secure.annual_contract_distance
 */
export const AnnualContractDistance = {
    /**
     * Fact Title: Annual Contract Distance
     * Fact ID: fact.re_all_secure.annual_contract_distance
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.annual_contract_distance", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Annual Contract Distance
     * Fact ID: fact.re_all_secure.annual_contract_distance
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.annual_contract_distance", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Annual Contract Distance
     * Fact ID: fact.re_all_secure.annual_contract_distance
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.annual_contract_distance", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Annual Contract Distance
     * Fact ID: fact.re_all_secure.annual_contract_distance
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.annual_contract_distance", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Annual Contract Distance
     * Fact ID: fact.re_all_secure.annual_contract_distance
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.annual_contract_distance", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Annual Contract Distance
     * Fact ID: fact.re_all_secure.annual_contract_distance
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.annual_contract_distance", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Apr
 * Fact ID: fact.re_all_secure.apr
 */
export const Apr = {
    /**
     * Fact Title: Apr
     * Fact ID: fact.re_all_secure.apr
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.apr", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Apr
     * Fact ID: fact.re_all_secure.apr
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.apr", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Apr
     * Fact ID: fact.re_all_secure.apr
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.apr", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Apr
     * Fact ID: fact.re_all_secure.apr
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.apr", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Apr
     * Fact ID: fact.re_all_secure.apr
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.apr", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Apr
     * Fact ID: fact.re_all_secure.apr
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.apr", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Breakdown
 * Fact ID: fact.re_all_secure.breakdown
 */
export const Breakdown = {
    /**
     * Fact Title: Breakdown
     * Fact ID: fact.re_all_secure.breakdown
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.breakdown", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Breakdown
     * Fact ID: fact.re_all_secure.breakdown
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.breakdown", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Breakdown
     * Fact ID: fact.re_all_secure.breakdown
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.breakdown", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Breakdown
     * Fact ID: fact.re_all_secure.breakdown
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.breakdown", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Breakdown
     * Fact ID: fact.re_all_secure.breakdown
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.breakdown", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Breakdown
     * Fact ID: fact.re_all_secure.breakdown
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.breakdown", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Co2malus
 * Fact ID: fact.re_all_secure.co2malus
 */
export const Co2malus = {
    /**
     * Fact Title: Co2malus
     * Fact ID: fact.re_all_secure.co2malus
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.co2malus", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Co2malus
     * Fact ID: fact.re_all_secure.co2malus
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.co2malus", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Co2malus
     * Fact ID: fact.re_all_secure.co2malus
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.co2malus", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Co2malus
     * Fact ID: fact.re_all_secure.co2malus
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.co2malus", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Co2malus
     * Fact ID: fact.re_all_secure.co2malus
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.co2malus", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Co2malus
     * Fact ID: fact.re_all_secure.co2malus
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.co2malus", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Contract Mths
 * Fact ID: fact.re_all_secure.contract_mths
 */
export const ContractMths = {
    /**
     * Fact Title: Contract Mths
     * Fact ID: fact.re_all_secure.contract_mths
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.contract_mths", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Contract Mths
     * Fact ID: fact.re_all_secure.contract_mths
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.contract_mths", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Contract Mths
     * Fact ID: fact.re_all_secure.contract_mths
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.contract_mths", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Contract Mths
     * Fact ID: fact.re_all_secure.contract_mths
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.contract_mths", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Contract Mths
     * Fact ID: fact.re_all_secure.contract_mths
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.contract_mths", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Contract Mths
     * Fact ID: fact.re_all_secure.contract_mths
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.contract_mths", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Deposit
 * Fact ID: fact.re_all_secure.deposit
 */
export const Deposit = {
    /**
     * Fact Title: Deposit
     * Fact ID: fact.re_all_secure.deposit
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.deposit", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Deposit
     * Fact ID: fact.re_all_secure.deposit
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.deposit", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Deposit
     * Fact ID: fact.re_all_secure.deposit
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.deposit", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Deposit
     * Fact ID: fact.re_all_secure.deposit
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.deposit", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Deposit
     * Fact ID: fact.re_all_secure.deposit
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.deposit", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Deposit
     * Fact ID: fact.re_all_secure.deposit
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.deposit", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Discount
 * Fact ID: fact.re_all_secure.discount
 */
export const Discount = {
    /**
     * Fact Title: Discount
     * Fact ID: fact.re_all_secure.discount
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.discount", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Discount
     * Fact ID: fact.re_all_secure.discount
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.discount", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Discount
     * Fact ID: fact.re_all_secure.discount
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.discount", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Discount
     * Fact ID: fact.re_all_secure.discount
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.discount", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Discount
     * Fact ID: fact.re_all_secure.discount
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.discount", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Discount
     * Fact ID: fact.re_all_secure.discount
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.discount", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Doors
 * Fact ID: fact.re_all_secure.doors
 */
export const Doors = {
    /**
     * Fact Title: Doors
     * Fact ID: fact.re_all_secure.doors
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.doors", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Doors
     * Fact ID: fact.re_all_secure.doors
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.doors", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Doors
     * Fact ID: fact.re_all_secure.doors
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.doors", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Doors
     * Fact ID: fact.re_all_secure.doors
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.doors", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Doors
     * Fact ID: fact.re_all_secure.doors
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.doors", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Doors
     * Fact ID: fact.re_all_secure.doors
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.doors", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Efficiency
 * Fact ID: fact.re_all_secure.efficiency
 */
export const Efficiency = {
    /**
     * Fact Title: Efficiency
     * Fact ID: fact.re_all_secure.efficiency
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.efficiency", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Efficiency
     * Fact ID: fact.re_all_secure.efficiency
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.efficiency", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Efficiency
     * Fact ID: fact.re_all_secure.efficiency
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.efficiency", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Efficiency
     * Fact ID: fact.re_all_secure.efficiency
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.efficiency", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Efficiency
     * Fact ID: fact.re_all_secure.efficiency
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.efficiency", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Efficiency
     * Fact ID: fact.re_all_secure.efficiency
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.efficiency", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Emissions
 * Fact ID: fact.re_all_secure.emissions
 */
export const Emissions = {
    /**
     * Fact Title: Emissions
     * Fact ID: fact.re_all_secure.emissions
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.emissions", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Emissions
     * Fact ID: fact.re_all_secure.emissions
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.emissions", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Emissions
     * Fact ID: fact.re_all_secure.emissions
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.emissions", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Emissions
     * Fact ID: fact.re_all_secure.emissions
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.emissions", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Emissions
     * Fact ID: fact.re_all_secure.emissions
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.emissions", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Emissions
     * Fact ID: fact.re_all_secure.emissions
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.emissions", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Engine Cc
 * Fact ID: fact.re_all_secure.engine_cc
 */
export const EngineCc = {
    /**
     * Fact Title: Engine Cc
     * Fact ID: fact.re_all_secure.engine_cc
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.engine_cc", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Engine Cc
     * Fact ID: fact.re_all_secure.engine_cc
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.engine_cc", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Engine Cc
     * Fact ID: fact.re_all_secure.engine_cc
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.engine_cc", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Engine Cc
     * Fact ID: fact.re_all_secure.engine_cc
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.engine_cc", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Engine Cc
     * Fact ID: fact.re_all_secure.engine_cc
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.engine_cc", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Engine Cc
     * Fact ID: fact.re_all_secure.engine_cc
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.engine_cc", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Evbonus
 * Fact ID: fact.re_all_secure.evbonus
 */
export const Evbonus = {
    /**
     * Fact Title: Evbonus
     * Fact ID: fact.re_all_secure.evbonus
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.evbonus", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Evbonus
     * Fact ID: fact.re_all_secure.evbonus
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.evbonus", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Evbonus
     * Fact ID: fact.re_all_secure.evbonus
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.evbonus", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Evbonus
     * Fact ID: fact.re_all_secure.evbonus
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.evbonus", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Evbonus
     * Fact ID: fact.re_all_secure.evbonus
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.evbonus", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Evbonus
     * Fact ID: fact.re_all_secure.evbonus
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.evbonus", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Evolpct
 * Fact ID: fact.re_all_secure.evolpct
 */
export const Evolpct = {
    /**
     * Fact Title: Evolpct
     * Fact ID: fact.re_all_secure.evolpct
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.evolpct", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Evolpct
     * Fact ID: fact.re_all_secure.evolpct
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.evolpct", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Evolpct
     * Fact ID: fact.re_all_secure.evolpct
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.evolpct", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Evolpct
     * Fact ID: fact.re_all_secure.evolpct
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.evolpct", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Evolpct
     * Fact ID: fact.re_all_secure.evolpct
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.evolpct", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Evolpct
     * Fact ID: fact.re_all_secure.evolpct
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.evolpct", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Fin Rental
 * Fact ID: fact.re_all_secure.fin_rental
 */
export const FinRental = {
    /**
     * Fact Title: Fin Rental
     * Fact ID: fact.re_all_secure.fin_rental
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.fin_rental", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Fin Rental
     * Fact ID: fact.re_all_secure.fin_rental
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.fin_rental", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Fin Rental
     * Fact ID: fact.re_all_secure.fin_rental
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.fin_rental", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Fin Rental
     * Fact ID: fact.re_all_secure.fin_rental
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.fin_rental", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Fin Rental
     * Fact ID: fact.re_all_secure.fin_rental
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.fin_rental", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Fin Rental
     * Fact ID: fact.re_all_secure.fin_rental
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.fin_rental", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Finance Contrib
 * Fact ID: fact.re_all_secure.financecontrib
 */
export const FinanceContrib = {
    /**
     * Fact Title: Finance Contrib
     * Fact ID: fact.re_all_secure.financecontrib
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.financecontrib", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Finance Contrib
     * Fact ID: fact.re_all_secure.financecontrib
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.financecontrib", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Finance Contrib
     * Fact ID: fact.re_all_secure.financecontrib
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.financecontrib", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Finance Contrib
     * Fact ID: fact.re_all_secure.financecontrib
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.financecontrib", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Finance Contrib
     * Fact ID: fact.re_all_secure.financecontrib
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.financecontrib", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Finance Contrib
     * Fact ID: fact.re_all_secure.financecontrib
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.financecontrib", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Financialloss
 * Fact ID: fact.re_all_secure.financialloss
 */
export const Financialloss = {
    /**
     * Fact Title: Financialloss
     * Fact ID: fact.re_all_secure.financialloss
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.financialloss", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Financialloss
     * Fact ID: fact.re_all_secure.financialloss
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.financialloss", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Financialloss
     * Fact ID: fact.re_all_secure.financialloss
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.financialloss", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Financialloss
     * Fact ID: fact.re_all_secure.financialloss
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.financialloss", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Financialloss
     * Fact ID: fact.re_all_secure.financialloss
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.financialloss", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Financialloss
     * Fact ID: fact.re_all_secure.financialloss
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.financialloss", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Fl Breakdown
 * Fact ID: fact.re_all_secure.fl_breakdown
 */
export const FlBreakdown = {
    /**
     * Fact Title: Fl Breakdown
     * Fact ID: fact.re_all_secure.fl_breakdown
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.fl_breakdown", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Fl Breakdown
     * Fact ID: fact.re_all_secure.fl_breakdown
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.fl_breakdown", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Fl Breakdown
     * Fact ID: fact.re_all_secure.fl_breakdown
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.fl_breakdown", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Fl Breakdown
     * Fact ID: fact.re_all_secure.fl_breakdown
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.fl_breakdown", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Fl Breakdown
     * Fact ID: fact.re_all_secure.fl_breakdown
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.fl_breakdown", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Fl Breakdown
     * Fact ID: fact.re_all_secure.fl_breakdown
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.fl_breakdown", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Fl Co2malus
 * Fact ID: fact.re_all_secure.fl_co2malus
 */
export const FlCo2malus = {
    /**
     * Fact Title: Fl Co2malus
     * Fact ID: fact.re_all_secure.fl_co2malus
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.fl_co2malus", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Fl Co2malus
     * Fact ID: fact.re_all_secure.fl_co2malus
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.fl_co2malus", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Fl Co2malus
     * Fact ID: fact.re_all_secure.fl_co2malus
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.fl_co2malus", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Fl Co2malus
     * Fact ID: fact.re_all_secure.fl_co2malus
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.fl_co2malus", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Fl Co2malus
     * Fact ID: fact.re_all_secure.fl_co2malus
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.fl_co2malus", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Fl Co2malus
     * Fact ID: fact.re_all_secure.fl_co2malus
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.fl_co2malus", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Fl Evbonus
 * Fact ID: fact.re_all_secure.fl_evbonus
 */
export const FlEvbonus = {
    /**
     * Fact Title: Fl Evbonus
     * Fact ID: fact.re_all_secure.fl_evbonus
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.fl_evbonus", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Fl Evbonus
     * Fact ID: fact.re_all_secure.fl_evbonus
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.fl_evbonus", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Fl Evbonus
     * Fact ID: fact.re_all_secure.fl_evbonus
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.fl_evbonus", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Fl Evbonus
     * Fact ID: fact.re_all_secure.fl_evbonus
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.fl_evbonus", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Fl Evbonus
     * Fact ID: fact.re_all_secure.fl_evbonus
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.fl_evbonus", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Fl Evbonus
     * Fact ID: fact.re_all_secure.fl_evbonus
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.fl_evbonus", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Fl Financialloss
 * Fact ID: fact.re_all_secure.fl_financialloss
 */
export const FlFinancialloss = {
    /**
     * Fact Title: Fl Financialloss
     * Fact ID: fact.re_all_secure.fl_financialloss
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.fl_financialloss", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Fl Financialloss
     * Fact ID: fact.re_all_secure.fl_financialloss
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.fl_financialloss", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Fl Financialloss
     * Fact ID: fact.re_all_secure.fl_financialloss
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.fl_financialloss", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Fl Financialloss
     * Fact ID: fact.re_all_secure.fl_financialloss
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.fl_financialloss", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Fl Financialloss
     * Fact ID: fact.re_all_secure.fl_financialloss
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.fl_financialloss", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Fl Financialloss
     * Fact ID: fact.re_all_secure.fl_financialloss
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.fl_financialloss", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Fl Insurance
 * Fact ID: fact.re_all_secure.fl_insurance
 */
export const FlInsurance = {
    /**
     * Fact Title: Fl Insurance
     * Fact ID: fact.re_all_secure.fl_insurance
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.fl_insurance", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Fl Insurance
     * Fact ID: fact.re_all_secure.fl_insurance
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.fl_insurance", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Fl Insurance
     * Fact ID: fact.re_all_secure.fl_insurance
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.fl_insurance", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Fl Insurance
     * Fact ID: fact.re_all_secure.fl_insurance
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.fl_insurance", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Fl Insurance
     * Fact ID: fact.re_all_secure.fl_insurance
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.fl_insurance", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Fl Insurance
     * Fact ID: fact.re_all_secure.fl_insurance
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.fl_insurance", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Fl Offer
 * Fact ID: fact.re_all_secure.fl_offer
 */
export const FlOffer = {
    /**
     * Fact Title: Fl Offer
     * Fact ID: fact.re_all_secure.fl_offer
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.fl_offer", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Fl Offer
     * Fact ID: fact.re_all_secure.fl_offer
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.fl_offer", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Fl Offer
     * Fact ID: fact.re_all_secure.fl_offer
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.fl_offer", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Fl Offer
     * Fact ID: fact.re_all_secure.fl_offer
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.fl_offer", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Fl Offer
     * Fact ID: fact.re_all_secure.fl_offer
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.fl_offer", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Fl Offer
     * Fact ID: fact.re_all_secure.fl_offer
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.fl_offer", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Fl Other
 * Fact ID: fact.re_all_secure.fl_other
 */
export const FlOther = {
    /**
     * Fact Title: Fl Other
     * Fact ID: fact.re_all_secure.fl_other
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.fl_other", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Fl Other
     * Fact ID: fact.re_all_secure.fl_other
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.fl_other", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Fl Other
     * Fact ID: fact.re_all_secure.fl_other
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.fl_other", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Fl Other
     * Fact ID: fact.re_all_secure.fl_other
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.fl_other", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Fl Other
     * Fact ID: fact.re_all_secure.fl_other
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.fl_other", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Fl Other
     * Fact ID: fact.re_all_secure.fl_other
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.fl_other", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Fl Other2
 * Fact ID: fact.re_all_secure.fl_other2
 */
export const FlOther2 = {
    /**
     * Fact Title: Fl Other2
     * Fact ID: fact.re_all_secure.fl_other2
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.fl_other2", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Fl Other2
     * Fact ID: fact.re_all_secure.fl_other2
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.fl_other2", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Fl Other2
     * Fact ID: fact.re_all_secure.fl_other2
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.fl_other2", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Fl Other2
     * Fact ID: fact.re_all_secure.fl_other2
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.fl_other2", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Fl Other2
     * Fact ID: fact.re_all_secure.fl_other2
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.fl_other2", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Fl Other2
     * Fact ID: fact.re_all_secure.fl_other2
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.fl_other2", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Fl Replacement
 * Fact ID: fact.re_all_secure.fl_replacement
 */
export const FlReplacement = {
    /**
     * Fact Title: Fl Replacement
     * Fact ID: fact.re_all_secure.fl_replacement
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.fl_replacement", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Fl Replacement
     * Fact ID: fact.re_all_secure.fl_replacement
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.fl_replacement", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Fl Replacement
     * Fact ID: fact.re_all_secure.fl_replacement
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.fl_replacement", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Fl Replacement
     * Fact ID: fact.re_all_secure.fl_replacement
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.fl_replacement", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Fl Replacement
     * Fact ID: fact.re_all_secure.fl_replacement
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.fl_replacement", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Fl Replacement
     * Fact ID: fact.re_all_secure.fl_replacement
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.fl_replacement", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Fl Rw
 * Fact ID: fact.re_all_secure.fl_rw
 */
export const FlRw = {
    /**
     * Fact Title: Fl Rw
     * Fact ID: fact.re_all_secure.fl_rw
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.fl_rw", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Fl Rw
     * Fact ID: fact.re_all_secure.fl_rw
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.fl_rw", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Fl Rw
     * Fact ID: fact.re_all_secure.fl_rw
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.fl_rw", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Fl Rw
     * Fact ID: fact.re_all_secure.fl_rw
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.fl_rw", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Fl Rw
     * Fact ID: fact.re_all_secure.fl_rw
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.fl_rw", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Fl Rw
     * Fact ID: fact.re_all_secure.fl_rw
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.fl_rw", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Fl Service
 * Fact ID: fact.re_all_secure.fl_service
 */
export const FlService = {
    /**
     * Fact Title: Fl Service
     * Fact ID: fact.re_all_secure.fl_service
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.fl_service", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Fl Service
     * Fact ID: fact.re_all_secure.fl_service
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.fl_service", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Fl Service
     * Fact ID: fact.re_all_secure.fl_service
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.fl_service", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Fl Service
     * Fact ID: fact.re_all_secure.fl_service
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.fl_service", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Fl Service
     * Fact ID: fact.re_all_secure.fl_service
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.fl_service", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Fl Service
     * Fact ID: fact.re_all_secure.fl_service
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.fl_service", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Fl Tyres
 * Fact ID: fact.re_all_secure.fl_tyres
 */
export const FlTyres = {
    /**
     * Fact Title: Fl Tyres
     * Fact ID: fact.re_all_secure.fl_tyres
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.fl_tyres", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Fl Tyres
     * Fact ID: fact.re_all_secure.fl_tyres
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.fl_tyres", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Fl Tyres
     * Fact ID: fact.re_all_secure.fl_tyres
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.fl_tyres", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Fl Tyres
     * Fact ID: fact.re_all_secure.fl_tyres
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.fl_tyres", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Fl Tyres
     * Fact ID: fact.re_all_secure.fl_tyres
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.fl_tyres", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Fl Tyres
     * Fact ID: fact.re_all_secure.fl_tyres
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.fl_tyres", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Fl Wintertyres
 * Fact ID: fact.re_all_secure.fl_wintertyres
 */
export const FlWintertyres = {
    /**
     * Fact Title: Fl Wintertyres
     * Fact ID: fact.re_all_secure.fl_wintertyres
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.fl_wintertyres", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Fl Wintertyres
     * Fact ID: fact.re_all_secure.fl_wintertyres
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.fl_wintertyres", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Fl Wintertyres
     * Fact ID: fact.re_all_secure.fl_wintertyres
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.fl_wintertyres", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Fl Wintertyres
     * Fact ID: fact.re_all_secure.fl_wintertyres
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.fl_wintertyres", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Fl Wintertyres
     * Fact ID: fact.re_all_secure.fl_wintertyres
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.fl_wintertyres", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Fl Wintertyres
     * Fact ID: fact.re_all_secure.fl_wintertyres
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.fl_wintertyres", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Flag 7seat
 * Fact ID: fact.re_all_secure.flag_7seat
 */
export const Flag7seat = {
    /**
     * Fact Title: Flag 7seat
     * Fact ID: fact.re_all_secure.flag_7seat
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.flag_7seat", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Flag 7seat
     * Fact ID: fact.re_all_secure.flag_7seat
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.flag_7seat", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Flag 7seat
     * Fact ID: fact.re_all_secure.flag_7seat
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.flag_7seat", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Flag 7seat
     * Fact ID: fact.re_all_secure.flag_7seat
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.flag_7seat", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Flag 7seat
     * Fact ID: fact.re_all_secure.flag_7seat
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.flag_7seat", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Flag 7seat
     * Fact ID: fact.re_all_secure.flag_7seat
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.flag_7seat", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Gvw
 * Fact ID: fact.re_all_secure.gvw
 */
export const Gvw = {
    /**
     * Fact Title: Gvw
     * Fact ID: fact.re_all_secure.gvw
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.gvw", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Gvw
     * Fact ID: fact.re_all_secure.gvw
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.gvw", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Gvw
     * Fact ID: fact.re_all_secure.gvw
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.gvw", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Gvw
     * Fact ID: fact.re_all_secure.gvw
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.gvw", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Gvw
     * Fact ID: fact.re_all_secure.gvw
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.gvw", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Gvw
     * Fact ID: fact.re_all_secure.gvw
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.gvw", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Insurance
 * Fact ID: fact.re_all_secure.insurance
 */
export const Insurance = {
    /**
     * Fact Title: Insurance
     * Fact ID: fact.re_all_secure.insurance
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.insurance", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Insurance
     * Fact ID: fact.re_all_secure.insurance
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.insurance", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Insurance
     * Fact ID: fact.re_all_secure.insurance
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.insurance", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Insurance
     * Fact ID: fact.re_all_secure.insurance
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.insurance", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Insurance
     * Fact ID: fact.re_all_secure.insurance
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.insurance", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Insurance
     * Fact ID: fact.re_all_secure.insurance
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.insurance", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Kw
 * Fact ID: fact.re_all_secure.kw
 */
export const Kw = {
    /**
     * Fact Title: Kw
     * Fact ID: fact.re_all_secure.kw
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.kw", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Kw
     * Fact ID: fact.re_all_secure.kw
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.kw", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Kw
     * Fact ID: fact.re_all_secure.kw
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.kw", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Kw
     * Fact ID: fact.re_all_secure.kw
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.kw", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Kw
     * Fact ID: fact.re_all_secure.kw
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.kw", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Kw
     * Fact ID: fact.re_all_secure.kw
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.kw", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: latestm
 * Fact ID: fact.re_all_secure.latestm
 */
export const Latestm = {
    /**
     * Fact Title: latestm
     * Fact ID: fact.re_all_secure.latestm
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.latestm", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: latestm
     * Fact ID: fact.re_all_secure.latestm
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.latestm", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: latestm
     * Fact ID: fact.re_all_secure.latestm
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.latestm", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: latestm
     * Fact ID: fact.re_all_secure.latestm
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.latestm", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: latestm
     * Fact ID: fact.re_all_secure.latestm
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.latestm", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: latestm
     * Fact ID: fact.re_all_secure.latestm
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.latestm", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: latestw
 * Fact ID: fact.re_all_secure.latestw
 */
export const Latestw = {
    /**
     * Fact Title: latestw
     * Fact ID: fact.re_all_secure.latestw
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.latestw", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: latestw
     * Fact ID: fact.re_all_secure.latestw
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.latestw", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: latestw
     * Fact ID: fact.re_all_secure.latestw
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.latestw", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: latestw
     * Fact ID: fact.re_all_secure.latestw
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.latestw", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: latestw
     * Fact ID: fact.re_all_secure.latestw
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.latestw", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: latestw
     * Fact ID: fact.re_all_secure.latestw
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.latestw", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: List Price
 * Fact ID: fact.re_all_secure.list_price
 */
export const ListPrice = {
    /**
     * Fact Title: List Price
     * Fact ID: fact.re_all_secure.list_price
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.list_price", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: List Price
     * Fact ID: fact.re_all_secure.list_price
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.list_price", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: List Price
     * Fact ID: fact.re_all_secure.list_price
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.list_price", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: List Price
     * Fact ID: fact.re_all_secure.list_price
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.list_price", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: List Price
     * Fact ID: fact.re_all_secure.list_price
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.list_price", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: List Price
     * Fact ID: fact.re_all_secure.list_price
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.list_price", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Otr Price
 * Fact ID: fact.re_all_secure.otrprice
 */
export const OtrPrice = {
    /**
     * Fact Title: Otr Price
     * Fact ID: fact.re_all_secure.otrprice
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.otrprice", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Otr Price
     * Fact ID: fact.re_all_secure.otrprice
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.otrprice", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Otr Price
     * Fact ID: fact.re_all_secure.otrprice
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.otrprice", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Otr Price
     * Fact ID: fact.re_all_secure.otrprice
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.otrprice", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Otr Price
     * Fact ID: fact.re_all_secure.otrprice
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.otrprice", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Otr Price
     * Fact ID: fact.re_all_secure.otrprice
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.otrprice", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: period2
 * Fact ID: fact.re_all_secure.period2
 */
export const Period2 = {
    /**
     * Fact Title: period2
     * Fact ID: fact.re_all_secure.period2
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.period2", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: period2
     * Fact ID: fact.re_all_secure.period2
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.period2", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: period2
     * Fact ID: fact.re_all_secure.period2
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.period2", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: period2
     * Fact ID: fact.re_all_secure.period2
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.period2", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: period2
     * Fact ID: fact.re_all_secure.period2
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.period2", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: period2
     * Fact ID: fact.re_all_secure.period2
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.period2", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: periodcat
 * Fact ID: fact.re_all_secure.periodcat
 */
export const Periodcat = {
    /**
     * Fact Title: periodcat
     * Fact ID: fact.re_all_secure.periodcat
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.periodcat", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: periodcat
     * Fact ID: fact.re_all_secure.periodcat
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.periodcat", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: periodcat
     * Fact ID: fact.re_all_secure.periodcat
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.periodcat", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: periodcat
     * Fact ID: fact.re_all_secure.periodcat
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.periodcat", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: periodcat
     * Fact ID: fact.re_all_secure.periodcat
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.periodcat", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: periodcat
     * Fact ID: fact.re_all_secure.periodcat
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.periodcat", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Prev Deposit
 * Fact ID: fact.re_all_secure.prev_deposit
 */
export const PrevDeposit = {
    /**
     * Fact Title: Prev Deposit
     * Fact ID: fact.re_all_secure.prev_deposit
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.prev_deposit", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Prev Deposit
     * Fact ID: fact.re_all_secure.prev_deposit
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.prev_deposit", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Prev Deposit
     * Fact ID: fact.re_all_secure.prev_deposit
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.prev_deposit", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Prev Deposit
     * Fact ID: fact.re_all_secure.prev_deposit
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.prev_deposit", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Prev Deposit
     * Fact ID: fact.re_all_secure.prev_deposit
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.prev_deposit", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Prev Deposit
     * Fact ID: fact.re_all_secure.prev_deposit
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.prev_deposit", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Prev Rental
 * Fact ID: fact.re_all_secure.prev_rental
 */
export const PrevRental = {
    /**
     * Fact Title: Prev Rental
     * Fact ID: fact.re_all_secure.prev_rental
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.prev_rental", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Prev Rental
     * Fact ID: fact.re_all_secure.prev_rental
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.prev_rental", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Prev Rental
     * Fact ID: fact.re_all_secure.prev_rental
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.prev_rental", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Prev Rental
     * Fact ID: fact.re_all_secure.prev_rental
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.prev_rental", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Prev Rental
     * Fact ID: fact.re_all_secure.prev_rental
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.prev_rental", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Prev Rental
     * Fact ID: fact.re_all_secure.prev_rental
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.prev_rental", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Ps
 * Fact ID: fact.re_all_secure.ps
 */
export const Ps = {
    /**
     * Fact Title: Ps
     * Fact ID: fact.re_all_secure.ps
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.ps", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Ps
     * Fact ID: fact.re_all_secure.ps
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.ps", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Ps
     * Fact ID: fact.re_all_secure.ps
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.ps", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Ps
     * Fact ID: fact.re_all_secure.ps
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.ps", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Ps
     * Fact ID: fact.re_all_secure.ps
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.ps", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Ps
     * Fact ID: fact.re_all_secure.ps
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.ps", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Rental
 * Fact ID: fact.re_all_secure.rental
 */
export const Rental = {
    /**
     * Fact Title: Rental
     * Fact ID: fact.re_all_secure.rental
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.rental", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Rental
     * Fact ID: fact.re_all_secure.rental
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.rental", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Rental
     * Fact ID: fact.re_all_secure.rental
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.rental", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Rental
     * Fact ID: fact.re_all_secure.rental
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.rental", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Rental
     * Fact ID: fact.re_all_secure.rental
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.rental", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Rental
     * Fact ID: fact.re_all_secure.rental
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.rental", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Rental Other
 * Fact ID: fact.re_all_secure.rental_other
 */
export const RentalOther = {
    /**
     * Fact Title: Rental Other
     * Fact ID: fact.re_all_secure.rental_other
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.rental_other", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Rental Other
     * Fact ID: fact.re_all_secure.rental_other
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.rental_other", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Rental Other
     * Fact ID: fact.re_all_secure.rental_other
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.rental_other", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Rental Other
     * Fact ID: fact.re_all_secure.rental_other
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.rental_other", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Rental Other
     * Fact ID: fact.re_all_secure.rental_other
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.rental_other", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Rental Other
     * Fact ID: fact.re_all_secure.rental_other
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.rental_other", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Rental Other2
 * Fact ID: fact.re_all_secure.rental_other2
 */
export const RentalOther2 = {
    /**
     * Fact Title: Rental Other2
     * Fact ID: fact.re_all_secure.rental_other2
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.rental_other2", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Rental Other2
     * Fact ID: fact.re_all_secure.rental_other2
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.rental_other2", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Rental Other2
     * Fact ID: fact.re_all_secure.rental_other2
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.rental_other2", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Rental Other2
     * Fact ID: fact.re_all_secure.rental_other2
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.rental_other2", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Rental Other2
     * Fact ID: fact.re_all_secure.rental_other2
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.rental_other2", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Rental Other2
     * Fact ID: fact.re_all_secure.rental_other2
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.rental_other2", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Replacement Vehicle
 * Fact ID: fact.re_all_secure.replacement_vehicle
 */
export const ReplacementVehicle = {
    /**
     * Fact Title: Replacement Vehicle
     * Fact ID: fact.re_all_secure.replacement_vehicle
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.replacement_vehicle", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Replacement Vehicle
     * Fact ID: fact.re_all_secure.replacement_vehicle
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.replacement_vehicle", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Replacement Vehicle
     * Fact ID: fact.re_all_secure.replacement_vehicle
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.replacement_vehicle", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Replacement Vehicle
     * Fact ID: fact.re_all_secure.replacement_vehicle
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.replacement_vehicle", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Replacement Vehicle
     * Fact ID: fact.re_all_secure.replacement_vehicle
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.replacement_vehicle", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Replacement Vehicle
     * Fact ID: fact.re_all_secure.replacement_vehicle
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.replacement_vehicle", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Rv
 * Fact ID: fact.re_all_secure.rv
 */
export const Rv = {
    /**
     * Fact Title: Rv
     * Fact ID: fact.re_all_secure.rv
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.rv", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Rv
     * Fact ID: fact.re_all_secure.rv
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.rv", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Rv
     * Fact ID: fact.re_all_secure.rv
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.rv", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Rv
     * Fact ID: fact.re_all_secure.rv
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.rv", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Rv
     * Fact ID: fact.re_all_secure.rv
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.rv", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Rv
     * Fact ID: fact.re_all_secure.rv
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.rv", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Seats
 * Fact ID: fact.re_all_secure.seats
 */
export const Seats = {
    /**
     * Fact Title: Seats
     * Fact ID: fact.re_all_secure.seats
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.seats", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Seats
     * Fact ID: fact.re_all_secure.seats
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.seats", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Seats
     * Fact ID: fact.re_all_secure.seats
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.seats", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Seats
     * Fact ID: fact.re_all_secure.seats
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.seats", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Seats
     * Fact ID: fact.re_all_secure.seats
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.seats", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Seats
     * Fact ID: fact.re_all_secure.seats
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.seats", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Service
 * Fact ID: fact.re_all_secure.service
 */
export const Service = {
    /**
     * Fact Title: Service
     * Fact ID: fact.re_all_secure.service
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.service", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Service
     * Fact ID: fact.re_all_secure.service
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.service", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Service
     * Fact ID: fact.re_all_secure.service
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.service", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Service
     * Fact ID: fact.re_all_secure.service
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.service", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Service
     * Fact ID: fact.re_all_secure.service
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.service", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Service
     * Fact ID: fact.re_all_secure.service
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.service", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Total Charges
 * Fact ID: fact.re_all_secure.totalcharges
 */
export const TotalCharges = {
    /**
     * Fact Title: Total Charges
     * Fact ID: fact.re_all_secure.totalcharges
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.totalcharges", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Total Charges
     * Fact ID: fact.re_all_secure.totalcharges
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.totalcharges", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Total Charges
     * Fact ID: fact.re_all_secure.totalcharges
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.totalcharges", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Total Charges
     * Fact ID: fact.re_all_secure.totalcharges
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.totalcharges", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Total Charges
     * Fact ID: fact.re_all_secure.totalcharges
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.totalcharges", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Total Charges
     * Fact ID: fact.re_all_secure.totalcharges
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.totalcharges", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Total Credit
 * Fact ID: fact.re_all_secure.totalcredit
 */
export const TotalCredit = {
    /**
     * Fact Title: Total Credit
     * Fact ID: fact.re_all_secure.totalcredit
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.totalcredit", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Total Credit
     * Fact ID: fact.re_all_secure.totalcredit
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.totalcredit", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Total Credit
     * Fact ID: fact.re_all_secure.totalcredit
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.totalcredit", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Total Credit
     * Fact ID: fact.re_all_secure.totalcredit
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.totalcredit", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Total Credit
     * Fact ID: fact.re_all_secure.totalcredit
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.totalcredit", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Total Credit
     * Fact ID: fact.re_all_secure.totalcredit
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.totalcredit", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Total Payable
 * Fact ID: fact.re_all_secure.totalpayable
 */
export const TotalPayable = {
    /**
     * Fact Title: Total Payable
     * Fact ID: fact.re_all_secure.totalpayable
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.totalpayable", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Total Payable
     * Fact ID: fact.re_all_secure.totalpayable
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.totalpayable", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Total Payable
     * Fact ID: fact.re_all_secure.totalpayable
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.totalpayable", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Total Payable
     * Fact ID: fact.re_all_secure.totalpayable
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.totalpayable", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Total Payable
     * Fact ID: fact.re_all_secure.totalpayable
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.totalpayable", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Total Payable
     * Fact ID: fact.re_all_secure.totalpayable
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.totalpayable", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Tyres
 * Fact ID: fact.re_all_secure.tyres
 */
export const Tyres = {
    /**
     * Fact Title: Tyres
     * Fact ID: fact.re_all_secure.tyres
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.tyres", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Tyres
     * Fact ID: fact.re_all_secure.tyres
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.tyres", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Tyres
     * Fact ID: fact.re_all_secure.tyres
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.tyres", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Tyres
     * Fact ID: fact.re_all_secure.tyres
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.tyres", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Tyres
     * Fact ID: fact.re_all_secure.tyres
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.tyres", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Tyres
     * Fact ID: fact.re_all_secure.tyres
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.tyres", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Wintertyres
 * Fact ID: fact.re_all_secure.wintertyres
 */
export const Wintertyres = {
    /**
     * Fact Title: Wintertyres
     * Fact ID: fact.re_all_secure.wintertyres
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.re_all_secure.wintertyres", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Wintertyres
     * Fact ID: fact.re_all_secure.wintertyres
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.re_all_secure.wintertyres", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Wintertyres
     * Fact ID: fact.re_all_secure.wintertyres
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.re_all_secure.wintertyres", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Wintertyres
     * Fact ID: fact.re_all_secure.wintertyres
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.re_all_secure.wintertyres", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Wintertyres
     * Fact ID: fact.re_all_secure.wintertyres
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.re_all_secure.wintertyres", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Wintertyres
     * Fact ID: fact.re_all_secure.wintertyres
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.re_all_secure.wintertyres", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Attribute Title: Year (date_of_scraping)
 * Attribute ID: date_of_scraping.year
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingYear = newAttribute("date_of_scraping.year.default");
/**
 * Attribute Title: Quarter (date_of_scraping)
 * Attribute ID: date_of_scraping.quarter.in.year
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingQuarter = newAttribute("date_of_scraping.quarter.in.year.default");
/**
 * Attribute Title: Week (Sun-Sat)/Year (date_of_scraping)
 * Attribute ID: date_of_scraping.week
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingWeekSunSatYear = {
    /**
     * Display Form Title: Week #/Year (W1/2010) (date_of_scraping)
     * Display Form ID: date_of_scraping.week.wk_year
     */
    WeekNrYear: newAttribute("date_of_scraping.week.wk_year"),
    /**
     * Display Form Title: Week Starting (date_of_scraping)
     * Display Form ID: date_of_scraping.week.starting
     */ WeekStarting: newAttribute("date_of_scraping.week.starting"),
    /**
     * Display Form Title: From - To (date_of_scraping)
     * Display Form ID: date_of_scraping.week.from_to
     */ FromTo: newAttribute("date_of_scraping.week.from_to"),
    /**
     * Display Form Title: Week #/Year (Cont.) (date_of_scraping)
     * Display Form ID: date_of_scraping.week.wk_year_cont
     */ WeekNrYear_1: newAttribute("date_of_scraping.week.wk_year_cont"),
    /**
     * Display Form Title: Wk/Qtr/Year (Cont.) (date_of_scraping)
     * Display Form ID: date_of_scraping.week.wk_qtr_year_cont
     */ WkQtrYear: newAttribute("date_of_scraping.week.wk_qtr_year_cont"),
    /**
     * Display Form Title: Wk/Qtr/Year (date_of_scraping)
     * Display Form ID: date_of_scraping.week.wk_qtr_year
     */ WkQtrYear_1: newAttribute("date_of_scraping.week.wk_qtr_year"),
};
/**
 * Attribute Title: Week (Sun-Sat) (date_of_scraping)
 * Attribute ID: date_of_scraping.week.in.year
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingWeekSunSat = newAttribute("date_of_scraping.week.in.year.number_us");
/**
 * Attribute Title: Week (Sun-Sat) of Qtr (date_of_scraping)
 * Attribute ID: date_of_scraping.week.in.quarter
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingWeekSunSatOfQtr = newAttribute("date_of_scraping.week.in.quarter.number_us");
/**
 * Attribute Title: Week (Mon-Sun)/Year (date_of_scraping)
 * Attribute ID: date_of_scraping.euweek
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingWeekMonSunYear = {
    /**
     * Display Form Title: Week #/Year (W1/2010) (date_of_scraping)
     * Display Form ID: date_of_scraping.euweek.wk_year
     */
    WeekNrYear: newAttribute("date_of_scraping.euweek.wk_year"),
    /**
     * Display Form Title: Week Starting (date_of_scraping)
     * Display Form ID: date_of_scraping.euweek.starting
     */ WeekStarting: newAttribute("date_of_scraping.euweek.starting"),
    /**
     * Display Form Title: From - To (date_of_scraping)
     * Display Form ID: date_of_scraping.euweek.from_to
     */ FromTo: newAttribute("date_of_scraping.euweek.from_to"),
};
/**
 * Attribute Title: Week (Mon-Sun) (date_of_scraping)
 * Attribute ID: date_of_scraping.euweek.in.year
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingWeekMonSun = newAttribute("date_of_scraping.euweek.in.year.number_eu");
/**
 * Attribute Title: Week (Mon-Sun) of Qtr (date_of_scraping)
 * Attribute ID: date_of_scraping.euweek.in.quarter
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingWeekMonSunOfQtr = newAttribute("date_of_scraping.euweek.in.quarter.number_eu");
/**
 * Attribute Title: Month (date_of_scraping)
 * Attribute ID: date_of_scraping.month.in.year
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingMonth = {
    /**
     * Display Form Title: Short (Jan) (date_of_scraping)
     * Display Form ID: date_of_scraping.month.in.year.short
     */
    Short: newAttribute("date_of_scraping.month.in.year.short"),
    /**
     * Display Form Title: Long (January) (date_of_scraping)
     * Display Form ID: date_of_scraping.month.in.year.long
     */ Long: newAttribute("date_of_scraping.month.in.year.long"),
    /**
     * Display Form Title: Number (M1) (date_of_scraping)
     * Display Form ID: date_of_scraping.month.in.year.number
     */ Number: newAttribute("date_of_scraping.month.in.year.number"),
    /**
     * Display Form Title: M/Q (M1/Q1) (date_of_scraping)
     * Display Form ID: date_of_scraping.month.in.year.m_q
     */ MQ: newAttribute("date_of_scraping.month.in.year.m_q"),
};
/**
 * Attribute Title: Month of Quarter (date_of_scraping)
 * Attribute ID: date_of_scraping.month.in.quarter
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingMonthOfQuarter = newAttribute("date_of_scraping.month.in.quarter.number");
/**
 * Attribute Title: Day of Year (date_of_scraping)
 * Attribute ID: date_of_scraping.day.in.year
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingDayOfYear = newAttribute("date_of_scraping.day.in.year.default");
/**
 * Attribute Title: Day of Week (Sun-Sat) (date_of_scraping)
 * Attribute ID: date_of_scraping.day.in.week
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingDayOfWeekSunSat = {
    /**
     * Display Form Title: Short (Sun) (date_of_scraping)
     * Display Form ID: date_of_scraping.day.in.week.short
     */
    Short: newAttribute("date_of_scraping.day.in.week.short"),
    /**
     * Display Form Title: Long (Sunday) (date_of_scraping)
     * Display Form ID: date_of_scraping.day.in.week.long
     */ Long: newAttribute("date_of_scraping.day.in.week.long"),
    /**
     * Display Form Title: Number (1=Sunday) (date_of_scraping)
     * Display Form ID: date_of_scraping.day.in.week.number
     */ Number: newAttribute("date_of_scraping.day.in.week.number"),
};
/**
 * Attribute Title: Day of Week (Mon-Sun) (date_of_scraping)
 * Attribute ID: date_of_scraping.day.in.euweek
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingDayOfWeekMonSun = {
    /**
     * Display Form Title: Short (Mon) (date_of_scraping)
     * Display Form ID: date_of_scraping.day.in.euweek.short
     */
    Short: newAttribute("date_of_scraping.day.in.euweek.short"),
    /**
     * Display Form Title: Long (Monday) (date_of_scraping)
     * Display Form ID: date_of_scraping.day.in.euweek.long
     */ Long: newAttribute("date_of_scraping.day.in.euweek.long"),
    /**
     * Display Form Title: Number (1=Monday) (date_of_scraping)
     * Display Form ID: date_of_scraping.day.in.euweek.number
     */ Number: newAttribute("date_of_scraping.day.in.euweek.number"),
};
/**
 * Attribute Title: Day of Quarter (date_of_scraping)
 * Attribute ID: date_of_scraping.day.in.quarter
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingDayOfQuarter = newAttribute("date_of_scraping.day.in.quarter.default");
/**
 * Attribute Title: Day of Month (date_of_scraping)
 * Attribute ID: date_of_scraping.day.in.month
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingDayOfMonth = newAttribute("date_of_scraping.day.in.month.default");
/**
 * Attribute Title: Quarter/Year (date_of_scraping)
 * Attribute ID: date_of_scraping.quarter
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingQuarterYear = newAttribute("date_of_scraping.quarter.short_us");
/**
 * Attribute Title: Month/Year (date_of_scraping)
 * Attribute ID: date_of_scraping.month
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingMonthYear = {
    /**
     * Display Form Title: Short (Jan 2010) (date_of_scraping)
     * Display Form ID: date_of_scraping.month.short
     */
    Short: newAttribute("date_of_scraping.month.short"),
    /**
     * Display Form Title: Long (January 2010) (date_of_scraping)
     * Display Form ID: date_of_scraping.month.long
     */ Long: newAttribute("date_of_scraping.month.long"),
    /**
     * Display Form Title: Number (1/2010) (date_of_scraping)
     * Display Form ID: date_of_scraping.month.number
     */ Number: newAttribute("date_of_scraping.month.number"),
};
/**
 * Attribute Title: Date (date_of_scraping)
 * Attribute ID: date_of_scraping.date
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingDate = {
    /**
     * Display Form Title: mm/dd/yyyy (date_of_scraping)
     * Display Form ID: date_of_scraping.date.day.us.mm_dd_yyyy
     */
    MmDdYyyy: newAttribute("date_of_scraping.date.day.us.mm_dd_yyyy"),
    /**
     * Display Form Title: yyyy-mm-dd (date_of_scraping)
     * Display Form ID: date_of_scraping.date.day.yyyy_mm_dd
     */ YyyyMmDd: newAttribute("date_of_scraping.date.day.yyyy_mm_dd"),
    /**
     * Display Form Title: m/d/yy (no leading zeroes) (date_of_scraping)
     * Display Form ID: date_of_scraping.date.day.us.m_d_yy
     */ MDYy: newAttribute("date_of_scraping.date.day.us.m_d_yy"),
    /**
     * Display Form Title: Long (Mon, Jan 1, 2010) (date_of_scraping)
     * Display Form ID: date_of_scraping.date.day.us.long
     */ Long: newAttribute("date_of_scraping.date.day.us.long"),
    /**
     * Display Form Title: dd/mm/yyyy (date_of_scraping)
     * Display Form ID: date_of_scraping.date.day.uk.dd_mm_yyyy
     */ DdMmYyyy: newAttribute("date_of_scraping.date.day.uk.dd_mm_yyyy"),
    /**
     * Display Form Title: dd-mm-yyyy (date_of_scraping)
     * Display Form ID: date_of_scraping.date.day.eu.dd_mm_yyyy
     */ DdMmYyyy_1: newAttribute("date_of_scraping.date.day.eu.dd_mm_yyyy"),
};
/**
 * Attribute Title: US Week Year (date_of_scraping)
 * Attribute ID: date_of_scraping.year.for.week
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingUSWeekYear = newAttribute("date_of_scraping.year.for.week.number");
/**
 * Attribute Title: EU Week Year (date_of_scraping)
 * Attribute ID: date_of_scraping.year.for.euweek
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingEUWeekYear = newAttribute("date_of_scraping.year.for.euweek.number");
/**
 * Attribute Title: US Week Quarter (date_of_scraping)
 * Attribute ID: date_of_scraping.quarter.for.week
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingUSWeekQuarter = newAttribute("date_of_scraping.quarter.for.week.number");
/**
 * Attribute Title: EU Week Quarter (date_of_scraping)
 * Attribute ID: date_of_scraping.quarter.for.euweek
 * @deprecated constants generated for date attributes are deprecated in favor of DateDatasets mapping
 */
export const DateOfScrapingEUWeekQuarter = newAttribute("date_of_scraping.quarter.for.euweek.number");
/** Available Date Data Sets */
export const DateDatasets = {
    /**
     * Date Data Set Title: Date (date_of_scraping)
     * Date Data Set ID: date_of_scraping.dataset.dt
     */
    DateOfScraping: {
        ref: idRef("date_of_scraping.dataset.dt", "dataSet"),
        identifier: "date_of_scraping.dataset.dt",
        /**
         * Date Attribute: Year (date_of_scraping)
         * Date Attribute ID: date_of_scraping.year
         */ Year: {
            ref: idRef("date_of_scraping.year", "attribute"),
            identifier: "date_of_scraping.year",
            /**
             * Display Form Title: Year (date_of_scraping)
             * Display Form ID: date_of_scraping.year.default
             */ Default: newAttribute("date_of_scraping.year.default"),
        },
        /**
         * Date Attribute: Quarter (date_of_scraping)
         * Date Attribute ID: date_of_scraping.quarter.in.year
         */ Quarter: {
            ref: idRef("date_of_scraping.quarter.in.year", "attribute"),
            identifier: "date_of_scraping.quarter.in.year",
            /**
             * Display Form Title: default (date_of_scraping)
             * Display Form ID: date_of_scraping.quarter.in.year.default
             */ Default: newAttribute("date_of_scraping.quarter.in.year.default"),
        },
        /**
         * Date Attribute: Week (Sun-Sat)/Year (date_of_scraping)
         * Date Attribute ID: date_of_scraping.week
         */ WeekSunSatYear: {
            ref: idRef("date_of_scraping.week", "attribute"),
            identifier: "date_of_scraping.week",
            /**
             * Display Form Title: Week #/Year (W1/2010) (date_of_scraping)
             * Display Form ID: date_of_scraping.week.wk_year
             */ WeekNrYear: newAttribute("date_of_scraping.week.wk_year"),
            /**
             * Display Form Title: Week Starting (date_of_scraping)
             * Display Form ID: date_of_scraping.week.starting
             */ WeekStarting: newAttribute("date_of_scraping.week.starting"),
            /**
             * Display Form Title: From - To (date_of_scraping)
             * Display Form ID: date_of_scraping.week.from_to
             */ FromTo: newAttribute("date_of_scraping.week.from_to"),
            /**
             * Display Form Title: Week #/Year (Cont.) (date_of_scraping)
             * Display Form ID: date_of_scraping.week.wk_year_cont
             */ WeekNrYear_1: newAttribute("date_of_scraping.week.wk_year_cont"),
            /**
             * Display Form Title: Wk/Qtr/Year (Cont.) (date_of_scraping)
             * Display Form ID: date_of_scraping.week.wk_qtr_year_cont
             */ WkQtrYear: newAttribute("date_of_scraping.week.wk_qtr_year_cont"),
            /**
             * Display Form Title: Wk/Qtr/Year (date_of_scraping)
             * Display Form ID: date_of_scraping.week.wk_qtr_year
             */ WkQtrYear_1: newAttribute("date_of_scraping.week.wk_qtr_year"),
        },
        /**
         * Date Attribute: Week (Sun-Sat) (date_of_scraping)
         * Date Attribute ID: date_of_scraping.week.in.year
         */ WeekSunSat: {
            ref: idRef("date_of_scraping.week.in.year", "attribute"),
            identifier: "date_of_scraping.week.in.year",
            /**
             * Display Form Title: Number US (date_of_scraping)
             * Display Form ID: date_of_scraping.week.in.year.number_us
             */ NumberUS: newAttribute("date_of_scraping.week.in.year.number_us"),
        },
        /**
         * Date Attribute: Week (Sun-Sat) of Qtr (date_of_scraping)
         * Date Attribute ID: date_of_scraping.week.in.quarter
         */ WeekSunSatOfQtr: {
            ref: idRef("date_of_scraping.week.in.quarter", "attribute"),
            identifier: "date_of_scraping.week.in.quarter",
            /**
             * Display Form Title: Number US (date_of_scraping)
             * Display Form ID: date_of_scraping.week.in.quarter.number_us
             */ NumberUS: newAttribute("date_of_scraping.week.in.quarter.number_us"),
        },
        /**
         * Date Attribute: Week (Mon-Sun)/Year (date_of_scraping)
         * Date Attribute ID: date_of_scraping.euweek
         */ WeekMonSunYear: {
            ref: idRef("date_of_scraping.euweek", "attribute"),
            identifier: "date_of_scraping.euweek",
            /**
             * Display Form Title: Week #/Year (W1/2010) (date_of_scraping)
             * Display Form ID: date_of_scraping.euweek.wk_year
             */ WeekNrYear: newAttribute("date_of_scraping.euweek.wk_year"),
            /**
             * Display Form Title: Week Starting (date_of_scraping)
             * Display Form ID: date_of_scraping.euweek.starting
             */ WeekStarting: newAttribute("date_of_scraping.euweek.starting"),
            /**
             * Display Form Title: From - To (date_of_scraping)
             * Display Form ID: date_of_scraping.euweek.from_to
             */ FromTo: newAttribute("date_of_scraping.euweek.from_to"),
        },
        /**
         * Date Attribute: Week (Mon-Sun) (date_of_scraping)
         * Date Attribute ID: date_of_scraping.euweek.in.year
         */ WeekMonSun: {
            ref: idRef("date_of_scraping.euweek.in.year", "attribute"),
            identifier: "date_of_scraping.euweek.in.year",
            /**
             * Display Form Title: Number EU (date_of_scraping)
             * Display Form ID: date_of_scraping.euweek.in.year.number_eu
             */ NumberEU: newAttribute("date_of_scraping.euweek.in.year.number_eu"),
        },
        /**
         * Date Attribute: Week (Mon-Sun) of Qtr (date_of_scraping)
         * Date Attribute ID: date_of_scraping.euweek.in.quarter
         */ WeekMonSunOfQtr: {
            ref: idRef("date_of_scraping.euweek.in.quarter", "attribute"),
            identifier: "date_of_scraping.euweek.in.quarter",
            /**
             * Display Form Title: Number EU (date_of_scraping)
             * Display Form ID: date_of_scraping.euweek.in.quarter.number_eu
             */ NumberEU: newAttribute("date_of_scraping.euweek.in.quarter.number_eu"),
        },
        /**
         * Date Attribute: Month (date_of_scraping)
         * Date Attribute ID: date_of_scraping.month.in.year
         */ Month: {
            ref: idRef("date_of_scraping.month.in.year", "attribute"),
            identifier: "date_of_scraping.month.in.year",
            /**
             * Display Form Title: Short (Jan) (date_of_scraping)
             * Display Form ID: date_of_scraping.month.in.year.short
             */ Short: newAttribute("date_of_scraping.month.in.year.short"),
            /**
             * Display Form Title: Long (January) (date_of_scraping)
             * Display Form ID: date_of_scraping.month.in.year.long
             */ Long: newAttribute("date_of_scraping.month.in.year.long"),
            /**
             * Display Form Title: Number (M1) (date_of_scraping)
             * Display Form ID: date_of_scraping.month.in.year.number
             */ Number: newAttribute("date_of_scraping.month.in.year.number"),
            /**
             * Display Form Title: M/Q (M1/Q1) (date_of_scraping)
             * Display Form ID: date_of_scraping.month.in.year.m_q
             */ MQ: newAttribute("date_of_scraping.month.in.year.m_q"),
        },
        /**
         * Date Attribute: Month of Quarter (date_of_scraping)
         * Date Attribute ID: date_of_scraping.month.in.quarter
         */ MonthOfQuarter: {
            ref: idRef("date_of_scraping.month.in.quarter", "attribute"),
            identifier: "date_of_scraping.month.in.quarter",
            /**
             * Display Form Title: Number (date_of_scraping)
             * Display Form ID: date_of_scraping.month.in.quarter.number
             */ Number: newAttribute("date_of_scraping.month.in.quarter.number"),
        },
        /**
         * Date Attribute: Day of Year (date_of_scraping)
         * Date Attribute ID: date_of_scraping.day.in.year
         */ DayOfYear: {
            ref: idRef("date_of_scraping.day.in.year", "attribute"),
            identifier: "date_of_scraping.day.in.year",
            /**
             * Display Form Title: default (date_of_scraping)
             * Display Form ID: date_of_scraping.day.in.year.default
             */ Default: newAttribute("date_of_scraping.day.in.year.default"),
        },
        /**
         * Date Attribute: Day of Week (Sun-Sat) (date_of_scraping)
         * Date Attribute ID: date_of_scraping.day.in.week
         */ DayOfWeekSunSat: {
            ref: idRef("date_of_scraping.day.in.week", "attribute"),
            identifier: "date_of_scraping.day.in.week",
            /**
             * Display Form Title: Short (Sun) (date_of_scraping)
             * Display Form ID: date_of_scraping.day.in.week.short
             */ Short: newAttribute("date_of_scraping.day.in.week.short"),
            /**
             * Display Form Title: Long (Sunday) (date_of_scraping)
             * Display Form ID: date_of_scraping.day.in.week.long
             */ Long: newAttribute("date_of_scraping.day.in.week.long"),
            /**
             * Display Form Title: Number (1=Sunday) (date_of_scraping)
             * Display Form ID: date_of_scraping.day.in.week.number
             */ Number: newAttribute("date_of_scraping.day.in.week.number"),
        },
        /**
         * Date Attribute: Day of Week (Mon-Sun) (date_of_scraping)
         * Date Attribute ID: date_of_scraping.day.in.euweek
         */ DayOfWeekMonSun: {
            ref: idRef("date_of_scraping.day.in.euweek", "attribute"),
            identifier: "date_of_scraping.day.in.euweek",
            /**
             * Display Form Title: Short (Mon) (date_of_scraping)
             * Display Form ID: date_of_scraping.day.in.euweek.short
             */ Short: newAttribute("date_of_scraping.day.in.euweek.short"),
            /**
             * Display Form Title: Long (Monday) (date_of_scraping)
             * Display Form ID: date_of_scraping.day.in.euweek.long
             */ Long: newAttribute("date_of_scraping.day.in.euweek.long"),
            /**
             * Display Form Title: Number (1=Monday) (date_of_scraping)
             * Display Form ID: date_of_scraping.day.in.euweek.number
             */ Number: newAttribute("date_of_scraping.day.in.euweek.number"),
        },
        /**
         * Date Attribute: Day of Quarter (date_of_scraping)
         * Date Attribute ID: date_of_scraping.day.in.quarter
         */ DayOfQuarter: {
            ref: idRef("date_of_scraping.day.in.quarter", "attribute"),
            identifier: "date_of_scraping.day.in.quarter",
            /**
             * Display Form Title: default (date_of_scraping)
             * Display Form ID: date_of_scraping.day.in.quarter.default
             */ Default: newAttribute("date_of_scraping.day.in.quarter.default"),
        },
        /**
         * Date Attribute: Day of Month (date_of_scraping)
         * Date Attribute ID: date_of_scraping.day.in.month
         */ DayOfMonth: {
            ref: idRef("date_of_scraping.day.in.month", "attribute"),
            identifier: "date_of_scraping.day.in.month",
            /**
             * Display Form Title: default (date_of_scraping)
             * Display Form ID: date_of_scraping.day.in.month.default
             */ Default: newAttribute("date_of_scraping.day.in.month.default"),
        },
        /**
         * Date Attribute: Quarter/Year (date_of_scraping)
         * Date Attribute ID: date_of_scraping.quarter
         */ QuarterYear: {
            ref: idRef("date_of_scraping.quarter", "attribute"),
            identifier: "date_of_scraping.quarter",
            /**
             * Display Form Title: US Short (date_of_scraping)
             * Display Form ID: date_of_scraping.quarter.short_us
             */ USShort: newAttribute("date_of_scraping.quarter.short_us"),
        },
        /**
         * Date Attribute: Month/Year (date_of_scraping)
         * Date Attribute ID: date_of_scraping.month
         */ MonthYear: {
            ref: idRef("date_of_scraping.month", "attribute"),
            identifier: "date_of_scraping.month",
            /**
             * Display Form Title: Short (Jan 2010) (date_of_scraping)
             * Display Form ID: date_of_scraping.month.short
             */ Short: newAttribute("date_of_scraping.month.short"),
            /**
             * Display Form Title: Long (January 2010) (date_of_scraping)
             * Display Form ID: date_of_scraping.month.long
             */ Long: newAttribute("date_of_scraping.month.long"),
            /**
             * Display Form Title: Number (1/2010) (date_of_scraping)
             * Display Form ID: date_of_scraping.month.number
             */ Number: newAttribute("date_of_scraping.month.number"),
        },
        /**
         * Date Attribute: Date (date_of_scraping)
         * Date Attribute ID: date_of_scraping.date
         */ Date: {
            ref: idRef("date_of_scraping.date", "attribute"),
            identifier: "date_of_scraping.date",
            /**
             * Display Form Title: mm/dd/yyyy (date_of_scraping)
             * Display Form ID: date_of_scraping.date.day.us.mm_dd_yyyy
             */ MmDdYyyy: newAttribute("date_of_scraping.date.day.us.mm_dd_yyyy"),
            /**
             * Display Form Title: yyyy-mm-dd (date_of_scraping)
             * Display Form ID: date_of_scraping.date.day.yyyy_mm_dd
             */ YyyyMmDd: newAttribute("date_of_scraping.date.day.yyyy_mm_dd"),
            /**
             * Display Form Title: m/d/yy (no leading zeroes) (date_of_scraping)
             * Display Form ID: date_of_scraping.date.day.us.m_d_yy
             */ MDYy: newAttribute("date_of_scraping.date.day.us.m_d_yy"),
            /**
             * Display Form Title: Long (Mon, Jan 1, 2010) (date_of_scraping)
             * Display Form ID: date_of_scraping.date.day.us.long
             */ Long: newAttribute("date_of_scraping.date.day.us.long"),
            /**
             * Display Form Title: dd/mm/yyyy (date_of_scraping)
             * Display Form ID: date_of_scraping.date.day.uk.dd_mm_yyyy
             */ DdMmYyyy: newAttribute("date_of_scraping.date.day.uk.dd_mm_yyyy"),
            /**
             * Display Form Title: dd-mm-yyyy (date_of_scraping)
             * Display Form ID: date_of_scraping.date.day.eu.dd_mm_yyyy
             */ DdMmYyyy_1: newAttribute("date_of_scraping.date.day.eu.dd_mm_yyyy"),
        },
        /**
         * Date Attribute: US Week Year (date_of_scraping)
         * Date Attribute ID: date_of_scraping.year.for.week
         */ USWeekYear: {
            ref: idRef("date_of_scraping.year.for.week", "attribute"),
            identifier: "date_of_scraping.year.for.week",
            /**
             * Display Form Title: Week Year (date_of_scraping)
             * Display Form ID: date_of_scraping.year.for.week.number
             */ WeekYear: newAttribute("date_of_scraping.year.for.week.number"),
        },
        /**
         * Date Attribute: EU Week Year (date_of_scraping)
         * Date Attribute ID: date_of_scraping.year.for.euweek
         */ EUWeekYear: {
            ref: idRef("date_of_scraping.year.for.euweek", "attribute"),
            identifier: "date_of_scraping.year.for.euweek",
            /**
             * Display Form Title: Week Year (date_of_scraping)
             * Display Form ID: date_of_scraping.year.for.euweek.number
             */ WeekYear: newAttribute("date_of_scraping.year.for.euweek.number"),
        },
        /**
         * Date Attribute: US Week Quarter (date_of_scraping)
         * Date Attribute ID: date_of_scraping.quarter.for.week
         */ USWeekQuarter: {
            ref: idRef("date_of_scraping.quarter.for.week", "attribute"),
            identifier: "date_of_scraping.quarter.for.week",
            /**
             * Display Form Title: Week Quarter (date_of_scraping)
             * Display Form ID: date_of_scraping.quarter.for.week.number
             */ WeekQuarter: newAttribute("date_of_scraping.quarter.for.week.number"),
        },
        /**
         * Date Attribute: EU Week Quarter (date_of_scraping)
         * Date Attribute ID: date_of_scraping.quarter.for.euweek
         */ EUWeekQuarter: {
            ref: idRef("date_of_scraping.quarter.for.euweek", "attribute"),
            identifier: "date_of_scraping.quarter.for.euweek",
            /**
             * Display Form Title: Week Quarter (date_of_scraping)
             * Display Form ID: date_of_scraping.quarter.for.euweek.number
             */ WeekQuarter: newAttribute("date_of_scraping.quarter.for.euweek.number"),
        },
    },
};
export const Insights = {
    /**
     * Insight Title: Vehicles
     * Insight ID: aabA3DEaWFtD
     */
    Vehicles: "aabA3DEaWFtD",
    /**
     * Insight Title: Overview - 1
     * Insight ID: aaeKoWbAzwpI
     */ Overview1: "aaeKoWbAzwpI",
    /**
     * Insight Title: Volumes
     * Insight ID: aagQuXRqW21l
     */ Volumes: "aagQuXRqW21l",
    /**
     * Insight Title: BubbleChartByModel
     * Insight ID: aaEQgG0ecNGO
     */ BubbleChartByModel: "aaEQgG0ecNGO",
    /**
     * Insight Title: Check Duration
     * Insight ID: aatQsNcLW2Ri
     */ CheckDuration: "aatQsNcLW2Ri",
};
export const Dashboards = {
    /**
     * Dashboard Title: 2. Latest
     * Dashboard ID: aaeVxNDNKIJ9
     */
    _2Latest: "aaeVxNDNKIJ9",
    /**
     * Dashboard Title: 1. Overview
     * Dashboard ID: aagKeij2uME5
     */ _1Overview: "aagKeij2uME5",
};

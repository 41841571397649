import React, { useCallback, useState } from "react";
import _ from "lodash";

import { Checkbox, Tooltip } from "antd";
import { useBrandConfigurator } from "../../contexts/BrandConfiguratorProvider";
import { MdExpandLess, MdExpandMore, MdOutlineClear } from "react-icons/md";
// import "./brand_style.css";

const BrandConfigurator = ({ makeModelObject, ...props }) => {
    const { filterSelectors, setFilterSelector, deselectConfigurator } = useBrandConfigurator();
    // State used to display nested list
    const [visibleMakes, setVisibleMakes] = useState({});
    const [visibleModels, setVisibleModels] = useState({});
    // Avoid mutation by reference
    const makeModelObject2 = _.cloneDeep(makeModelObject);

    const selectConfigurator = useCallback(
        (make, model = undefined, versions = []) => {
            const newConfigurator = Object.assign({
                ...filterSelectors,
                [make]: model
                    ? {
                          ...filterSelectors[make],
                          [model]:
                              model && make in filterSelectors && model in filterSelectors[make]
                                  ? // versions.length > 0 && filterSelectors[make][model].length < makeModelObject[make][model].length ?
                                    [...filterSelectors[make][model], ...versions]
                                  : versions,
                          // : makeModelObject[make][model]
                      }
                    : makeModelObject2[make],
            });
            setFilterSelector(newConfigurator);
        },
        [makeModelObject2, filterSelectors],
    );

    function showHideNestedList(type, value) {
        if (type === "make") {
            setVisibleMakes({
                ...visibleMakes,
                [value]: value in visibleMakes ? !visibleMakes[value] : true,
            });
        } else if (type === "model") {
            setVisibleModels({
                ...visibleModels,
                [value]: value in visibleModels ? !visibleModels[value] : true,
            });
        }
    }

    function clearSelection() {
        setFilterSelector({});
    }
    const countActiveModels = useCallback(
        (brand) => {
            return brand in filterSelectors ? Object.keys(filterSelectors[brand]).length : 0;
        },
        [filterSelectors],
    );

    const countActiveVersionStdByModel = useCallback(
        (brand, model) => {
            return brand in filterSelectors && model in filterSelectors[brand] ? filterSelectors[brand][model].length : 0;
        },
        [filterSelectors],
    );

    return (
        <div className={`h-full px-2 py-1 border rounded-md border-gray-300 shadow-md`}>
            <div className="flex items-center justify-between">
                <span className="text-indigo-700 text-lg font-semibold">Selection box</span>
                <button className="border-gray-300 bg-secondary-500 text-sm text-white px-2.5 py-0.5 border rounded font-semibold shadow-button" onClick={clearSelection}>
                    <span>Clear selection</span>
                </button>
                {/* <IoCloseCircleOutline onClick={clearSelection} className="text-secondary-500 w-6 h-6 cursor-pointer" /> */}
            </div>
            <ul className="mt-2 max-h-[450px] overflow-y-scroll">
                {Object.keys(makeModelObject2).map((make) => {
                    const selectedModelsCount = countActiveModels(make);

                    return (
                        <li>
                            <div className={`sticky top-0 z-20 py-1 flex justify-stretch items-center gap-2 bg-white hover:bg-gray-100 border-b border-gray-200 ${selectedModelsCount > 0 ? "text-indigo-500" : "text-gray-500"} `} onClick={(e) => showHideNestedList("make", make)}>
                                <div className="flex-1 flex items-center gap-2">
                                    <Checkbox
                                        onClick={(e) => {
                                            if (!(make in filterSelectors)) selectConfigurator(make);
                                            else deselectConfigurator(make);
                                            e.stopPropagation();
                                        }}
                                        checked={filterSelectors[make] && Object.keys(makeModelObject2[make]).length === selectedModelsCount}
                                        indeterminate={make in filterSelectors && Object.keys(makeModelObject2[make]).length > selectedModelsCount && selectedModelsCount !== 0}
                                    />
                                    <span className="text-base font-medium leading-8 cursor-copy">{make}</span>
                                    {selectedModelsCount > 0 && <span>({selectedModelsCount})</span>}
                                </div>
                                {React.createElement(visibleMakes[make] ? MdExpandLess : MdExpandMore, { className: "pr-2 w-8 h-8 text-gray-400" })}
                            </div>
                            {visibleMakes[make] && (
                                <ul className="px-2">
                                    {Object.keys(makeModelObject2[make]).map((model) => {
                                        const versionCount = countActiveVersionStdByModel(make, model);
                                        return (
                                            <ul>
                                                <div className={`sticky top-10 z-10 bg-white flex items-center gap-2 py-0.5 ${versionCount > 0 ? "text-steel font-medium" : "text-gray-500"}`} onClick={(e) => showHideNestedList("model", model)}>
                                                    <Checkbox
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (make in filterSelectors && filterSelectors[make][model]) deselectConfigurator(make, model);
                                                            else selectConfigurator(make, model, makeModelObject2[make][model]);
                                                        }}
                                                        checked={make in filterSelectors && makeModelObject2[make][model].length === versionCount}
                                                        indeterminate={make in filterSelectors && makeModelObject2[make][model].length > versionCount && versionCount !== 0}
                                                    />
                                                    <span className="leading-6 cursor-copy">{model}</span>
                                                    {versionCount > 0 && <span>({versionCount})</span>}
                                                </div>
                                                {visibleModels[model] && (
                                                    <ul className="pl-4 pr-1">
                                                        {makeModelObject2[make][model].map((versionObject) => {
                                                            const makeModelWordsCount = [make, model].join(" ").split(" ").length;
                                                            const versionLabel = versionObject.label.split(" ").slice(makeModelWordsCount).join(" ");
                                                            // Get the min value for the promo
                                                            const custIdMinPromo = Object.keys(versionObject)
                                                                .filter((key) => key !== "label" && props.oemPromos.includes(key) && versionObject[key][23] !== null)
                                                                .sort((key1, key2) => versionObject[key1][23] - versionObject[key2][23])[0];
                                                            // Get the min value for the config
                                                            const custIdMinConfig = Object.keys(versionObject)
                                                                .filter((key) => key !== "label" && props.oemConfigs.includes(key) && versionObject[key][23] !== null)
                                                                .sort((key1, key2) => versionObject[key1][23] - versionObject[key2][23])[0];
                                                            // Min list price
                                                            const listPricePromo = custIdMinPromo && versionObject[custIdMinPromo][1] !== null ? Math.round(versionObject[custIdMinPromo][1]) : null;
                                                            const listPriceConfig = custIdMinConfig && versionObject[custIdMinConfig][1] !== null ? Math.round(versionObject[custIdMinConfig][1]) : null;
                                                            const minListPrice = listPricePromo && listPriceConfig ? (listPricePromo > listPriceConfig ? listPriceConfig : listPricePromo) : custIdMinPromo ? listPricePromo : custIdMinConfig ? listPriceConfig : null;
                                                            const tooltipMessage = `List price ${minListPrice} ${custIdMinConfig ? ", Config " + Math.round(versionObject[custIdMinConfig][23]) : ""} ${custIdMinPromo ? ", Promo " + Math.round(versionObject[custIdMinPromo][23]) : ""}`;

                                                            return (
                                                                <li
                                                                    className="flex items-center gap-2 text-[13px] font-normal cursor-pointer py-1"
                                                                    onClick={() => {
                                                                        if (make in filterSelectors && model in filterSelectors[make] && filterSelectors[make][model].find((item) => item.label === versionObject.label)) deselectConfigurator(make, model, [versionObject]);
                                                                        else selectConfigurator(make, model, [versionObject]);
                                                                    }}
                                                                >
                                                                    <Checkbox checked={make in filterSelectors && model in filterSelectors[make] && filterSelectors[make][model].find((item) => item.label === versionObject.label)} />
                                                                    <Tooltip title={tooltipMessage} placement="left" overlayStyle={{ fontSize: 12, minWidth: "max-content" }}>
                                                                        <span>{versionLabel}</span>
                                                                    </Tooltip>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                )}
                                            </ul>
                                        );
                                    })}
                                </ul>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default BrandConfigurator;
